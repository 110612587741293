import React, { useEffect, useState } from "react";
import { CreateAccountDots } from "../../CommonComponents/CreateAccountDots";

export const WhereDoYouLive = ({ setshow_card, setshow_create_card, data }) => {
  const {
    address,
    address_err,
    setaddress,
    setaddress_err,
    apartmentno,
    setapartmentno,
    apartmentno_err,
    setapartmentno_err,
    zipcode,
    setzipcode,
    zipcode_err,
    setzipcode_err,
    i_have_a_doorman,
    seti_have_a_doorman,
    next,
    setnext,
  } = data;

  useEffect(() => {
    if (
      address != "" &&
      apartmentno != "" &&
      zipcode != "" &&
      address_err == "false" &&
      apartmentno_err == "false" &&
      zipcode_err == "false"
    ) {
      setnext(true);
    } else {
      setnext(false);
    }
  }, [address, apartmentno, zipcode]);

  return (
    <div className="almostthere">
      <div className="almostthere_heading f40">
        Where do
        <br />
        you live?
      </div>
      <div className="f14">ADDRESS DETAILS</div>
      <div className="almostthere_address">
        <input
          type="text"
          value={address}
          inp_error={address_err}
          className="f14"
          placeholder="Address"
          onChange={(e) => {
            setaddress_err((e.target.value == "").toString());
            setaddress(e.target.value);
          }}
        ></input>
      </div>
      <div className="almostthere_apartmentno">
        <input
          type="text"
          value={apartmentno}
          inp_error={apartmentno_err}
          className="f14"
          placeholder="Apt"
          onChange={(e) => {
            setapartmentno_err((e.target.value == "").toString());
            setapartmentno(e.target.value);
          }}
        ></input>
      </div>
      <div className="almostthere_zipcode">
        <input
          type="number"
          value={zipcode}
          inp_error={zipcode_err}
          className="f14"
          placeholder="Zip Code"
          onChange={(e) => {
            setzipcode(e.target.value);
            setzipcode_err((e.target.value == "").toString());
          }}
        ></input>
      </div>
      <div
        className="almostthere_i_have_a_doorman f14"
        onClick={() => seti_have_a_doorman(!i_have_a_doorman)}
      >
        <div className="tick_checkbox">
          {/* <input mouse="pointer" type="checkbox" id="i_have_a_doorman" /> */}
          <input
            mouse="pointer"
            type="checkbox"
            id="i_have_a_doorman"
            cust_type="type1"
            checked={i_have_a_doorman}
          />
          {/* <label htmlFor="i_have_a_doorman"> */}
          <label cust_type="type1">
            {/* <label> */}
            <div className="show_cust_checkbox"></div>
          </label>
        </div>
        {/* <label htmlFor="i_have_a_doorman">YES, I have a doorman</label> */}
        <label mouse="pointer">YES, I have a doorman</label>
      </div>
      <div className="f12 almostthere_please_note" style={{ fontWeight: '400' }}>
        *We only serve FiDi to 110th St.
      </div>
      <div className="almostthere_haveanaccount f13 f-400" style={{width:'fit-content'}} onClick={() => setshow_card("login")}>
        <div>Have an Account?</div>
        <div >Log In</div>
      </div>
      <div className="almostthere_prevnext">
        <div
          className="almostthere_prev"
          mouse="pointer"
          onClick={() => setshow_create_card("verifyyourself")}
        >
          <div>Previous</div>
        </div>
        <div className="almostthere_next" mouse="pointer">
          <div
            className={next ? "" : "disabled"}
            onClick={() => next && setshow_create_card("onemorething")}
          >
            Next
          </div>
        </div>
      </div>
      <CreateAccountDots show_dots="3" />
    </div>
  );
};
