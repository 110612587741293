import CryptoJS from "crypto-js";
const RSA_key = `MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQDhYumNUmiKRLKjXfiTL6fKEjdq`;

export const encrypt_data = (data) => {
  console.log(data);
  var ciphertext = CryptoJS.TripleDES.encrypt(
    data,
    CryptoJS.MD5(RSA_key).toString()
  ).toString();
  // console.log('decrypt', CryptoJS.TripleDES.decrypt(ciphertext, CryptoJS.MD5(RSA_key).toString()).toString(CryptoJS.enc.Utf8))
  return ciphertext;
};
