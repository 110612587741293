import "./style/main.css";

import { Provider } from "react-redux";
import { useRoutes } from "react-router-dom";
import { useEffect, useState } from "react";

import store from "./store/store";

import { Navbar } from "./Components/CommonComponents/Navbar";
import { Juliette } from "./Components/CommonComponents/Juliette";

import { LoginPage } from "./Components/LoginPage";
import { RequestPickup } from "./Components/RequestPickup";
import { Profile } from "./Components/Profile";
import { MyOrders } from "./Components/MyOrders";
import { Pricelist } from "./Components/Pricelist";
import { FAQ } from "./Components/FAQ";

import { HowItWorks } from "./Components/HowItWorks";
import { ForgotPassword } from "./Components/LoginPage/ForgotPassword";
import { NotFound } from "./Components/NotFound";
import { Test } from "./Components/CommonComponents/Test";
import { set } from "date-fns";
import { Loading } from "./Components/CommonComponents/Loading";
import { check_user_signin, getToken1 } from "./Firebase/firebase";
import { getToken } from "firebase/messaging";
import { MyOrderTemp } from "./Components/MyOrderTemp";

// import { useAuth } from './firebase';

import ReactGA from "react-ga";
import TagManager from "react-gtm-module";
import { FAQ_copy } from "./Components/FAQ copy";
const TRACKING_ID = "UA-46736740-4";
// const TRACKING_ID = "UA-226259261-1";
ReactGA.initialize(TRACKING_ID);
const tagManagerArgs = {
  gtmId: "GTM-5TZXRQD",
};

TagManager.initialize(tagManagerArgs);

ReactGA.initialize(TRACKING_ID);

function App() {
  const [isTokenFound, setTokenFound] = useState(false);

  // console.log("token set", isTokenFound);

  const [loader, setloader] = useState(false);
  const [loader_data, setloader_data] = useState({});

  const [menu, setmenu] = useState(false);
  const [set_bg_juliette, setset_bg_juliette] = useState("");
  const [juliette_bg, setjuliette_bg] = useState(false);
  const [page_loaded, setpage_loaded] = useState(false);
  // console.log("page_loaded", page_loaded);

  const routes = [
    {
      path: "/",
      element: (
        <HowItWorks
          // bg_svg={hiw_bg_svg}
          setloader={setloader}
          setmenu={setmenu}
          setjuliette_bg={setjuliette_bg}
        />
      ),
    },
    {
      path: "/login",
      element: <LoginPage setmenu={setmenu} setjuliette_bg={setjuliette_bg} />,
    },
    {
      path: "/forgot-password",
      element: (
        <ForgotPassword setmenu={setmenu} setjuliette_bg={setjuliette_bg} />
      ),
    },
    {
      path: "/request-pickup",
      element: (
        <RequestPickup setmenu={setmenu} setjuliette_bg={setjuliette_bg} />
      ),
    },
    {
      path: "/my-orders",
      // element: <MyOrders setmenu={setmenu} setjuliette_bg={setjuliette_bg} />,
      element: (
        <MyOrderTemp
          setmenu={setmenu}
          setjuliette_bg={setjuliette_bg}
          setloader={setloader}
        />
      ),
    },
    {
      path: "/profile",
      element: <Profile setmenu={setmenu} setjuliette_bg={setjuliette_bg} />,
    },
    {
      path: "/pricelist",
      element: <Pricelist setmenu={setmenu} setjuliette_bg={setjuliette_bg} />,
    },
    // {
    //   path: "/faq",
    //   element: (
    //     <FAQ
    //       setmenu={setmenu}
    //       setjuliette_bg={setjuliette_bg}
    //       setloader={setloader}
    //     />
    //   ),
    // },
    {
      path: "/faq",
      element: (
        <FAQ_copy
          setmenu={setmenu}
          setjuliette_bg={setjuliette_bg}
          setloader={setloader}
        />
      ),
    },
    // {
    //   path: "/test",
    //   element: <Test setmenu={setmenu} setjuliette_bg={setjuliette_bg} />,
    // },

    {
      path: "/*",
      element: (
        // <LoginPage
        //   setmenu={setmenu}
        //   setjuliette_bg={setjuliette_bg}
        //   set_bg_juliette={set_bg_juliette}
        // />
        <NotFound setmenu={setmenu} setjuliette_bg={setjuliette_bg} />
      ),
    },
  ];
  useEffect(() => {
    // console.log(routes);
  }, [routes]);
  useEffect(() => {
    console.log("analytics", window.location.pathname + window.location.search);
    ReactGA.pageview(window.location.pathname);
  }, [window.location.pathname]);
  useEffect(() => {
    // console.log("page changed");
    let user_data = check_user_signin();
    let user_from_db = {};
    if (user_data) {
      user_from_db = {};
    } else {
    }
    getToken1(setTokenFound);
  }, [routes]);

  const route = useRoutes(routes);

  return (
    <>
      {loader && <Loading loader_data={loader_data} />}
      <Provider store={store}>
        {/* <div className="pre_load_bg_images" style={{ display: 'none' }}>
          <img src={faq_bg} />
        </div> */}
        <Juliette juliette_bg={juliette_bg} menu={menu} setmenu={setmenu} />
        {route}
      </Provider>
      {/* <BrowserRouter>
          <Routes>
            <Route path='/' element={<LoginPage setmenu={setmenu} setjuliette_bg={setjuliette_bg}/>} />
             <Route path='/request-pickup' element={<RequestPickup setmenu={setmenu} setjuliette_bg={setjuliette_bg}/>} />
             <Route path='/profile' element={<Profile setmenu={setmenu} setjuliette_bg={setjuliette_bg}/>} />
             <Route path='/my-orders' element={<MyOrders setmenu={setmenu} setjuliette_bg={setjuliette_bg}/>} />
             <Route path='/pricelist' element={<Pricelist setmenu={setmenu} setjuliette_bg={setjuliette_bg}/>} />
             <Route path='/faq' element={<FAQ setmenu={setmenu} setjuliette_bg={setjuliette_bg}/>} />
             <Route path='/hiw' element={<HowItWorks setmenu={setmenu} setjuliette_bg={setjuliette_bg}/> } />
            <Route path='/*' element={<LoginPage setmenu={setmenu}/>} />
          </Routes>
        </BrowserRouter> */}
    </>
  );
}

export default App;
