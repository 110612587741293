import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { edit_email, edit_email_get_token, resend_email_otp, send_email_otp, verify_email, verify_password } from "../../API/API";
import { encrypt_data } from "../../../RSA_Encryption/RSA";

export const EditEmail = ({
  profile,
  setprofile_modal,
  cancel,
  setprofile,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const current_store = useSelector((state) => state);

  const [email_modal_showing, setemail_modal_showing] =
    useState("enterpassword");
  const [password, setpassword] = useState("");
  const [invalid_pass, setinvalid_pass] = useState("");

  const [email, setemail] = useState("");
  const [confirmemail, setconfirmemail] = useState("");
  const [email_err, setemail_err] = useState("");
  const [confirmemail_err, setconfirmemail_err] = useState("");

  const [verificationcode, setverificationcode] = useState("");
  const [verificationcode_err, setverificationcode_err] = useState("");

  const [temp_token, settemp_token] = useState("");
  // console.log(temp_token);


  return (
    <div className="profile_modal_common_card">
      {email_modal_showing == "enterpassword" ? (
        <>
          <div className="card_heading">
            New
            <br />
            Email
          </div>
          <div className="card_title">ENTER PASSWORD</div>
          <div className="card_input">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => { setpassword(e.target.value); setinvalid_pass('') }}
              inp_error={invalid_pass.toString()}
            />
          </div>
          <div className="margin_bottom_auto"></div>
          <div className="btn_group btn_group_vertical">
            <div btn_type="type1" onClick={cancel}>
              Cancel
            </div>
            <div
              btn_type={password != "" ? "type3" : "type2"}
              onClick={() => {

                if (password.length == 0) {
                  return
                }
                verify_password(encrypt_data(password), current_store.user.token, (data) => {
                  if (data.msg.verified) {
                    settemp_token(data.msg.token)
                    setemail_modal_showing("setanewemail1")
                    return
                  }
                  setinvalid_pass(true)
                })
              }

              }
            >
              Continue
            </div>
          </div>
        </>
      ) : email_modal_showing == "setanewemail1" ? (
        <>
          <div className="card_heading">
            New
            <br />
            Email
          </div>
          <div className="card_title">SET A NEW EMAIL</div>
          <div className="card_input">
            <input
              type="text"
              placeholder="New Email Address"
              value={email}
              inp_error={email_err.toString()}
              onChange={(e) => {
                setemail(e.target.value);
                setemail_err(
                  !e.target.value.match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                  )?.length > 0
                );
                // console.log(
                // !e.target.value.match(
                //     /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                //   )?.length > 0
                // );
              }}
            />
          </div>
          <div className="card_input">
            <input
              type="text"
              placeholder="Confirm Email Address "
              value={confirmemail}
              onChange={(e) => setconfirmemail(e.target.value)}
            />
          </div>
          <div className="margin_bottom_auto"></div>
          <div className="card_txt14">
            A verification email will be sent
            <br /> to this address.
          </div>
          <div className="btn_group btn_group_vertical">
            <div btn_type="type1" onClick={cancel}>
              Cancel
            </div>
            <div
              btn_type={email != "" && confirmemail != "" && email == confirmemail && email_err == false ? "type3" : "type2"}
              onClick={() => {
                if (email != confirmemail || !(email_err == false)) {
                  // console.log(email, confirmemail, email_err);
                  return;
                }
                send_email_otp(
                  {
                    email: email,
                    edit: true,
                    name: profile.name
                  },
                  current_store.user.token,
                  (data) => {
                    // console.log('email_otp', data);
                    if (!data.status) {
                      navigate('/404')
                      return
                    }
                    // setverificationcode(data.msg)
                    setemail_modal_showing("setanewemail2");
                  }
                )
              }}
            >
              Send verification code
            </div>
          </div>
        </>
      ) : email_modal_showing == "setanewemail2" ? (
        <>
          <div className="card_heading">
            New
            <br />
            Email
          </div>
          <div className="card_title">SET A NEW EMAIL</div>
          <div className="card_input">
            <input type="text" placeholder="New Email Address" readOnly />
          </div>
          <div className="card_input">
            <input type="text" placeholder="Confirm Email Address " readOnly />
          </div>
          <div className="card_title mt-34">ENTER VERIFICATION CODE</div>
          <div className="card_input">
            <input
              type="text"
              placeholder="Verification Code "
              value={verificationcode}
              onChange={(e) => { setverificationcode(e.target.value); setverificationcode_err('') }}
              inp_error={verificationcode_err.toString()}
            />
          </div>
          <div className="margin_bottom_auto"></div>
          <div className="btn_group btn_group_vertical">
            <div btn_type="type1"
              onClick={() => {
                // console.log('resendotp mail');
                // resend_email_otp({
                //   email:email,
                // },current_store.user.token,(data)=>{console.log(data);})
                resend_email_otp({
                  email:email,
                  "name" : "Shantanu Iyengar"
                },
                current_store.user.token
                ,(data)=>{
                  console.log(data);
                });
              }}
            >Resend code</div>
            <div btn_type="type1" onClick={cancel}>
              Cancel
            </div>
            <div
              btn_type="type3"
              onClick={() => {
                if (verificationcode == '') {
                  return;
                }
                verify_email(
                  {
                    email: email,
                    name: profile.name,
                    otp: verificationcode,
                    id: profile.id
                  },
                  current_store.user.token,
                  (data) => {
                    if (!data.status) {
                      navigate('/404')
                      return
                    }
                    if (data.otp_error) {
                      // console.log('otp false');
                      setverificationcode_err(true)
                      return
                    }
                    dispatch({ type: "login", payload: { token: data.token } });
                    localStorage.setItem('juliette_user_token', data.token);
                    cancel();

                    // setverificationcode(data.msg)
                    // setemail_modal_showing("setanewemail2");
                  }
                )

                // setprofile({ ...profile, email: email });
              }}
            >
              Set new email
            </div>
          </div>
        </>
      ) : (
        ""
      )
      }
    </div >
  );
};
