import React, { useEffect, useRef } from "react";
import Slider from "react-slick";

export const PaymentSlider = ({ add_arr, setshowadd }) => {
  const sliderRef = useRef();
  useEffect(() => {
    sliderRef.current.slickGoTo(0);
  }, [add_arr.length]);

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // fade: true,
    afterChange: (i) => setshowadd(i),
  };
  return (
    <div className="address_slider">
      <Slider {...settings} ref={sliderRef}>
        {add_arr.map((el, i) => (
          <div>
            <input
              type="text"
              placeholder="Name on Card"
              value={el.nameoncard}
              readOnly
            />
            <input
              type="text"
              placeholder="Card Number"
              value={el.cardno
                .split(" ")
                .map((el1, i) => (i == 3 ? el1 : "****"))
                .join(" ")}
              readOnly
            />
          </div>
        ))}
      </Slider>
    </div>
  );
};
