import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { get_order, verify_local_token } from "./API/API";
import { Loading } from "./CommonComponents/Loading";
// import { ViewOrder } from "./MyOrder/ViewOrder";
import { NewViewOrder } from "./MyOrder/NewViewOrder";
const status = ["pickup requested", "clothes picked up", "clothes delivered"];
{
  /* 
            0 = charge:0,delivery:0 
            1 = charge:1,delivery:0 
            2 = charge:1,delivery:1 
            */
}
{
  /*
             client side
             order created
             paid 
             sent for delivery
                */
}
const convertdate = (date) => {
  let temp_date = date.split("T")[0];
  temp_date = temp_date.split("-").reverse();
  return `${temp_date[0]}/${temp_date[1]}/${temp_date[2].slice(2)}`;
};
const checkstatus = (order_status, status) => {
  // console.log(order_status, status);
  if (order_status) {
    if (order_status != 1) {
      return "cancelled";
    }
    return status;
  }
  return "cancelled";
};
const status_arr = [["order created"], ["paid", "sent for delivery"]];
export const MyOrderTemp = ({ setmenu, setjuliette_bg, setloader }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [order_list, setorder_list] = useState([]);
  const [show_this_page, setshow_this_page] = useState(false);
  const [view_order, setview_order] = useState(false);
  const [current_item, setcurrent_item] = useState({});
  const current_store = useSelector((state) => state);
  useEffect(() => {
    if (current_store.user.token == "") {
      let token_in_localstorage = localStorage.getItem("juliette_user_token");

      if (token_in_localstorage) {
        verify_local_token(token_in_localstorage, (data) => {
          if (!data.status) {
            setshow_this_page(true);
            return navigate("/");
          }
          dispatch({ type: "login", payload: { token: data.token } });
        });
        // return navigate('/request-pickup')
      } else {
        return navigate("/");
      }
    } else {
      get_order(current_store.user.token, (data) => {
        if (!data.status) {
          setshow_this_page(true);
          return navigate("/");
        }
        setmenu(true);
        setjuliette_bg(true);
        setorder_list([
          ...data.msg.map((el) => {
            return {
              id: el.order_id,
              // date: convertdate(el.order_date),
              date: el.order_date,
              notes: el.notes,
              total:
                el.amount == null || el.amount.trim().length == 0
                  ? "-"
                  : `$ ${el.amount}`,
              // status: checkstatus(el.order_status, status_arr[el.charged][el.delivered]),
              status: el.display_status,
              photos: el.images.map((el) => el.url),
              string_to_show: (
                <div className="string_showing">
                  <div className="title heading">Requested Pickup</div>
                  {el.client_particulars.laundry === "1" && <div>Laundry</div>}
                  {el.client_particulars.drycleaning === "1" && (
                    <div>Dry Cleaning</div>
                  )}
                  {el.client_particulars.others === "1" && <div>Others</div>}
                </div>
              ),

              // order_status:(el.order_status? (el.order_status==1)
            };
          }),
        ]);
        setshow_this_page(true);
      });
    }
  }, [current_store.user.token]);

  return (
    <>
      {!show_this_page ? (
        <Loading />
      ) : (
        <>
          <div className="myordertemp">
            {/* <div className="page_title">My Orders</div> */}
            {/* {view_order && ( */}
            {/* // <div className=""> */}
            <NewViewOrder
              setloader={setloader}
              view_order={view_order}
              order_item={current_item}
              setview_order={setview_order}
            />
            {/* // </div> */}
            {/* )} */}
            <div>
              <div className="page_title">My Orders</div>
              {order_list.map((el) => {
                return (
                  <div className="order_card">
                    <div className="data_flex">
                      <div className="date">{el.date}</div>
                      {el.string_to_show}
                      <div className="total">
                        <div className="title">Total</div>
                        <div>{el.total}</div>
                      </div>
                      <div className="status">
                        <div className="title">Status</div>
                        <div style={{ display: "flex", flexWrap: "wrap" }}>
                          {el.status.split(" ").map((el) => (
                            <div>{el}&nbsp;</div>
                          ))}
                        </div>
                      </div>
                      <div>
                        {[
                          // "Picked Up",
                          "Customer Charged",
                          "Charged",
                          "Delivering Clothes",
                          "Driver Enroute to Dropoff",
                          "Order Completed",
                        ].indexOf(el.status) !== -1 && (
                          <div
                            className="action_btn"
                            onClick={() => {
                              setcurrent_item({ ...el });
                              setview_order(true);
                            }}
                          >
                            <div>View</div>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </>
      )}
    </>
  );
};
