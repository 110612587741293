import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export const Navbar = () => {
  const navigate = useNavigate();
  const [active_nav_tab, setactive_nav_tab] = useState("request-pickup");
  useEffect(() => {
    setactive_nav_tab(window.location.pathname.slice(1));
  }, [window.location.pathname])


  return (
    <div
      className="navbar"
      id="navbar"
      onClick={() => {
        document.getElementById("navbar").classList.toggle("active");
      }}
    >
      <div className="menu">
        <div className="menu_bar bar1"></div>
        <div className="menu_bar bar2"></div>
        <div className="menu_bar bar3"></div>
      </div>
      <div
        className="menu_items"
        // onClick={(e) =>
        //   document.getElementById("navbar").classList.toggle("active")
        // }
        id="menu_items"
      >
        {/* <div className="menu_items" id="menu_items"> */}
        <div className="menu_items_main">
          <div
            className="menu_item_tab f18"
            mouse="pointer"
            nav_tab_active={(active_nav_tab == "request-pickup").toString()}
            onClick={() => {
              navigate("/request-pickup");
              setactive_nav_tab("request-pickup");
              window.location.reload();
            }}
          >
            Request Pickup
          </div>
          <div
            className="menu_item_tab f18"
            mouse="pointer"
            nav_tab_active={(active_nav_tab == "my-orders").toString()}
            onClick={() => {
              navigate("/my-orders");
              setactive_nav_tab("my-orders");
              window.location.reload();
            }}
          >
            My Orders
          </div>
          <div
            className="menu_item_tab f18"
            mouse="pointer"
            nav_tab_active={(active_nav_tab == "profile").toString()}
            onClick={() => {
              navigate("/profile");
              setactive_nav_tab("profile");
              window.location.reload();
            }}
          >
            Profile
          </div>
          <div
            className="menu_item_tab f18"
            mouse="pointer"
            nav_tab_active={(active_nav_tab == "pricelist").toString()}
            onClick={() => {
              navigate("/pricelist");
              setactive_nav_tab("pricelist");
            }}
          >
            Pricelist
          </div>
          <div
            className="menu_item_tab f18"
            mouse="pointer"
            nav_tab_active={(active_nav_tab == "FAQ").toString()}
            onClick={() => {
              navigate("/FAQ");
              setactive_nav_tab("FAQ");
            }}
          >
            FAQ
          </div>
          {/* <div
            className="menu_item_tab f18"
            mouse="pointer"
            onClick={() => {
              navigate("/");
            }}
            >
            HIW
          </div> */}
          {/* <div
            className="menu_item_tab f18"
            mouse="pointer"
            onClick={() => {
              navigate("/login");
            }}
          >
            Logout
          </div> */}
        </div>
      </div>
    </div>
  );
};
