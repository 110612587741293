import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import arrow_down from "../img/arrow_down.svg";
import { get_pricelist } from "./API/API";

export const Pricelist = ({ setmenu, setjuliette_bg }) => {
  const current_store = useSelector((state) => state);
  const [pricelist, setpricelist] = useState([]);
  // const data = [
  //   {
  //     title: "Dry Cleaning",

  //     body: [
  //       {
  //         accord_head: "Shirts",
  //         accord_body: [
  //           ["On Hanger", "5.00"],
  //           ["Boxed", "5.50"],
  //           ["Hand Pressed", "11.50"],
  //           ["Dry Cleaned", "12.00"],
  //           ["Tuxedo", "16.00"],
  //           ["Polo Shirt / Sport", "13.00"],
  //         ].map((el) => {
  //           return { catg: el[0], cost: `$${el[1]}` };
  //         }),
  //       },
  //       {
  //         accord_head: "Suits",
  //         accord_body: [
  //           ["Two Piece", "27.00"],
  //           ["Three Piece", "33.00"],
  //           ["Suit Jacket", "16.00"],
  //           ["Slacks", "16.00"],
  //           ["Tuxedo", "33.00"],
  //           ["Vest", "13.00"],
  //           ["Tie", "10.00"],
  //         ].map((el) => {
  //           return { catg: el[0], cost: `$${el[1]}` };
  //         }),
  //       },
  //       {
  //         accord_head: "Accessories",
  //         accord_body: [
  //           ["Tie", "10.00"],
  //           ["Scarf", "12.00"],
  //           ["Shawl", "15.00"],
  //           ["Hat", "8.50"],
  //           ["Cummerbund", "10.00"],
  //           ["Socks", "8.00"],
  //           ["Gloves", "8.00"],
  //           ["Zitsis", "10.00"],
  //           ["2 Piece Pajama", "18.00"],
  //         ].map((el) => {
  //           return { catg: el[0], cost: `$${el[1]}` };
  //         }),
  //       },
  //       {
  //         accord_head: "Tops",
  //         accord_body: [
  //           ["Blouse", "15.00"],
  //           ["Tank Top", "15.00"],
  //           ["Sweater", "18.00"],
  //           ["Cardigan", "18.00"],
  //           ["Hoodie", "18.00"],
  //           ["TShirt", "12.00"],
  //           ["Bodysuit", "15.00"],
  //         ].map((el) => {
  //           return { catg: el[0], cost: `$${el[1]}` };
  //         }),
  //       },
  //       {
  //         accord_head: "Bottoms",
  //         accord_body: [
  //           ["Pants", "16.00"],
  //           ["Jeans", "16.00"],
  //           ["Skirts", "16.00"],
  //           ["Shorts", "12.00"],
  //         ].map((el) => {
  //           return { catg: el[0], cost: `$${el[1]}` };
  //         }),
  //       },
  //       {
  //         accord_head: "Household",
  //         accord_body: [
  //           ["Tablecloth", "30.00"],
  //           ["Napkin", "9.00"],
  //           ["Bathrobe", "20.00"],
  //           ["Towel", "15.00"],
  //           ["Bathmat", "15.00"],
  //           ["Pillow Case", "15.00"],
  //           ["Fitted Sheet", "30.00"],
  //           ["Flat Sheet", "30.00"],
  //           ["Blanket", "40.00"],
  //           ["Duvet Cover", "40.00"],
  //           ["Comforter", "55.00"],
  //           ["Shower Curtain", "25.00"],
  //         ].map((el) => {
  //           return { catg: el[0], cost: `$${el[1]}` };
  //         }),
  //       },
  //       {
  //         accord_head: "Dresses",
  //         accord_body: [
  //           ["One Piece", "25.00"],
  //           ["Two Piece", "30.00"],
  //           ["Cocktail Dress", "30.00"],
  //           ["Gown", "30.00"],
  //           ["Jumpsuit", "25.00"],
  //           ["Romper", "25.00"],
  //         ].map((el) => {
  //           return { catg: el[0], cost: `$${el[1]}` };
  //         }),
  //       },
  //       {
  //         accord_head: "Outerwear",
  //         accord_body: [
  //           ["Trench Coat", "40.00"],
  //           ["Water Repellant (Extra)", "30.00"],
  //           ["Jacket", "35.00"],
  //           ["Parka", "45.00"],
  //           ["Coat", "45.00"],
  //           ["Raincoat", "40.00"],
  //           ["Peacoat", "45.00"],
  //           ["Duffle Coat", "45.00"],
  //           ["", ""],
  //         ].map((el) => {
  //           return { catg: el[0], cost: el[1] != "" ? `$${el[1]}` : "" };
  //         }),
  //       },
  //     ],
  //   },
  //   {
  //     title: "Laundry",
  //     body: [
  //       ..."Wash&Fold,wash".split(",").map((el, i) => {
  //         return {
  //           accord_head: el,
  //           accord_body: [
  //             { catg: "10lb minimum", cost: "$2.50" },
  //             {
  //               catg: "",
  //               cost: (
  //                 <>
  //                   $3.00
  //                   <br />
  //                   per lb
  //                 </>
  //               ),
  //             },
  //             { catg: "", cost: "" },
  //           ],
  //         };
  //       }),
  //     ],
  //   },
  //   {
  //     title: "Alterations",
  //     body: [
  //       ...[
  //         ["Hemming", "25.00"],
  //         ["Take in/out", "25.00"],
  //         ["Patching", "25.00"],
  //         ["Rips", "15.00"],
  //       ].map((el) => {
  //         return {
  //           accord_head: el[0],
  //           cost: `$${el[1]}`,
  //         };
  //       }),
  //     ],
  //   },
  //   {
  //     title: "Hand Pressed",
  //     body: [
  //       ...[
  //         ["Shirts", "11.50"],
  //         ["Sheets", "15.00"],
  //         ["Pillowcases", "10.00"],
  //       ].map((el) => {
  //         return {
  //           accord_head: el[0],
  //           cost: `$${el[1]}`,
  //         };
  //       }),
  //     ],
  //   },
  // ];
  useEffect(() => {
    setmenu(true);
    setjuliette_bg(true);
    get_pricelist("", current_store.user.token, (data1) => {
      let temp_pricelist = data1;
      console.log(temp_pricelist);
      // .filter((el) => el.delete_status != 1);
      // console.log(temp_pricelist);
      temp_pricelist = temp_pricelist.map((el) => {
        if (el.has_child) {
          return {
            title: el.name,
            haschild: el.has_child,
            delete: el.delete_status === 1 || el.show_client_status === 1,
            body: [
              ...el.items.map((el1) => {
                return {
                  accord_head: el1.name,
                  delete:
                    el1.delete_status === 1 || el1.show_client_status === 1,
                  accord_body: [
                    ...el1.items.map((el2) => {
                      return {
                        catg: el2.name,
                        cost: "$" + Number(el2.cost).toFixed(2).toString(),
                        delete:
                          el2.delete_status === 1 ||
                          el2.show_client_status === 1,
                      };
                    }),
                  ],
                };
              }),
            ],
          };
        }
        if (el.special_cost) {
          return {
            title: el.name,
            haschild: false,
            delete: el.delete_status === 1 || el.show_client_status === 1,
            body: [
              ...el.items.map((el1) => {
                return {
                  accord_body: [
                    {
                      catg: `${el1.cost.min_weight}lb minimum`,
                      cost: `$${Number(el1.cost.min_cost)
                        .toFixed(2)
                        .toString()}`,
                      delete:
                        el1.delete_status === 1 || el1.show_client_status === 1,
                    },
                    {
                      catg: "",
                      cost: (
                        <>
                          ${Number(el1.cost.per_cost).toFixed(2)}
                          <br />
                          per lb
                        </>
                      ),
                    },
                    { catg: "", cost: "" },
                  ],
                  accord_head: el1.name,
                };
              }),
            ],
          };
        }
        return {
          title: el.name,
          haschild: false,
          delete: el.delete_status === 1 || el.show_client_status === 1,
          body: [
            ...el.items.map((el1) => {
              return {
                accord_head: el1.name,
                cost: "$" + Number(el1.cost).toFixed(2).toString(),
                delete: el1.delete_status === 1 || el1.show_client_status === 1,
              };
            }),
          ],
        };
      });
      console.log(temp_pricelist);
      setpricelist([...temp_pricelist]);
      // console.log("data1", data1);
      // console.log("pricelist", temp_pricelist, data);
    });
  }, []);
  // const data1 = [[data[0]], [data[1], data[2]], [data[3]]];
  // const data1 = [
  //   ...data.map((el) => [el]),
  //   ...data.map((el) => [el]),
  //   ...data.map((el) => [el]),
  // ];
  // console.log(data, data1);

  const [current_open, setcurrent_open] = useState();
  const [columncount, setcolumncount] = useState(0);

  const switch_acccordian = (current_id, count_item) => {
    let accordian_body = document.getElementsByClassName("accordian_body");
    [...accordian_body].map((el) => {
      el.style = "";
      el.className = "accordian_body";
    });
    if (current_open != current_id) {
      document.getElementById(current_id).classList.toggle("active");
      setcurrent_open(current_id);
      if (document.getElementById("pricelist").offsetWidth < 704) {
        // counts the height of the respective accordian to open
        document.getElementById(current_id).style.height =
          count_item * 18 + count_item * 12 + 10 + 22 + "px";
      } else {
        // counts the height of the respective accordian to open
        document.getElementById(current_id).style.height =
          count_item * 18 + (count_item - 1) * 12 + 10 + 22 + "px";
      }
    } else {
      setcurrent_open("");
    }
  };
  useEffect(() => {
    // calculates number of coumns to show w.r.t the data provided if you want a horizontal scroll
    // let count = 0;
    // data1.map((el) => {
    //   let x = el[0].body.length > 5 ? (count += 2) : (count += 1);
    // });
    // console.log(Math.ceil(count / 2));
    // setcolumncount(Math.ceil(count / 2) + 1);
  }, []);

  return (
    <div className="pricelist">
      <div className="page_title" id="pricelist">
        Pricelist
      </div>
      <div className="pricelist_main_body">
        <style>
          {`[pricelist_column_count='${columncount}']{column-count:${columncount}}`}
        </style>
        <div className="pricelist_body" pricelist_column_count={columncount}>
          {/* {data1.map((main_el) => ( */}
          {[
            ...pricelist.map((el) => [el]),
            // ...pricelist.map((el) => [el]),
            // ...pricelist.map((el) => [el]),
          ].map((main_el) => (
            <>
              {/* // className="pricelist_body_height" */}
              {main_el.map((el, i) => {
                if (el.delete) {
                  return "";
                }
                return (
                  <div key={i} className={"body_colm colm" + (i + 1)}>
                    <div className="body_colm_title f30">{el.title}</div>
                    <div className="body_colm_body">
                      {el.body.map((el1, i1) => {
                        if (el1.delete) {
                          return "";
                        }
                        return (
                          <div
                            key={i1}
                            className="accordian_body"
                            id={
                              el.title.split(" ").join("").toLowerCase() +
                              "_accordian_body_" +
                              i1
                            }
                          >
                            <div
                              className={
                                "accordian_head " +
                                (el1.accord_body ? " f18" : " f16")
                              }
                              onClick={() =>
                                switch_acccordian(
                                  el.title.split(" ").join("").toLowerCase() +
                                    "_accordian_body_" +
                                    i1,
                                  el1.accord_body.length
                                )
                              }
                            >
                              {el1.accord_head}
                              {el1.accord_body ? (
                                <img className="arrowdown" src={arrow_down} />
                              ) : (
                                <div className="xost_div">{el1.cost}</div>
                              )}
                            </div>
                            <div className="accordian_content f14">
                              {el1.accord_body
                                ? el1.accord_body.map((el2, i3) => {
                                    if (el2.delete) {
                                      return "";
                                    }
                                    return (
                                      <div key={i3}>
                                        <div>{el2.catg}</div>
                                        <div>{el2.cost}</div>
                                      </div>
                                    );
                                  })
                                : ""}
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </>
          ))}

          {/* <div className='body_colm colm1'>
          <div className='body_colm_title'>Dry Cleaning</div>
          <div className='body_colm_body'>
          
          </div>
          </div>
          <div className='body_colm colm2'>
          <div className='body_colm_title'>Laundry</div>
          <div className='body_colm_body'>
          
          </div>
          </div>
          <div className='body_colm colm3'>
          <div className='body_colm_title'></div>
        </div> */}
        </div>
      </div>
    </div>
  );
};
