import React, { useEffect, useState } from "react";
import { check_img_loaded } from "./CommonComponents/loader_data";
import { ReactComponent as Email_icon } from "../img/profile/email_icon.svg";
import { ReactComponent as Call_icon } from "../img/profile/call_icon.svg";
import { ReactComponent as Message_icon } from "../img/profile/message_icon.svg";
import { RTE } from "./Rich text editor/RTE";
import { get_faq } from "./API/API";

export const faq_link_icons = [
  { title: "email", svg: <Email_icon /> },
  { title: "call", svg: <Call_icon /> },
  { title: "msg", svg: <Message_icon /> },
];

export const FAQ_copy = ({ setmenu, setjuliette_bg, setloader }) => {
  const [faq_data, setfaq_data] = useState([]);

  useEffect(() => {
    // setpage_loaded(false);
    setjuliette_bg(false);
    setmenu(true);
    setloader(true);
    get_faq({ type: 2 }, "", (data) => {
      setfaq_data(data.data.filter((el) => el.is_deleted != true));
      console.log(data.data);
      setloader(false);
    });
    // check_img_loaded(faq_bg, setloader);
    // window.onload = () => {
    //   // console.log(1);
    //   setpage_loaded(true);
    // };
  }, []);

  return (
    <div
      className="faq"
      style={{
        backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(https://julietteimages.s3.amazonaws.com/systemimages/faq-min.jpg)`,
        // backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://github.com/Punit-esh/JC/blob/mani/src/img/how_it_works_bg.svg')`,
      }}
    >
      <div className="retain_navbar"></div>
      <div className="faq_body faq_copy">
        <div className="page_title">
          <div>FAQ</div>
        </div>
        {/* {faq_data.map((el, i) => (
          <div key={i} className="faq_block">
            <div className="f18">
              <div>Q:</div>
              <div>{el.Q}</div>
            </div>
            <div className="f16">
              <div>A:</div>
              <div>{el.A}</div>
            </div>
          </div>
        ))} */}
        {faq_data.map((el) => (
          <div className="preview_body">
            <div className="question f18">
              <div>Q:</div>
              <div>
                <RTE read_only={true} msg={el.que} />
              </div>
            </div>
            <div className="answer f16">
              <div>A:</div>
              <div>
                <RTE read_only={true} msg={el.ans} />
              </div>
            </div>
            <div className="links_group">
              {el.links?.map((el1, i) => (
                <div className="link_btn_items">
                  <div className="link_btn">
                    <a href={el1.href} target="_blank">
                      {el1.img && (
                        <div className="link_img">
                          {
                            faq_link_icons.filter(
                              (el2) => el1.img === el2.title
                            )[0].svg
                          }
                        </div>
                      )}
                      <div className="f14">{el1.txt}</div>
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
