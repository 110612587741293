import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { add_card } from "../../API/API";
import { encrypt_data } from "../../../RSA_Encryption/RSA";
import { Loading } from "../../CommonComponents/Loading";
const month_count = [
  [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
  [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
];

export const AddPaymentMethod = ({ cancel, profile, setprofile }) => {
  // const [card_details, setcard_details] = useState({
  //   nameoncard: "",
  //   cardno: "",
  //   expirydate: "",
  //   cvv: "",
  // });
  const current_store = useSelector((state) => state);
  const navigate = useNavigate();

  const [show_this_page, setshow_this_page] = useState(true);

  const [nameoncard, setnameoncard] = useState("");
  const [cardnumber, setcardnumber] = useState("");
  const [expirydate, setexpirydate] = useState("");
  const [cvv, setcvv] = useState("");
  const [is_default, setis_default] = useState(false);

  const [nameoncard_err, setnameoncard_err] = useState("");
  const [cardnumber_err, setcardnumber_err] = useState("");
  const [expirydate_err, setexpirydate_err] = useState("");
  const [cvv_err, setcvv_err] = useState("");

  const [add_btn, setadd_btn] = useState("type2");

  const [card_err, setcard_err] = useState("");

  useEffect(() => {
    if (
      nameoncard != "" &&
      cardnumber != "" &&
      expirydate != "" &&
      cvv != "" &&
      nameoncard_err == "false" &&
      cardnumber_err == "false" &&
      expirydate_err == "false" &&
      cvv_err == "false"
    ) {
      return setadd_btn("type3");
    }
    setadd_btn("type2");
  }, [
    nameoncard,
    cardnumber,
    expirydate,
    cvv,
    nameoncard_err,
    cardnumber_err,
    expirydate_err,
    cvv_err,
  ]);

  const set_value_carddate = (current) => {
    // checks for a number as a input and also if cheks for while backspacing for '' and '/' if dsabled you wouldnt be able to backspace in card month
    if (
      Number(current.value.substr(-1)) == current.value.substr(-1) ||
      current.value.substr(-1) == " " ||
      current.value.substr(-1) == "/"
    ) {
      if (current.value.replaceAll("/", "").replaceAll(" ", "").length < 5) {
        if (current.value.replaceAll("/", "").replaceAll(" ", "").length != 4) {
          setexpirydate_err("true");
        } else {
          let entered_date = new Date(
            `20${current.value.split("/")[1].trim()}-${current.value
              .split("/")[0]
              .trim()}-${
              month_count[
                (2000 + current.value.split("/")[1].trim()) % 4 == 0 ? 1 : 0
              ][Number(current.value.split("/")[0].trim())]
            }T00:00:00`
          );
          console.log(entered_date);
          let current_date = new Date(
            `${new Date().getFullYear()}-${
              new Date().getMonth() < 9 ? "0" : ""
            }${new Date().getMonth() + 1}-${
              new Date().getDate() < 10 ? "0" : ""
            }${new Date().getDate()}T00:00:00`
          );
          setexpirydate_err((entered_date - current_date <= 0).toString());
        }
        let data = "";
        // adds a ' / ' etween two numbers and doesnot lets user to enter the first number greater than 12 if it is then changes eg. 13 =  01 / 3
        if (
          current.value.length == 3 &&
          expirydate.length + 1 <= current.value.length
        ) {
          setexpirydate(expirydate + " / " + current.value.slice(-1));
          data = current.value.slice(0, 2) + "/" + current.value.slice(-1);
        } else {
          if (current.value > 12) {
            let date_arr = current.value.split("");
            setexpirydate("0" + date_arr[0] + " / " + date_arr[1]);
            data = "0" + date_arr[0] + " / " + date_arr[1];
          } else {
            // console.log(current.value.length);
            if (current.value.length == 5 && current.value.substr(-1) == " ") {
              return setexpirydate(current.value.slice(0, 2));
            }
            setexpirydate(current.value);
            data = current.value;
          }
        }
      }
    }
  };
  const set_value_cardno = (current) => {
    // checks for a number as a input
    if (Number(current.value.substr(-1)) == current.value.substr(-1)) {
      // checks for the length of value to be less than 20
      console.log(current.value, current.value.replaceAll(" ", "").length);
      if (current.value.replaceAll(" ", "").length >= 20) {
        return;
      }
      if (current.value.replaceAll(" ", "").length < 20) {
        setcardnumber_err(
          (
            [15, 16, 19].indexOf(current.value.replaceAll(" ", "").length) == -1
          ).toString()
        );
        // puts spaces between 4 digits eg. 1234 1234 1234 1234
        if (
          [5, 10, 15, 20].indexOf(current.value.length) > -1 &&
          cardnumber.length <= current.value.length
        ) {
          setcardnumber(cardnumber + " " + current.value.slice(-1));
        } else {
          if (
            [5, 10, 15, 20].indexOf(current.value.length) > -1 &&
            current.value.slice(-1) == " "
          ) {
            return setcardnumber(current.value.trim());
          }
          setcardnumber(current.value);
        }
      }
    }
  };
  const set_value_cardcvv = (current) => {
    if (current.value > 99) {
      setcvv_err("false");
    } else {
      setcvv_err("true");
    }
    if (current.value <= 9999) {
      setcvv(current.value);
    }
  };
  return (
    <>
      {!show_this_page ? (
        <Loading />
      ) : (
        <div className="profile_modal_common_card">
          <div className="card_heading">
            Add Payment
            <br />
            Method
          </div>
          <div className="card_title">PAYMENT DETAILS</div>
          <div className="card_input">
            <input
              type="text"
              placeholder="Name on Card"
              value={nameoncard}
              inp_error={nameoncard_err}
              onChange={(e) => {
                setnameoncard(e.target.value);
                setnameoncard_err((e.target.value == "").toString());
                setcard_err("");
              }}
            />
          </div>
          <div className="card_input">
            <input
              type="text"
              placeholder="Card Number"
              value={cardnumber}
              inp_error={cardnumber_err}
              onChange={(e) => {
                set_value_cardno(e.target);
                setcard_err("");
              }}
            />
          </div>
          <div className="card_input">
            <input
              type="text"
              placeholder="MM/YY"
              value={expirydate}
              inp_error={expirydate_err}
              onChange={(e) => {
                set_value_carddate(e.target);
                setcard_err("");
              }}
            />
          </div>
          <div className="card_input">
            <input
              type="text"
              placeholder="CVV"
              value={cvv}
              inp_error={cvv_err}
              onChange={(e) => {
                set_value_cardcvv(e.target);
                setcard_err("");
              }}
            />
          </div>
          <div
            className="card_input doorman_checkbox"
            onClick={() => {
              setis_default(!is_default);
            }}
          >
            <div className="tick_checkbox">
              <input mouse="pointer" type="checkbox" checked={is_default} />
              <label>
                <div className="show_cust_checkbox"></div>
              </label>
            </div>
            <label mouse="pointer">Set as default payment</label>
          </div>
          {card_err && (
            <div className="error" error="true">
              {card_err}
            </div>
          )}
          <div className="margin_bottom_auto"></div>
          <div className="btn_group btn_group_horizontal">
            <div btn_type="type1" onClick={cancel}>
              Cancel
            </div>
            <div
              className={add_btn == "type2" && "disabled"}
              btn_type={add_btn}
              onClick={() => {
                if (add_btn != "type3") return;
                setshow_this_page(false);
                add_card(
                  {
                    card_name: encrypt_data(nameoncard),
                    card_number: encrypt_data(cardnumber.split(" ").join("")),
                    card_expiry_month: encrypt_data(
                      expirydate.split("/")[0].trim()
                    ),
                    card_expiry_year: encrypt_data(
                      expirydate.split("/")[1].trim()
                    ),
                    card_cvc: encrypt_data(cvv),
                    primary:
                      profile.card_arr.length === 0
                        ? "1"
                        : is_default
                        ? "1"
                        : "0",
                    // "is_default": profile.add_arr.length===0 ? '1' :add_details.default ? '1' : '0'
                    customer_id: profile.customer_id,
                  },
                  current_store.user.token,
                  (data) => {
                    console.log(data);
                    if (!data.status) {
                      return navigate("/404");
                    }
                    if (data.card_err) {
                      setshow_this_page(true);
                      // setTimeout(()=>{
                      console.log("data.card_err", data.card_err);
                      setcard_err(data.card_err);
                      // },2000)
                      return;
                    }
                    setprofile({
                      ...profile,
                      card_arr: [
                        ...data.msg.map((el) => {
                          return {
                            id: el.id,
                            nameoncard: el.cc_name,
                            cardno: `**** **** **** ${el.cc_last_4_digits}`,
                            default_card: el.is_primary == "0" ? false : true,
                          };
                        }),
                      ],
                    });
                    setshow_this_page(true);
                    cancel();
                  }
                );
                // let temp_arr = [...profile.card_arr];
                // temp_arr.push({
                //   nameoncard: nameoncard,
                //   cardno: cardnumber,
                //   expirydate: expirydate,
                //   cvv: cvv,
                //   default_card: false,
                // });
                // setprofile({
                //   ...profile,
                //   card_arr: temp_arr,
                // });
              }}
            >
              Add
            </div>
          </div>
        </div>
      )}
    </>
  );
};
