import axios from "axios";
// temporary@12345
// temporary@123456
// ppunitesh@gmail.com
// punitesh@siti-solutions.com
const url = "https://api.juliettecleaners.com/";
// const url = "https://ff9a-122-50-197-91.in.ngrok.io/";
// const url = 'https://1e3b-182-48-201-241.in.ngrok.io/'
const base_url = `${url}api/clients/v1/`;
// const base_url = `${url}api/pos/v1/`
const end_point = {
  SignUp: "sign-up",
  SignIn: "login",
  Ver̥ifyPassword: "verify-password",
  VerifyToken: "verify-token",
  SendSmsOtp: "send-sms-otp",
  ResendSmsOtp: "resend-sms-otp",
  VerifySmsOtp: "verify-sms-otp",
  EditPassword: "edit-password",
  EditEmail: "edit-email",
  // not done - phone
  EditPhone: "edit-phone",
  EditImage: "edit-image",
  EditName: "edit-name",
  AddAddress: "add-address",
  EditAddress: "edit-address",
  AddCard: "add-card",
  DeleteAddress: "delete-address",
  DeleteImage: "delete-image",
  DeleteCard: "delete-card",
  SetDefaultAddress: "set-default-address",
  SetDoorman: "set-doorman",
  SetDefaultCard: "set-default-card",
  GetProfile: "my-profile",
  SendEmailOtp: "send-email-otp",
  ResendEmailOtp: "resend-email-otp",
  VerifyEmailOtp: "verify-email-otp",
  GetAddressArray: "my-addresses",
  RequestPickup: "request-pickup",
  // not integrated as it need to be after my-order
  CancelOrder: "cancel-pickup",
  GetOrder: "get-orders",
  GetOrderById: "get-order-by-id",
  ResetPassword: "reset-password",
  GetOrderImages: "get-order-images",
  GetPricelist: "get-price-list",
  // order-items api
  GetOrderFiledata: "get-order-filedata",

  GetFaq: "get-faq",
};

const convert_phone_simple = (phone_no) => {
  let phone_no_withoutextra = phone_no.split(" ");
  phone_no_withoutextra.shift();
  phone_no_withoutextra = `1${phone_no_withoutextra.join("")}`;
  return phone_no_withoutextra;
};

export const send_sms_otp = async (phone_no, callback) => {
  // return callback(
  //     {
  //         // otp:'583130',
  //         status: true,
  //         msg: 'otp sent locally'
  //     }
  // )

  // 9159969739 - 583130
  // 7864206154 - 848864
  axios
    .post(`${base_url}${end_point.SendSmsOtp}`, {
      mobile: convert_phone_simple(phone_no),
    })
    .then((response) => response.data)
    .then((response) => {
      // console.log('send_otp_response', response);
      if (response.error) {
        return callback({
          status: false,
          msg: response.error,
        });
      }
      return callback({
        status: true,
        msg: response.message,
      });
    });
};
export const verify_sms_otp = ({ phone_no, otp }, callback) => {
  // return callback(
  //     {
  //         status: true,
  //     }
  // )
  axios
    .post(`${base_url}${end_point.VerifySmsOtp}`, {
      mobile: convert_phone_simple(phone_no),
      otp: otp,
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.error) {
        return callback({
          status: false,
          msg: response.error,
        });
      }

      if (response.otpVerified) {
        return callback({
          status: true,
          msg: response.token,
        });
      } else {
        return callback({
          status: false,
          msg: response,
        });
      }
    });
};
export const resend_sms_otp = (phone_no, callback) => {
  axios
    .post(`${base_url}${end_point.ResendSmsOtp}`, {
      mobile: convert_phone_simple(phone_no),
    })
    .then((response) => response.data)
    .then((response) => {
      if (response.error) {
        return callback({
          status: false,
          msg: response.error,
        });
      }
      return callback({
        status: true,
        msg: response.message,
      });
    });
};
const sign_up_errors = [
  // user already
  "Insert Query Client Failed",
];
export const sign_up = (signup_data, callback) => {
  // console.log(signup_data);
  let user_signup_data = {
    ...signup_data,
    // email: 'temp2@mail.com'
  };
  if (signup_data.phone) {
    user_signup_data = {
      ...user_signup_data,
      phone: convert_phone_simple(signup_data.phone),
    };
  }
  axios
    .post(`${base_url}${end_point.SignUp}`, {
      ...user_signup_data,
    })
    .then((response) => response.data)
    .then((response) => {
      if (!response.token || response.error) {
        return callback({
          status: false,
          msg: response.error,
          error_card: response.invalidCard == true,
        });
      }
      localStorage.setItem("juliette_user_token", response.token);
      return callback({
        status: true,
        msg: response,
      });
      callback(response);
    });
  // {
  //     "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6InRlbXAxQG1haWwuY29tIiwiaWQiOjEzLCJpYXQiOjE2NTY1MzM3NDN9.jEUH_k1Da6vtcNJWKaAwy3DVtBwDAZhyU1lQAiOz8K8",
  //     "status": "Client added succesfully"
  // }
};

export const sign_in = (sign_in_data, callback) => {
  axios
    .post(`${base_url}${end_point.SignIn}`, {
      ...sign_in_data,
    })
    .then((response) => response.data)
    .then((response) => {
      // console.log('login_api', response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      localStorage.setItem("juliette_user_token", response.token);
      return callback({
        status: true,
        token: response.token,
      });
    });
  // return
};
export const verify_local_token = (token, callback) => {
  axios
    .post(
      `${base_url}${end_point.VerifyToken}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        localStorage.removeItem("juliette_user_token");
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        token: token,
      });
    });
};
export const get_add = (token, callback) => {
  // console.log('my address', token);
  axios
    .post(
      `${base_url}${end_point.GetAddressArray}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      if (response.error) {
        localStorage.removeItem("juliette_user_token");
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response,
      });
    });
};
export const request_pickup = (request_pickup_data, token, callback) => {
  axios
    .post(
      `${base_url}${end_point.RequestPickup}`,
      { ...request_pickup_data },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // callback({
      //     status: false
      // })
      // console.log('request_pickup api',response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response.order_id,
      });
    });
};
export const cancel_order = (order_id, token, callback) => {
  axios
    .post(
      `${base_url}${end_point.CancelOrder}`,
      { order_id: order_id },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response.status,
      });
    });
};
export const get_profile = (token, callback) => {
  axios
    .post(
      `${base_url}${end_point.GetProfile}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response,
      });
    });
};
export const change_profile_image = (photo_base64, token, callback) => {
  axios
    .post(
      `${base_url}${end_point.EditImage}`,
      {
        base64: photo_base64.split(",")[1],
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response.profile_photo,
      });
    })
    .catch((err) => {
      return callback({
        status: false,
        img_error: true,
      });
    });
};
export const delete_profile_image = (photo_base64, token, callback) => {
  axios
    .post(
      `${base_url}${end_point.DeleteImage}`,
      {},
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response.profile_photo,
      });
    })
    .catch((err) => {
      return callback({
        status: false,
        img_error: true,
      });
    });
};
export const verify_password = (password, token, callback) => {
  axios
    .post(
      `${base_url}${end_point.Ver̥ifyPassword}`,
      {
        password: password,
      },
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response,
      });
    });
  // .catch(err => {
  //     return callback({
  //         status: false,
  //         img_error: true
  //     })
  // })
};
export const edit_name = (user_token, temp_token, new_name, callback) => {
  axios
    .post(
      `${base_url}${end_point.EditName}`,
      {
        token: temp_token,
        name: new_name,
      },
      {
        headers: {
          Authorization: user_token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response.name,
      });
    });
};
export const send_email_otp = (email_otp_obj, user_token, callback) => {
  axios
    .post(
      `${base_url}${end_point.SendEmailOtp}`,
      {
        ...email_otp_obj,
      },
      {
        headers: {
          Authorization: user_token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
          msg: response.error,
        });
      }
      return callback({
        status: true,
        msg: response,
      });
    });
};
export const just_verify_email = (verify_email_obj, user_token, callback) => {
  axios
    .post(
      `${base_url}${end_point.VerifyEmailOtp}`,
      {
        ...verify_email_obj,
        id: "",
      },
      {
        headers: {
          Authorization: user_token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      if (response.otpVerified == false) {
        return callback({
          status: true,
          otp_error: true,
        });
      }
      return callback({
        status: true,
        msg: response,
      });
    });
};
export const verify_email = (verify_email_obj, user_token, callback) => {
  axios
    .post(
      `${base_url}${end_point.VerifyEmailOtp}`,
      {
        ...verify_email_obj,
        id: "",
      },
      {
        headers: {
          Authorization: user_token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      if (response.otpVerified == false) {
        return callback({
          status: true,
          otp_error: true,
        });
      }
      axios
        .post(
          `${base_url}${end_point.EditEmail}`,
          {
            email: verify_email_obj.email,
            token: response.token,
            id: verify_email_obj.id,
          },
          {
            headers: {
              Authorization: user_token,
            },
          }
        )
        .then((response) => response.data)
        .then((response) => {
          if (response.error) {
            return callback({
              status: false,
            });
          }
          return callback(response);
        });
    });
};
export const resend_email_otp = (email_otp_obj, user_token, callback) => {
  axios
    .post(
      `${base_url}${end_point.ResendEmailOtp}`,
      {
        ...email_otp_obj,
      },
      {
        headers: {
          Authorization: user_token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response,
      });
    });
};

export const edit_password = (password_obj, user_token, callback) => {
  axios
    .post(
      `${base_url}${end_point.EditPassword}`,
      {
        ...password_obj,
      },
      {
        headers: {
          Authorization: user_token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response.password_length,
      });
    });
};
export const edit_phone = (token, phone, user_token, callback) => {
  axios
    .post(
      `${base_url}${end_point.EditPhone}`,
      {
        token: token,
        phone: convert_phone_simple(phone),
      },
      {
        headers: {
          Authorization: user_token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response,
      });
    });
};
export const add_address = (add_obj, user_token, callback) => {
  axios
    .post(
      `${base_url}${end_point.AddAddress}`,
      {
        ...add_obj,
      },
      {
        headers: {
          Authorization: user_token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response.address,
      });
    });
};
export const edit_address = (add_obj, user_token, callback) => {
  // console.log('edit address', add_obj);
  axios
    .post(
      `${base_url}${end_point.EditAddress}`,
      {
        ...add_obj,
      },
      {
        headers: {
          Authorization: user_token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response.address,
      });
    });
};
export const edit_default_address = (add_id, user_token, callback) => {
  axios
    .post(
      `${base_url}${end_point.SetDefaultAddress}`,
      {
        address_id: add_id,
      },
      {
        headers: {
          Authorization: user_token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response.address,
      });
    });
};
export const edit_doorman = (doorman_obj, user_token, callback) => {
  axios
    .post(
      `${base_url}${end_point.SetDoorman}`,
      {
        address_id: doorman_obj.id,
        doorman: doorman_obj.doorman ? "1" : "0",
      },
      {
        headers: {
          Authorization: user_token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response.address,
      });
    });
};
export const add_card = (card_obj, user_token, callback) => {
  axios
    .post(
      `${base_url}${end_point.AddCard}`,
      {
        ...card_obj,
      },
      {
        headers: {
          Authorization: user_token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        if (response.error === "Invalid Card") {
          return callback({
            status: true,
            card_err: "Invalid Card",
          });
        }
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response.cards,
      });
    });
};
export const edit_default_card = (card_id, user_token, callback) => {
  axios
    .post(
      `${base_url}${end_point.SetDefaultCard}`,
      {
        card_id: card_id,
      },
      {
        headers: {
          Authorization: user_token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response.cards,
      });
    });
};
export const delete_address = (add_id, user_token, callback) => {
  axios
    .post(
      `${base_url}${end_point.DeleteAddress}`,
      {
        address_id: add_id,
      },
      {
        headers: {
          Authorization: user_token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response.address,
      });
    });
};
export const delete_card = (card_id, user_token, callback) => {
  axios
    .post(
      `${base_url}${end_point.DeleteCard}`,
      {
        card_id: card_id,
      },
      {
        headers: {
          Authorization: user_token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response.cards,
      });
    });
};
export const get_order = (user_token, callback) => {
  axios
    .post(
      `${base_url}${end_point.GetOrder}`,
      {},
      {
        headers: {
          Authorization: user_token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response,
      });
    });
};
export const get_order_by_id = (id, user_token, callback) => {
  axios
    .post(
      `${base_url}${end_point.GetOrderById}`,
      {
        id: id,
      },
      {
        headers: {
          Authorization: user_token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response,
      });
    });
};
export const reset_password = (password_obj, user_token, callback) => {
  axios
    .post(
      `${base_url}${end_point.ResetPassword}`,
      {
        ...password_obj,
      },
      {
        headers: {
          Authorization: "",
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response,
      });
    });
};
export const get_order_images = (order_id, user_token, callback) => {
  axios
    .post(
      `${base_url}${end_point.GetOrderImages}`,
      { order_id: order_id },
      {
        headers: {
          Authorization: user_token,
        },
      }
    )
    .then((response) => response.data)
    .then((response) => {
      // console.log(response);
      if (response.error) {
        return callback({
          status: false,
        });
      }
      return callback({
        status: true,
        msg: response,
      });
    });
};
export const get_pricelist = (order_id, user_token, callback) => {
  axios
    .post(
      `${base_url}${end_point.GetPricelist}`,
      // `http://localhost:9000/readfile`,
      {},
      {
        headers: {
          Authorization: user_token,
        },
      }
    )
    .then((data) => data.data)
    .then((data) => {
      callback([...JSON.parse(data)]);
    });
};
export const get_orderfiledata = (order_id, user_token, callback) => {
  return callback([
    {
      item_name: "Laundry - Wash and Fold",
      item_price: "75",
      item_weight: "15",
    },
    { item_name: "Hang Dry", item_price: "24", item_weight: -1 },
  ]);
  axios
    .post(
      `${base_url}${end_point.GetOrderFiledata}`,
      // `http://localhost:9000/readfile`,
      {},
      {
        headers: {
          Authorization: user_token,
        },
      }
    )
    .then((data) => data.data)
    .then((data) => {
      callback(data);
    });
};
export const get_faq = (arr, token, callback) => {
  axios
    .post(
      `${base_url}${end_point.GetFaq}`,
      // `http://localhost:9000/readfile`,
      "",
      {
        headers: {
          Authorization: token,
        },
      }
    )
    .then((res) => res.data)
    .then((res) => {
      if (res.error) {
        return callback({
          status: false,
          data: res,
        });
      }
      return callback({
        status: true,
        data: JSON.parse(res),
      });
    });
};
// export const cancel_pickup = (order_id, user_token, callback) => {
//     axios.post(`${base_url}${end_point.CancelOrder}`, { order_id: order_id }, {
//         headers: {
//             Authorization: token
//         }
//     })
//         .then(response => response.data)
//         .then(response => {
//             if (response.error) {
//                 return callback({
//                     status: false
//                 })
//             }
//             return callback({
//                 status: true,
//                 msg: response.status
//             })
//         })

// }
// remove data:image/png;base64, from img_arr
