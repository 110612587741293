import React, { useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { EnterEmailToGetPassword } from "./ForgetPassword/EnterEmailToGetPassword";
import { EnterVerificationCode } from "./ForgetPassword/EnterVerificationCode";
import { SetNewPassword } from "./ForgetPassword/SetNewPassword";

export const ForgotPassword = ({ setmenu, setjuliette_bg }) => {
  const navigate = useNavigate();
  const [show_forgetpassword_card, setshow_forgetpassword_card] = useState(
    "enteremailtogetcode"
  );
  // enter email to get code -start
  const [email, setemail] = useState("");
  const [temptoken, settemptoken] = useState("");
  // enter email to get code -end

  useEffect(() => {
    setmenu(false);
    setjuliette_bg(true);
  }, []);

  return (
    <div className="forgotpassword">
      <div className="forgotpassword_card">
        <div className="forgotpassword_heading f40">Forgot Password</div>
        {show_forgetpassword_card == "enteremailtogetcode" ? (
          <EnterEmailToGetPassword
            setshow_forgetpassword_card={setshow_forgetpassword_card}
            cancel={() => navigate("/login")}
            email={email}
            setemail={setemail}
            />
            ) : show_forgetpassword_card == "enterverificationcode" ? (
              <EnterVerificationCode
            setshow_forgetpassword_card={setshow_forgetpassword_card}
            cancel={() => navigate("/login")}
            email={email}
            setemail={setemail}
            temptoken ={temptoken} 
            settemptoken ={settemptoken}
          />
        ) : show_forgetpassword_card == "setnewpassword" ? (
          <SetNewPassword 
          cancel={() => navigate("/login")}
          email={email}
          temptoken={temptoken}
          settemptoken={settemptoken}
          />
          ) : (
          ""
        )}
      </div>
    </div>
  );
};
