import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import password_not_shown from "../../img/password_not_shown.svg";
import password_shown from "../../img/password_shown.svg";

import { useDispatch } from "react-redux";
import { OR_LoginThrough } from "../CommonComponents/OR_LoginThrough";
import { email_regex } from "../Data/data";
import { encrypt_data } from "../../RSA_Encryption/RSA";
import { sign_in } from "../API/API";

export const Login = ({ setshow_card, setshow_dots }) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [login_flag, setlogin_flag] = useState("");

  // holds both email aswell as phone 
  const [email, setemail] = useState("");
  const [email_phone_err, setemail_phone_err] = useState("");
  const [email_disabled, setemail_disabled] = useState(false);
  // const [email_err, setemail_err] = useState("");

  const [password, setpassword] = useState("");
  // const [password_err, setpassword_err] = useState("");
  const [token, settoken] = useState("");

  const [login_err, setlogin_err] = useState("");

  const [password_type, setpassword_type] = useState("password");



  useEffect(() => {
    setlogin_err("");
    if (email != "" && password != "" && email_phone_err != 'true') {
      setlogin_flag("true");
    } else {
      setlogin_flag("false");
    }
  }, [email, password]);

  const login_handler = () => {
    // if (email == "testerror@mail.com") {
    //   setlogin_err("true");
    //   setlogin_flag("false");
    //   return;
    // }
    // setlogin_err(false);
    let payload_login = {
      password: encrypt_data(password),
    };
    let login_api_body = {
      idToken: token == '' ? null : token,
      isEmail: false
    }
    if (email.match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    ) != null) {
      payload_login = {
        ...payload_login,
        email: email,
      }
      login_api_body = {
        ...login_api_body,
        isEmail: true
      }
      // console.log('email', payload_login);
    }
    else {
      let phone_data = email.split(' ');
      phone_data.shift();
      payload_login = {
        ...payload_login,
        phone: `1${phone_data.join('')}`,
      }
      // console.log('phone', payload_login);
    }
    login_api_body = {
      ...login_api_body,
      ...payload_login
    }
    sign_in(login_api_body, (data) => {
      if (!data.status) {
        setemail_phone_err(true);
        setlogin_err(true);
        return
      }
      
      dispatch({ type: "login", payload: { token: data.token } });
      navigate("/request-pickup");
      return
    })

    // encrypt_data();
    // console.log(login_api_body);
    // console.log(email, password);
  };

  const OnChangeEmail = (e) => {
    if (e.target.value[0] == " ") {
      return;
    }
    // console.log(e.target.value.slice(0, 4));
    if (
      e.target.value[0] == Number(e.target.value[0]) ||
      e.target.value.slice(0, 4) == "(+1)"
    ) {
      return OnChangePhone(e.target.value);
    }
    setemail(e.target.value);
    setemail_phone_err(
      (
        e.target.value.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) == null
      ).toString()
    );
  };
  const OnChangePhone = (e) => {
    // console.log([9, 13].some((el) => el == e.target.value.length));
    if (
      Number(e.slice(-1)).toString() == "NaN" ||
      e.length > 17 ||
      (e.slice(-1) == " " && ![5, 9, 13].some((el) => el == e.length))
    ) {
      return;
    }
    console.log(e.length);
    setemail_phone_err("true");
    if (e.length == 17) {
      setemail_phone_err("false");
    }
    switch (e.length) {
      case 1:
        return setemail(`(+1) ${e[0]}`);
      case 5:
        return setemail("");
      case 9:
        if (e.slice(-1) == " ") {
          return setemail(e.slice(0, 8));
        }
        return setemail(e.slice(0, 8) + " " + e.slice(-1));
      case 13:
        if (e.slice(-1) == " ") {
          return setemail(e.slice(0, 12));
        }
        return setemail(e.slice(0, 12) + " " + e.slice(-1));

      default:
        return setemail(e);
    }
  };
  const OnChangePassword = (e) => {
    setpassword(e.target.value);
  };
  // console.log('login_logic', email_phone_err, typeof login_err);
  return (
    <>
      <div className="login_card">
        <div className="login_heading f40">Log in</div>
        <div className="f14">DETAILS</div>
        <div className="login_email">
          {/* <input value={email} onChange={(e)=>setemail(e.target.value)}></input> */}
          <input
            value={email}
            className="f14"
            placeholder="Email/ Mobile number"
            onChange={OnChangeEmail}
            // onChange={(e) => setemail(e.target.value)}
            disabled={email_disabled}
            inp_error={email_phone_err.toString()}
          ></input>
          {/* <label value={email} className='f14'>Email Address</label> */}
          {/* <label className='f14'>Email Address</label> */}
        </div>
        <div className="login_password">
          <input
            value={password}
            type={password_type}
            className="f14"
            placeholder="Password"
            onChange={OnChangePassword}
            // onChange={(e) => setpassword(e.target.value)}
            inp_error={login_err.toString()}
          ></input>
          <img
            mouse="pointer"
            className="password_img"
            src={password_type === "text" ? password_shown : password_not_shown}
            onClick={() => {
              password_type === "password"
                ? setpassword_type("text")
                : setpassword_type("password");
            }}
          />
          {/* <input value={password} onChange={(e)=>setpassword(e.target.value)}></input> */}
          {/* <label className='f14'>Password</label> */}
        </div>

        {
          login_err
          &&
          <div className="error f12" error={login_err.toString()}>
            Invalid login information.
          </div>
        }
        <div className="login_forgotpassword">
          <label className="f14" onClick={() => navigate("/forgot-password")}>
            Forgot Password?
          </label>
        </div>
        <div className="login_createaccount f13 f-400" style={{width:'fit-content'}} mouse="pointer"
          onClick={() => {
            setshow_card("letsgetstarted");
            setshow_dots("letsgetstarted");
          }}>
          <div>Don't have an account?</div>
          <div

          >
            Join Now.
          </div>
        </div>
        <div
          className={"login_submit" + (login_flag == "true" ? "" : " disable")}
          mouse="pointer"
          onClick={() => {
            login_flag == "true" && login_handler();
          }}
        >
          login
        </div>
        <OR_LoginThrough
          setemail={setemail}
          setemail_disabled={setemail_disabled}
          settoken={settoken}
          setlogin_err={setlogin_err}
        />
      </div>
    </>
  );
};
