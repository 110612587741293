// import { faExternalLinkAlt, faUnlink } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// Import the Slate editor factory.
import {
  createEditor,
  Editor,
  Text,
  Transforms,
  Element as SlateElement,
  Path,
} from "slate";
import { withHistory } from "slate-history";

// Import the Slate components and React plugin.
import {
  Slate,
  Editable,
  withReact,
  useSlate,
  ReactEditor,
  useSlateStatic,
  useSelected,
  useFocused,
} from "slate-react";
// import { RenderElement } from "./components/RenderElement";
// import {  } from "./components/ui components/Toolbar";
// import { Button, Icon } from "./components/ui components/Toolbar.tsx";
import "./RTEStyle.scss";
import { RTE_Icons } from "./RTE_Icons";

const initialValue = [
  // {
  //   type: "paragraph",
  //   children: [
  //     { text: "This is editable " },
  //     { text: "rich", bold: true },
  //     { text: " text, " },
  //     { text: "much", italic: true },
  //     { text: " better than a " },
  //     { text: "<textarea>", code: true },
  //     { text: "!" },
  //   ],
  // },
  // {
  //   type: "paragraph",
  //   children: [
  //     {
  //       text: "Since it's rich text, you can do things like turn a selection of text ",
  //     },
  //     { text: "bold", bold: true },
  //     {
  //       text: ", or add a semantically rendered block quote in the middle of the page, like this:",
  //     },
  //   ],
  // },
  // {
  //   type: "block-quote",
  //   children: [{ text: "A wise quote." }],
  // },
  // {
  //   type: "paragraph",
  //   align: "center",
  //   children: [{ text: "Try it out for yourself!" }],
  // },
  {
    type: "paragraph",
    align: "left",
    children: [{ text: "" }],
  },
];
const LIST_TYPES = ["numbered-list", "bulleted-list"];
const TEXT_ALIGN_TYPES = ["left", "center", "right", "justify"];
export const RTE = ({ read_only, msg, cust_placeholder, setelement }) => {
  // console.log(msg, read_only);
  const editor = useMemo(() => withHistory(withReact(createEditor())), []);

  const renderElement = useCallback(
    (props) => <RenderElement {...props} />,
    []
  );

  const renderLeaf = useCallback((props) => <Leaf {...props} />, []);

  const CustomEditor = {
    checkActive: {
      isBoldMarkActive(editor) {
        const [match] = Editor.nodes(editor, {
          match: (n) => n.bold === true,
          universal: true,
        });
        return !!match;
      },

      isCodeBlockActive(editor) {
        const [match] = Editor.nodes(editor, {
          match: (n) => n.type === "code",
        });
        return !!match;
      },

      isCodeBlockTestRed(editor) {
        const [match] = Editor.nodes(editor, {
          match: (n) => n.type === "test-red",
        });
        return !!match;
      },
      isCodeBlockTestBlue(editor) {
        const [match] = Editor.nodes(editor, {
          match: (n) => n.type === "test-blue",
        });
        return !!match;
      },
    },

    toggleTestRed(editor) {
      const isActive = CustomEditor.checkActive.isCodeBlockTestRed(editor);
      Transforms.setNodes(
        editor,
        { type: isActive ? null : "test-red" },
        { match: (n) => Editor.isBlock(editor, n) }
      );
    },

    isCodeBlockTestBlue(editor) {
      const [match] = Editor.nodes(editor, {
        match: (n) => n.type === "test-blue",
      });

      return !!match;
    },

    toggleTestBlue(editor) {
      const isActive = CustomEditor.checkActive.isCodeBlockTestBlue(editor);
      Transforms.setNodes(
        editor,
        { type: isActive ? null : "test-blue" },
        { match: (n) => Editor.isBlock(editor, n) }
      );
    },
    toggleBold(editor) {
      const isActive = CustomEditor.checkActive.isBoldMarkActive(editor);
      Transforms.setNodes(
        editor,
        { bold: isActive ? null : true },
        { match: (n) => Text.isText(n), split: true }
      );
    },

    toggleCodeBlock(editor) {
      const isActive = CustomEditor.checkActive.isCodeBlockActive(editor);
      Transforms.setNodes(
        editor,
        { type: isActive ? null : "code" },
        { match: (n) => Editor.isBlock(editor, n) }
      );
    },
  };
  useEffect(() => {
    // setelement
  }, []);
  // console.log(editor.children);
  return (
    <div className="rte">
      <Slate editor={editor} value={msg ? msg : initialValue}>
        {!read_only && (
          <Toolbar
            className="toolbar"
            tools={[
              <MarkButton setelement={setelement} format="bold" icon="bold" />,
              <MarkButton
                setelement={setelement}
                format="italic"
                icon="italic"
              />,
              <MarkButton
                setelement={setelement}
                format="underline"
                icon="underline"
              />,
              <MarkButton setelement={setelement} format="code" icon="code" />,
              <BlockButton
                setelement={setelement}
                format="block-quote"
                icon="quote"
              />,
              <BlockButton
                setelement={setelement}
                format="strike-through"
                icon="strike"
              />,
              <BlockButton
                setelement={setelement}
                format="numbered-list"
                icon="list_numbered"
              />,
              <BlockButton
                setelement={setelement}
                format="bulleted-list"
                icon="list_bulleted"
              />,
              <BlockButton
                setelement={setelement}
                format="heading-1"
                icon="h1"
              />,
              <BlockButton
                setelement={setelement}
                format="heading-2"
                icon="h2"
              />,
              <BlockButton
                setelement={setelement}
                format="heading-3"
                icon="h3"
              />,
              <BlockButton setelement={setelement} format="left" icon="left" />,
              <BlockButton
                setelement={setelement}
                format="right"
                icon="right"
              />,
              <BlockButton
                setelement={setelement}
                format="center"
                icon="center"
              />,
              <BlockButton
                setelement={setelement}
                format="justify"
                icon="justify"
              />,
              // <MarkButton format="link" icon="link" />,
              // {
              //   title: "Heading",
              //   body: [
              // <BlockButton format="heading-1" icon="h1" />,
              // <BlockButton format="heading-2" icon="h2" />,
              // <BlockButton format="heading-3" icon="h3" />,
              // <BlockButton format="heading-4" icon="h4" />,
              // <BlockButton format="heading-5" icon="h5" />,
              // <BlockButton format="heading-6" icon="h6" />,
              //   ],
              // },
              // {
              //   title: "Align",
              //   body: [
              //     <BlockButton format="left" icon="left" />,
              //     <BlockButton format="right" icon="right" />,
              //     <BlockButton format="center" icon="center" />,
              //     <BlockButton format="justify" icon="justify" />,
              //   ],
              // },
            ]}
          >
            {/* <BlockButton format="heading-one" icon="looks_one" />
          <BlockButton format="heading-two" icon="looks_two" />
          <BlockButton format="block-quote" icon="format_quote" />
          <BlockButton format="numbered-list" icon="format_list_numbered" />
          <BlockButton format="bulleted-list" icon="format_list_bulleted" />
          <BlockButton format="left" icon="format_align_left" />
          <BlockButton format="center" icon="format_align_center" />
          <BlockButton format="right" icon="format_align_right" />
        <BlockButton format="justify" icon="format_align_justify" /> */}
          </Toolbar>
        )}
        {read_only ? (
          <Editable
            className="rte_editor"
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={cust_placeholder || "Enter some rich text…"}
            readOnly
          />
        ) : (
          <Editable
            className="rte_editor"
            renderElement={renderElement}
            renderLeaf={renderLeaf}
            placeholder={cust_placeholder || "Enter some rich text…"}
            onKeyUp={() => {
              // console.log(editor);
              setelement([...editor.children]);
            }}
          />
        )}
        {/* // spellCheck
          // autoFocus
          // onKeyDown={(event) => {
          //   for (const hotkey in HOTKEYS) {
          //     if (isHotkey(hotkey, event)) {
          //       event.preventDefault();
          //       const mark = HOTKEYS[hotkey];
          //       toggleMark(editor, mark);
          //     }
          //   }
          // }} */}
      </Slate>
    </div>
  );
};

const DefaultElement = (props) => {
  return <div {...props.attributes}>{props.children}</div>;
};
// Define a React component renderer for our code blocks.
const CodeElement = (props) => {
  return (
    <div {...props.attributes}>
      <code>{props.children}</code>
    </div>
  );
};
const TestElementRed = (props) => {
  return (
    <div style={{ background: "red" }} {...props.attributes}>
      <div>{props.children}</div>
    </div>
  );
};
const TestElementBlue = (props) => {
  return (
    <div style={{ background: "blue" }} {...props.attributes}>
      <div>{props.children}</div>
    </div>
  );
};

// const Leaf = (props) => {
//   return (
//     <span
//       {...props.attributes}
//       style={{ fontWeight: props.leaf.bold ? "bold" : "normal" }}
//     >
//       {props.children}
//     </span>
//   );
// };
const Leaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};
const Toolbar = ({ className, tools }) => {
  return (
    <div class="toolbar">
      {tools.map((el) => {
        // console.log(typeof el);
        if (el.title) {
          return <ToolbarDropdown data={el} />;
        }
        return el;
      })}
    </div>
  );
};
const ToolbarDropdown = ({ data }) => {
  const [height, setheight] = useState({
    min_height: 0,
    max_height: 0,
  });
  const [toggel_height, settoggel_height] = useState(false);
  // return;
  const max_height = useRef();
  const body_height = useRef();
  useEffect(() => {
    // console.log(
    //   max_height.current.parentNode.getBoundingClientRect().height,
    //   body_height.current.getBoundingClientRect().height
    // );
    setheight({
      min_height: max_height.current.parentNode.getBoundingClientRect().height,
      max_height: body_height.current.getBoundingClientRect().height,
      // body_height.current.getBoundingClientRect().height,
    });
  }, []);
  // console.log(title_height.current.getBoundingClientRect().height);
  return (
    <div
      className="tool_dropdown"
      style={{
        height: `${height.min_height}px`,
      }}
      onClick={() => settoggel_height(!toggel_height)}
      onMouseLeave={() => settoggel_height(false)}
      ref={max_height}
    >
      <div>
        <div className="tool_dropdown_title">{data.title}</div>
        <div
          className="tool_dropdown_main"
          style={{ height: `${!toggel_height ? 0 : height.max_height}px` }}
        >
          <div ref={body_height}>{data.body.map((el1) => el1)}</div>
        </div>
      </div>
      <div className="arrow_down"></div>
    </div>
  );
};
const MarkButton = ({ format, icon, setelement }) => {
  const editor = useSlate();
  return (
    <div
      // active={isMarkActive(editor, format)}
      className="tool"
      onClick={(event) => {
        console.log(format);
        console.log("mark btn");
        if (format !== "link") {
          event.preventDefault();
          toggleMark(editor, format);
        }
        if (format === "link") {
          const url = prompt("Enter a URL"); // For simplicity
          insertLink(editor, url); // will be implemented later
        }
        setelement([...editor.children]);
      }}
    >
      {/* <div> */}
      <RTE_Icons icon={icon} active={isMarkActive(editor, format)} />
      {/* </div> */}
    </div>
  );
};
const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};
const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

const BlockButton = ({ format, icon, setelement }) => {
  const editor = useSlate();
  return (
    <div
      className="tool"
      // active={isBlockActive(
      //   editor,
      //   format,
      //   TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
      // )}
      onClick={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
        setelement([...editor.children]);
      }}
    >
      {/* <div> */}
      <RTE_Icons
        icon={icon}
        active={isBlockActive(
          editor,
          format,
          TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
        )}
      />
      {/* test */}
      {/* {format} */}
      {/* {icon} */}
      {/* </div> */}
    </div>
  );
};
const isBlockActive = (editor, format, blockType = "type") => {
  const { selection } = editor;
  if (!selection) return false;

  const [match] = Array.from(
    Editor.nodes(editor, {
      at: Editor.unhangRange(editor, selection),
      match: (n) =>
        !Editor.isEditor(n) &&
        SlateElement.isElement(n) &&
        n[blockType] === format,
    })
  );

  return !!match;
};
const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(
    editor,
    format,
    TEXT_ALIGN_TYPES.includes(format) ? "align" : "type"
  );
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) &&
      SlateElement.isElement(n) &&
      LIST_TYPES.includes(n.type) &&
      !TEXT_ALIGN_TYPES.includes(format),
    split: true,
  });
  let newProperties = {};
  if (TEXT_ALIGN_TYPES.includes(format)) {
    newProperties = {
      align: isActive ? undefined : format,
    };
  } else {
    newProperties = {
      type: isActive ? "paragraph" : isList ? "list-item" : format,
    };
  }
  Transforms.setNodes(editor, newProperties);

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};
export const RenderElement = ({ attributes, children, element }) => {
  const style = { textAlign: element.align };

  switch (element.type) {
    case "strike-through":
      return (
        <s style={style} {...attributes}>
          {children}
        </s>
      );
    case "block-quote":
      return (
        <blockquote style={style} {...attributes}>
          {children}
        </blockquote>
      );
    case "bulleted-list":
      return (
        <ul style={style} {...attributes}>
          {children}
        </ul>
      );
    case "heading-1":
      return (
        <h1 style={style} {...attributes}>
          {children}
        </h1>
      );
    case "heading-2":
      return (
        <h2 style={style} {...attributes}>
          {children}
        </h2>
      );
    case "heading-3":
      return (
        <h3 style={style} {...attributes}>
          {children}
        </h3>
      );
    case "heading-4":
      return (
        <h4 style={style} {...attributes}>
          {children}
        </h4>
      );
    case "heading-5":
      return (
        <h5 style={style} {...attributes}>
          {children}
        </h5>
      );
    case "heading-6":
      return (
        <h6 style={style} {...attributes}>
          {children}
        </h6>
      );
    case "list-item":
      return (
        <li style={style} {...attributes}>
          {children}
        </li>
      );
    case "numbered-list":
      return (
        <ol style={style} {...attributes}>
          {children}
        </ol>
      );
    case "link":
      return <Link {...{ element, attributes, children }} />;
    default:
      return (
        <div style={style} {...attributes}>
          {children}
        </div>
      );
  }
};
const createLinkNode = (href, text) => ({
  type: "link",
  href,
  children: [{ text }],
});

const removeLink = (editor, opts = {}) => {
  Transforms.unwrapNodes(editor, {
    ...opts,
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === "link",
  });
};

const insertLink = (editor, url) => {
  console.log(url);
  if (!url) return;

  const { selection } = editor;
  const link = createLinkNode(url, "New Link");

  ReactEditor.focus(editor);

  if (!!selection) {
    const [parentNode, parentPath] = Editor.parent(
      editor,
      selection.focus?.path
    );

    // Remove the Link node if we're inserting a new link node inside of another
    // link.
    if (parentNode.type === "link") {
      removeLink(editor);
    }

    if (editor.isVoid(parentNode)) {
      // Insert the new link after the void node
      Transforms.insertNodes(editor, createParagraphNode([link]), {
        at: Path.next(parentPath),
        select: true,
      });
    } else if (selection.isCollapsed) {
      // Insert the new link in our last known location
      Transforms.insertNodes(editor, link, { select: true });
    } else {
      // Wrap the currently selected range of text into a Link
      Transforms.wrapNodes(editor, link, { split: true });
      // Remove the highlight and move the cursor to the end of the highlight
      Transforms.collapse(editor, { edge: "end" });
    }
  } else {
    // Insert the new link node at the bottom of the Editor when selection
    // is falsey
    Transforms.insertNodes(editor, createParagraphNode([link]));
  }
};
const Link = ({ attributes, element, children }) => {
  const editor = useSlateStatic();
  const selected = useSelected();
  const focused = useFocused();
  // console.log(element, attributes, children);
  return (
    <div className="element-link">
      <a {...attributes} href={element?.href}>
        {children}
      </a>
      {/* {selected && focused && (
        <div className="popup" contentEditable={false}>
          <a href={element?.href} rel="noreferrer" target="_blank">
            <FontAwesomeIcon icon={faExternalLinkAlt} />
            {element?.href || "err href"}
          </a>
          <button onClick={() => removeLink(editor)}>
            <FontAwesomeIcon icon={faUnlink} />
          </button>
        </div>
      )} */}
    </div>
  );
};
export const createParagraphNode = (children = [{ text: "" }]) => ({
  type: "paragraph",
  children,
});
