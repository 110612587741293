import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { encrypt_data } from "../../../RSA_Encryption/RSA";
import { edit_name, verify_password } from "../../API/API";

export const EditName = ({
  profile,
  setprofile_modal,
  cancel,
  setprofile,
  // show_this_page,
  setshow_this_page
}) => {
  const navigate = useNavigate();
  const current_store = useSelector((state) => state);
  const [email_modal_showing, setemail_modal_showing] =
    useState("enterpassword");
  const [password, setpassword] = useState("");
  const [invalid_pass, setinvalid_pass] = useState("");

  const [name, setname] = useState("");
  const [name_err, setname_err] = useState("");

  const [temp_token, settemp_token] = useState("");

  return (
    <div className="profile_modal_common_card">
      {email_modal_showing == "enterpassword" ? (
        <>
          <div className="card_heading">
            New
            <br />
            Name
          </div>
          <div className="card_title">ENTER PASSWORD</div>
          <div className="card_input">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => { setpassword(e.target.value); setinvalid_pass('') }}
              inp_error={invalid_pass.toString()}
            />
          </div>
          <div className="margin_bottom_auto"></div>
          <div className="btn_group btn_group_vertical">
            <div btn_type="type1" onClick={cancel}>
              Cancel
            </div>
            <div
              btn_type={password != "" ? "type3" : "type2"}
              onClick={() => {

                if (password.length == 0) {
                  return
                }
                verify_password(encrypt_data(password), current_store.user.token, (data) => {
                  if (data.msg.verified) {
                    settemp_token(data.msg.token)
                    setemail_modal_showing("setanewemail1")
                    return
                  }
                  setinvalid_pass(true)

                  // console.log(data);
                })


              }
              }
            >
              Continue
            </div>
          </div>
        </>
      ) : email_modal_showing == "setanewemail1" ? (
        <>
          <div className="card_heading">
            New
            <br />
            Name
          </div>
          <div className="card_title">SET A NEW NAME</div>
          <div className="card_input">
            <input
              type="text"
              placeholder="New Name"
              value={name}
              inp_error={(name_err).toString()}
              onChange={(e) => {
                setname(e.target.value);
                setname_err((e.target.value.trim() == '').toString())
              }}
            />
          </div>
          <div className="margin_bottom_auto"></div>
          <div className="btn_group btn_group_vertical">
            <div btn_type="type1" onClick={cancel}>
              Cancel
            </div>
            <div
              btn_type={name.trim() != "" ? "type3" : "type2"}
              onClick={() => {
                if (name.trim() == "") { return; }
                setshow_this_page(false)

                edit_name(
                  current_store.user.token,
                  temp_token,
                  name,
                  (data) => {
                    // console.log(data);
                    if (!data.status) {
                      navigate('/404')
                      return
                    }
                    setprofile({
                      ...profile,
                      name: data.msg
                    })
                    setshow_this_page(true)
                    cancel();

                  }
                )
              }}
            >
              Set new name
            </div>
          </div>
        </>
      ) : (
        ""
      )
      }
    </div >
  );
};
