import React, { useEffect, useState } from "react";
// import HIW from '../img/HIW.svg';
import { useNavigate } from "react-router-dom";

import { Login } from "./LoginPage/Login";
import { LetsGetStarted } from "./LoginPage/CreateAccount/LetsGetStarted";

export const LoginPage = ({ setmenu, setjuliette_bg }) => {
  const navigate = useNavigate();
  useEffect(() => {
    setmenu(false);
    setjuliette_bg(true);
    let token_in_localstorage = localStorage.getItem('juliette_user_token')
    if (token_in_localstorage) {
      return navigate('/request-pickup')
    }
    setshow_this_page(true)
  }, []);

  const [show_card, setshow_card] = useState("login");
  const [show_dots, setshow_dots] = useState("login");
  const [show_this_page, setshow_this_page] = useState(false)



  // useEffect(() => {
  //     setshow_dots(show_card)
  // }, [show_card])

  return (
    <div className="loginpage">
      {
        show_this_page &&
        <>
          {/* <img src={HIW}/> */}
          {show_card === "login" ? (
            <Login setshow_card={setshow_card} setshow_dots={setshow_dots} />
          ) : show_card === "letsgetstarted" ? (
            <LetsGetStarted
              setshow_card={setshow_card}
              setshow_dots={setshow_dots}
            />
          ) : (
            ""
          )}
        </>
      }
    </div>
  );
};
