import { initializeApp } from "firebase/app";
import {
  FacebookAuthProvider,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  OAuthProvider,
  signInWithPopup,
  signInWithRedirect,
} from "firebase/auth";
import { getMessaging, getToken, onMessage } from "firebase/messaging";

// jc-app
const firebaseConfig = {
  apiKey: "AIzaSyAlSVwxlbxHXbVfPmCHQNp-jrtDkRalUqw",
  authDomain: "juliettecleaners.firebaseapp.com",
  projectId: "juliettecleaners",
  storageBucket: "juliettecleaners.appspot.com",
  messagingSenderId: "83154822362",
  appId: "1:83154822362:web:8b1a0ea2b2d18c0570b1e3",
  measurementId: "G-DLZRQPPR6P",
};

export const firebase_app = initializeApp(firebaseConfig);

const auth = getAuth();
const facebook_provider = new FacebookAuthProvider();
const linkedin_provider = new OAuthProvider("microsoft.com");
const google_provider = new GoogleAuthProvider();
const apple_provider = new OAuthProvider("apple.com");
const messaging = getMessaging(firebase_app);

// google signin popup
export const SigninWithFirebase = (login_id, setemail, setothers, setname) => {
  // if (login_id != 2) return;
  // let data = {
  //   token: "",
  //   others: {},
  // };
  // console.log(['facebook_provider', 'linkedin_provider', 'google_provider', 'apple_provider'][login_id]);
  signInWithPopup(
    // signInWithRedirect(
    auth,
    [facebook_provider, linkedin_provider, google_provider, apple_provider][login_id]
  )
    .then(async (res) => {
      // This gives you a Google Access Token. You can use it to access the Google API.
      // console.log("result", res);
      let credential;
      // [0:facebook,1:]
      switch (login_id) {
        case 0:
          credential = FacebookAuthProvider.credentialFromResult(res);
          break;
        case 1:
          facebook_provider.addScope("public_profile");
          credential = OAuthProvider.credentialFromResult(res);
          // provider.addScope("displayName");
          // console.log("credential.public_profile", credential.public_profile);
          break;
        case 2:
          credential = GoogleAuthProvider.credentialFromResult(res);
          break;
        case 3:
          apple_provider.addScope('email');
          apple_provider.addScope('name');
          credential = OAuthProvider.credentialFromResult(res);
          break;
        default:
          return;
          break;
      }
      // console.log("result,crediatial", res, credential);
      const token = credential.accessToken;
      // The signed-in user info.
      const user = res.user;
      setemail(user.email);
      setname && setname(user.displayName);
      // firebase token to send to back end
      // console.log(auth.currentUser.getIdToken(true));
      // // auth.currentUser.getIdToken(true).then(data => console.log('auth_token', data));
      return {
        // token: credential.accessToken,
        token: await auth.currentUser.getIdToken(true),
        others: res,
      };
    })
    .then((data) => {
      setothers(false, data);
    })
    .catch((error) => {
      // console.log(error);
      // data.error = true;
      setothers(true, error);
      //   const errorCode = error.code;
      //   const errorMessage = error.message;
      //   // The email of the user's account used.
      //   const email = error.customData.email;
      //   // The AuthCredential type that was used.
      //   const credential = GoogleAuthProvider.credentialFromError(error);
    });
  // return data;
  return;
};

// check current user thtas signed in
export const check_user_signin = () => {
  const user = auth.currentUser;
  // console.log('user', user);
  return user;
};

export const getToken1 = (setTokenFound) => {
  return getToken(messaging, {
    vapidKey:
      "BG0V9Ys-nIeoFZodrJZuzLh91m00VPNqumsdrpEH_K3uvIdQtgW1dSe29itKRx3okyj338u--OqKfuK2MSjUJeU",
  })
    .then((currentToken) => {
      if (currentToken) {
        // console.log("current token for client: ", currentToken);
        setTokenFound(true);
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
      } else {
        // console.log(
        //   "No registration token available. Request permission to generate one."
        // );
        setTokenFound(false);
        // shows on the UI that permission is required
      }
    })
    .catch((err) => {
      // console.log("An error occurred while retrieving token. ", err);
      // catch error while creating client token
    });
};
