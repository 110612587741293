import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { cancel_order, get_order_by_id } from "../API/API";
import { CancelPickupModal } from "../CancelPickupModal";
import { useNavigate } from "react-router-dom";

export const GotIt = ({ order_id, setshow_requestpickup, resetpickupdata, token }) => {
  // console.log(order_id);
  // const [cancel_pickup_modal, setcancel_pickup_modal] = useState(false);
  const [cancelordermodalloader, setcancelordermodalloader] = useState(false);
  const [cancelpickupmodal, setcancelpickupmodal] = useState(false);
  const [cancelpickupmodal_txt, setcancelpickupmodal_txt] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const current_store = useSelector(state => state)
  console.log('test',cancelpickupmodal);

  return (
    <div className="requestpickup_body finalpage">
      <div className="requestpickup_nav_tabs">
        <div className="txt f40">Got it!</div>
        <div className="f16">
          Cancelling after 5 minutes will result in a $5 cancellation fee.
          You'll get an auto-text once our driver is on the way.
        </div>
        {/* <div className='requestpickup_tabs f16' > */}
        {/* </div> */}
      </div>
      <div className="btns_group gotit_btn_group">
        <div
          mouse="pointer"
          className="requestpickup_cancelpickup"
          active="true"
          onClick={() => {
            if(cancelordermodalloader){
              return
            }
            setcancelordermodalloader(true)
            get_order_by_id(
              order_id,
              current_store.user.token,
              (data)=>{
                if(!data.status){
                  return navigate('/404')
                }
                setcancelordermodalloader(false)
                console.log('order_by_id',data.msg.display_status);
                if(data.msg.cancel_display){
                  setcancelpickupmodal_txt(data.msg.cancel_display)
                }
                setcancelpickupmodal(true)
                
            })
          }}
        >
          {cancelordermodalloader ? 'Fetching Data' : 'Cancel pickup'}
        </div>
        <div
          mouse="pointer"
          className="requestpickup_confirm"
          active="true"
          onClick={() => {
            dispatch({ type: "resetpickup" });
            setshow_requestpickup("RequestPickupType");
            resetpickupdata();
          }}
        >
          Request another pickup
        </div>
      </div>
      {cancelpickupmodal ? (
        <CancelPickupModal
          txt={cancelpickupmodal_txt}
          no={() => setcancelpickupmodal(false)}
          cancelnow={() => {
            cancel_order(order_id, token, (data) => {
              if (data.error) {
                // do something
                return
              }
              // setcancel_pickup_modal(false)
              setshow_requestpickup("RequestPickupType");
              resetpickupdata();
              // console.log(data);
            })
          }}
        />
      ) : (
        <></>
      )}
    </div>
  );
};
