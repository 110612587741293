import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { send_email_otp } from "../../API/API";

export const EnterEmailToGetPassword = ({
  setshow_forgetpassword_card,
  cancel,
  email,
  setemail,
}) => {
  const navigate = useNavigate();
  const [email_error,setemail_error] = useState('')
  return (
    <>
      <div className="f14">ENTER EMAIL TO GET CODE</div>
      <div className="forgotpassword_email mb_auto">
        <input
          className="f14"
          placeholder="Email Adress"
          onChange={(e) => {setemail(e.target.value);setemail_error('')}}
          value={email}
        ></input>
        {
          email_error && <div className="error" style={{marginTop:'5px'}} error='true'>{email_error}</div>
        }
      </div>
      <div className={"forgotpassword_cancel"} mouse="pointer" onClick={cancel}>
        Cancel
      </div>
      <div
        className={
          "forgotpassword_submit " +
          (email.match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          ) != null
            ? ""
            : "disable")
        }
        mouse="pointer"
        onClick={() =>{
          if(email.match(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/) != null){
            send_email_otp(
              {
                email: email,
                edit: false,
                name: ''
              },
              '',
              (data) => {
                // console.log('email_otp', data);
                // console.log(data);
                if (!data.status) {
                  if(data.msg === 'User does not exist'){
                    // console.log('error',data.status);
                    setemail_error(data.msg)
                    return
                  }
                  navigate('/404')
                }
                // setverificationcode(data.msg)
                // setemail_modal_showing("setanewemail2");
                setshow_forgetpassword_card("enterverificationcode")
              }
            )
            return
          }
          else{
            return
          }
          }
        }
      >
        Send verification code
      </div>
    </>
  );
};
