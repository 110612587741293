import React from "react";

export const CreateAccountDots = ({ show_dots }) => {
  return (
    <div className={"dots_box show"}>
      <div className={"dot " + (show_dots == 1 ? 'active' : '')}></div>
      <div className={"dot " + (show_dots == 2 ? 'active' : '')}></div>
      <div className={"dot " + (show_dots == 3 ? 'active' : '')}></div>
      <div className={"dot " + (show_dots == 4 ? 'active' : '')}></div>
    </div>
  );
};
