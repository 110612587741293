import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const NotFound = ({ setjuliette_bg, setmenu }) => {
  const navigate = useNavigate();
  useEffect(() => {
    setjuliette_bg(false);
    setmenu(false);
  }, []);

  return (
    <div className="notfound">
      <div className="txt1">404</div>
      <div className="txt2">it seems an error accured</div>
      <div
        className="homepage"
        mouse="pointer"
        onClick={() => navigate("/login")}
      >
        Homepage
      </div>
    </div>
  );
};
