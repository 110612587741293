import { SettingsPhone } from "@mui/icons-material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import { useDispatch, useSelector } from "react-redux";
import { encrypt_data } from "../../../RSA_Encryption/RSA";
import { edit_phone, resend_sms_otp, send_sms_otp, verify_password, verify_sms_otp } from "../../API/API";

export const EditMobile = ({ profile, setprofile, cancel, setshow_this_page }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const current_store = useSelector((state) => state);
  const [mobile_modal_showing, setmobile_modal_showing] =
    useState("enterpassword");
  const [password, setpassword] = useState("");
  const [invalid_pass, setinvalid_pass] = useState("");


  const [mobile, setmobile] = useState("");
  const [mobile_err, setmobile_err] = useState("");
  const [confirmmobile, setconfirmmobile] = useState("");
  const [confirmmobile_err, setconfirmmobile_err] = useState("");
  const [verification, setverification] = useState("");
  const [phone_verification_code_wrong, setphone_verification_code_wrong] = useState(false);

  const [temp_token, settemp_token] = useState("");

  // const[temp_token,settemp_]
  const OnChangePhone = (e) => {
    // console.log([9, 13].some((el) => el == e.length));
    if (
      Number(e.slice(-1)).toString() == "NaN" ||
      e.length > 17 ||
      (e.slice(-1) == " " && ![5, 9, 13].some((el) => el == e.length))
    ) {
      return;
    }
    setmobile_err("true");
    if (e.length == 17) {
      setmobile_err("false");
    }
    switch (e.length) {
      case 1:
        return setmobile(`(+1) ${e[0]}`);
      case 5:
        return setmobile("");
      case 9:
        if (e.slice(-1) == " ") {
          return setmobile(e.slice(0, 8));
        }
        return setmobile(e.slice(0, 8) + " " + e.slice(-1));
      case 13:
        if (e.slice(-1) == " ") {
          return setmobile(e.slice(0, 12));
        }
        return setmobile(e.slice(0, 12) + " " + e.slice(-1));

      default:
        return setmobile(e);
    }
  };
  const OnChangeConfirmPhone = (e) => {
    // console.log([9, 13].some((el) => el == e.length));
    if (
      Number(e.slice(-1)).toString() == "NaN" ||
      e.length > 17 ||
      (e.slice(-1) == " " && ![5, 9, 13].some((el) => el == e.length))
    ) {
      return;
    }
    setconfirmmobile_err("true");
    if (e.length == 17) {
      setconfirmmobile_err("false");
    }
    switch (e.length) {
      case 1:
        return setconfirmmobile(`(+1) ${e[0]}`);
      case 5:
        return setconfirmmobile("");
      case 9:
        if (e.slice(-1) == " ") {
          return setconfirmmobile(e.slice(0, 8));
        }
        return setconfirmmobile(e.slice(0, 8) + " " + e.slice(-1));
      case 13:
        if (e.slice(-1) == " ") {
          return setconfirmmobile(e.slice(0, 12));
        }
        return setconfirmmobile(e.slice(0, 12) + " " + e.slice(-1));

      default:
        return setconfirmmobile(e);
    }
  };
  return (
    <div className="profile_modal_common_card">
      {mobile_modal_showing == "enterpassword" ? (
        <>
          <div className="card_heading">
            New
            <br />
            Mobile
          </div>
          <div className="card_title">ENTER PASSWORD</div>
          <div className="card_input">
            <input
              type="password"
              placeholder="Password"
              value={password}
              onChange={(e) => setpassword(e.target.value)}
              inp_error={invalid_pass.toString()}
            />
          </div>
          <div className="margin_bottom_auto"></div>
          <div className="btn_group btn_group_vertical">
            <div btn_type="type1" onClick={cancel}>
              Cancel
            </div>
            <div
              btn_type={password != "" ? "type3" : "type2"}
              onClick={() => {

                if (password == '') {
                  return
                }
                verify_password(encrypt_data(password), current_store.user.token, (data) => {
                  if (data.msg.verified) {
                    settemp_token(data.msg.token)
                    setmobile_modal_showing("setanewmobile1")
                    return
                  }
                  setinvalid_pass(true)
                })
              }
              }
            >
              Continue
            </div>
          </div>
        </>
      ) : mobile_modal_showing == "setanewmobile1" ? (
        <>
          <div className="card_heading">
            New
            <br />
            Mobile
          </div>
          <div className="card_title">NEW MOBILE</div>
          <div className="card_input">
            <input
              type="text"
              placeholder="New Mobile"
              value={mobile}
              inp_error={mobile_err}
              onChange={(e) => OnChangePhone(e.target.value)}
            />
          </div>
          <div className="card_input">
            <input
              type="text"
              placeholder="Confirm New Mobile "
              value={confirmmobile}
              inp_error={confirmmobile_err}
              onChange={(e) => OnChangeConfirmPhone(e.target.value)}
            />
          </div>
          <div className="margin_bottom_auto"></div>
          <div className="card_txt14">
            A verification sms will be sent
            <br /> to this address.
          </div>
          <div className="btn_group btn_group_vertical">
            <div btn_type="type1" onClick={cancel}>
              Cancel
            </div>
            <div
              btn_type={
                confirmmobile_err == "false" && mobile_err == "false" && confirmmobile == mobile
                  ? "type3"
                  : "type2"
              }
              onClick={() => {
                if (!(confirmmobile_err == "false" && mobile_err == "false" && confirmmobile == mobile)) {
                  return
                }
                send_sms_otp(
                  mobile,
                  (data) => {

                    if (!data.status) {
                      return navigate('/404')
                    }

                    setmobile_modal_showing("setanewmobile2")

                  }
                )
              }
              }
            >
              Send verification code
            </div>
          </div>
        </>
      ) : mobile_modal_showing == "setanewmobile2" ? (
        <>
          <div className="card_heading">
            New
            <br />
            Mobile
          </div>
          <div className="card_title">NEW MOBILE</div>
          <div className="card_input">
            <input
              type="text"
              placeholder="New Mobile"
              value={mobile}
              readOnly
            />
          </div>
          <div className="card_input">
            <input
              type="text"
              placeholder="Confirm New Mobile"
              value={confirmmobile}
              readOnly
            />
          </div>
          <div className="card_title mt-34">ENTER VERIFICATION CODE</div>
          <div className="card_input">
            <input
              type="text"
              placeholder="Verification Code "
              value={verification}
              onChange={(e) => setverification(e.target.value)}
            />
          </div>
          {
            phone_verification_code_wrong &&
            <div className='error' error={phone_verification_code_wrong.toString()}>
              Verification Code wrong.
            </div>
          }
          <div className="margin_bottom_auto"></div>
          <div className="btn_group btn_group_vertical">
            <div btn_type="type1"
            onClick={()=>{
              console.log('resen code')
              resend_sms_otp(mobile, (data) => {
                // console.log(data);
                // if (data.status) {
                //     // setphone_validation_code('');
                //     setphone_verification_code_wrong(false);
                // }
            })
            }}
            >Resend code</div>
            <div btn_type="type1" onClick={cancel}>
              Cancel
            </div>
            <div
              btn_type={verification != "" ? "type3" : "type2"}
              onClick={() => {
                if (verification == '') {
                  return;
                }
                setshow_this_page(false)
                verify_sms_otp( 
                  {
                    phone_no: mobile,
                    otp: verification
                  },
                  (data) => {
                    // console.log('mobile_verify', data);
                    if (!data.status) {
                      setphone_verification_code_wrong(true)
                      setshow_this_page(true)
                      return
                      // navigate('/404')
                    }

                    edit_phone(
                      data.msg,
                      mobile,
                      current_store.user.token,
                      (data) => {
                        if (!data.status) {
                          setshow_this_page(true)
                          return
                          // navigate('/404')
                        }
                        setprofile({
                          ...profile,
                          phone: mobile,
                        });
                        setshow_this_page(true)
                        cancel();


                      }
                    )

                    return
                    // setphone_validation_err(true)

                  }
                )

              }}
            >
              Set new mobile
            </div>
          </div>
        </>
      ) : (
        ""
      )}
    </div>
  );
};
