import React from "react";

import facebook_login from "../../img/loginThrough/facebook.svg";
import linkedin_login from "../../img/loginThrough/linkedin.svg";
import microsoft_login from "../../img/loginThrough/microsoft.svg";
import google_login from "../../img/loginThrough/google.svg";
import apple_login from "../../img/loginThrough/apple.svg";

import { firebase_app, SigninWithFirebase } from "../../Firebase/firebase";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { sign_in } from "../API/API";
// import { firebase } from "../../Firebase/firebase";

export const OR_LoginThrough = ({ setlogin_err, setemail, setemail_disabled, mini, settoken, setname }) => {
  const current_store = useSelector((store) => store);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const signinwith = (i) => {
    SigninWithFirebase(i, setemail, setothers, setname,);
  };
  const setothers = (error, result) => {
    // console.log(result);
    if (error) {
      return console.log(result);
    }
    sign_in(
      {
        idToken: result.token
      }
      , (data) => {
        if (!data.status) {
          return setlogin_err(true)
        }
        dispatch({ type: "login", payload: { token: data.token } });
        navigate("/request-pickup");
        // setemail_disabled(true);

        // settoken(result.token);
      });
    // dispatch({
    //   type: "others",
    //   payload: { others: result.others, token: result.token },
    // });
  };
  // console.log(store_data);
  // const SigninWithFirebase = (login_id) => {
  //   //   console.log(firebase_app);
  //   //   if (login_id != 2) return;
  //   //   console.log(login_id);
  //   //   var google_provider = new firebase_app.auth.GoogleAuthProvider();
  //   //   firebase_app
  //   //     .auth()
  //   //     .signInWithPopup(google_provider)
  //   //     .then((res) => console.log(res))
  //   //     .catch((err) => console.log(err));
  // };
  return (
    <>
      <div className={"options_or f14" + (mini ? ' options_mini' : '')}>or</div>
      <div className={"login_through" + (mini ? ' options_mini' : '')}>
        {/* {[facebook_login, microsoft_login, google_login, apple_login].map( */}
        {[facebook_login, microsoft_login, google_login].map(
          (el, i) => (
            <img src={el} onClick={() => signinwith(i)} />
          )
        )}
      </div>
    </>
  );
};
