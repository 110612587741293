import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import hiw_bg from "../img/how_it_works_bg.svg";
// import hiw_bg from "../img/jpegbg/how_it_works_bg.jpg";
// import hiw_bg from "https://julietteimages.s3.amazonaws.com/systemimages/welcome-min.jpg";
import "../img/notfound_bg.png";
import { check_img_loaded } from "./CommonComponents/loader_data";
import { Loading } from "./CommonComponents/Loading";

export const HowItWorks = ({ setmenu, setjuliette_bg, setloader }) => {
  const navigate = useNavigate();
  const [show_this_page, setshow_this_page] = useState(false)
  // const [loader, setloader] = useState(true);

  useEffect(() => {
    let to_redirect = localStorage.getItem("juliette_home")
    let token_in_localstorage = localStorage.getItem('juliette_user_token')
    if (to_redirect && to_redirect == 'true') {
      if (token_in_localstorage) {
        return navigate("/request-pickup")
      }
      return navigate("/login")
    }
    setjuliette_bg(false);
    setmenu(false);
    // check_img_loaded(hiw_bg, setloader);
    setshow_this_page(true)
    // const loadImage = (image) => {
    //   return new Promise((resolve, reject) => {
    //     const loadImg = new Image();
    //     loadImg.src = image.url;
    //     // wait 2 seconds to simulate loading time
    //     loadImg.onload = () => resolve(image.url);

    //     loadImg.onerror = (err) => reject(err);
    //   });
    // };
  }, []);
  const hiw_bg = 'https://julietteimages.s3.amazonaws.com/systemimages/welcome-min.jpg'
  return (
    <>
      {
        show_this_page &&
        <div
          className="howitworks"
          style={{
            backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${hiw_bg})`,
            // backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('https://github.com/Punit-esh/JC/blob/mani/src/img/how_it_works_bg.svg')`,
          }}
        >
          <div className="page_title">Welcome</div>
          <div className="howitworks_body">
            <div className="f40">
              Welcome
              {/* How it
          <br />
        works */}
            </div>
            <div className="f16" style={{ maxWidth: "390px" }}>
              Juliette sits at the intersection of fashion, lifestyle, and
              technology. We understand that your clothes are an extension of who
              you are. But more than that, we're New Yorkers. And as New Yorkers
              we value time and convenience above all else.
              <br />
              <br />
              Our service is simple and straightforward. We'll pickup, clean, and
              deliver your clothes at the tap of a button.
              {/* <br />
          Request a pickup by the tap of a button and leave your clothes with
          your doorman. We pickup every night between 7-10pm.
          <br />
          <br />
          Your clothes are then taken to our flagship store on the Upper West
          Side where they are carefully cared for and beautifully packaged.
          <br />
          <br />
        Your clothes are returned to your doorman in 24-48 hours. */}
            </div>
            <div
              mouse="pointer"
              className="continue"
              onClick={() => navigate("/login")}
            >
              Enter
            </div>
            <div className="donotshowagain f12">
              <div className="tick_checkbox">
                <input mouse="pointer" type="checkbox" id="dontshowagain" onChange={(e) => {
                  localStorage.setItem("juliette_home", e.target.checked == true)
                }} />
                <label htmlFor="dontshowagain">
                  <div className="show_cust_checkbox"></div>
                </label>
              </div>
              <label mouse="pointer" htmlFor="dontshowagain">
                Don't show again
              </label>
            </div>
          </div>
        </div>
      }
    </>
  );
};
