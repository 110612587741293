import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { CancelPickupModal } from "../CancelPickupModal";
import { Loading } from "../CommonComponents/Loading";
import { AddPhotoAndNoteModal } from "./AddPhotoAndNoteModal";

export const AddPhotoAndNote = ({
  pickup_note,
  setpickup_note,
  setshow_requestpickup,
  photonoteconfirm_active,
  discount_code,
  setdiscount_code,
  requestpickup,
  resetpickupdata
}) => {
  const [cancel_pickup_modal, setcancel_pickup_modal] = useState(false);
  const [confirming_loader, setconfirming_loader] = useState(false);
  const dispatch = useDispatch();
  // const [image, setimage] = useState([]);
  // image length  =20 images per customer
  const [image_base64, setimage_base64] = useState([]);
  const [current_image_index, setcurrent_image_index] = useState([false, -1]);
  // console.log(image_base64);
  // function getBase64(file) {
  //   var reader = new FileReader();
  //   reader.readAsDataURL(file);

  //   reader.onload = function () {
  //     setimage_base64([...image_base64, reader.result]);
  //     console.log(reader.result); //base64encoded string
  //   };
  // }
  // const add_img = (current) => {
  //   if (current.target.files.length) {
  //     console.log(Object.values(current.target.files));
  //     setimage([
  //       ...image,
  //       ...[...Object.values(current.target.files)].map((el) => {
  //         // console.log(el);
  //         getBase64(el);
  //         return {
  //           preview: URL.createObjectURL(el),
  //           raw: el,
  //           // base64: await getBase64( el)
  //         };
  //       }),
  //     ]);
  //   }
  // };
  const add_img = (current) => {
    let temp_arr = [];
    if (current.target.files.length) {
      // console.log(Object.values(current.target.files));
      Object.values(current.target.files).map((el) => {
        var reader = new FileReader();
        reader.readAsDataURL(el);
        reader.onload = function () {
          temp_arr.push(reader.result);
          if (current.target.files.length == temp_arr.length) {
            setimage_base64([...image_base64, ...temp_arr]);
          }
        };
      });
    }
  };

  // console.log("image", image);
  // console.log("image_base64", image_base64);
  return (
    <>
      {confirming_loader && <Loading/>}
    <div className="requestpickup_body addphotoandnote f-500">
      {/* <img className='requestpickup_hanger' src={hanger} /> */}
      <div className="txt f26">
        <div className="  ">
          Anything we should give some extra love to?
          <br />
          You can add a photo or a note below:
        </div>
        {/* <div className="txt2">
          Does Anything need
          <br />
          some extra love?
        </div> */}
      </div>
      <div className="requestpickup_nav_tabs">
        <div className="requestpickup_tabs f16">
          <input
            type="file"
            accept=".jpg,.png"
            placeholder=""
            id="addphoto"
            onChange={(e) => add_img(e)}
            multiple
            />
          {/* not completed check mtlp EDITMODAL.JS line 725  */}
          <label
            className=" t-white"
            mouse="pointer"
            htmlFor="addphoto"
            onClick={() => { }}
            >
            + Add photo
          </label>

          {image_base64.length > 0 && (
            <div className="images_colection">
              {image_base64.map((el, i) => (
                <div key={i} className={"img_div imgclass" + ((i % 3) + 1)}>
                  <div onClick={() => setcurrent_image_index([true, i])}>
                    <img src={el} alt="dummy" width="100" height="100" />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="requestpickup_tabs f16">
          <textarea
            rows="5"
            placeholder="+ Add note"
            type="text"
            className="requested_pickup_checkbox f16"
            id="drycleaning"
            value={pickup_note}
            onChange={(e) => setpickup_note(e.target.value)}
            />
        </div>
        <div className="requestpickup_tabs f16">
          <textarea
            rows="1"
            placeholder="+ Add discount code"
            type="text"
            className="requested_pickup_checkbox f16"
            id="drycleaning"
            value={discount_code}
            onChange={(e) => setdiscount_code(e.target.value)}
            />
        </div>
      </div>
      <div className="btns_group " style={{ marginTop: '20px' }}>
        <div
          mouse="pointer"
          className="requestpickup_cancelpickup"
          onClick={() => {
            setcancel_pickup_modal(true);
          }}
          >
          Cancel
        </div>
        <div
          mouse="pointer"
          className="requestpickup_confirm"
          active="true"
          onClick={() => {
            // dispatch({
              //   type: "photoandnote",
              //   payload: { photos: [...image_base64], note: pickup_note },
              // });
              if (confirming_loader) {
                return
              }
              setconfirming_loader(true);
              requestpickup(image_base64, pickup_note, (data) => {
                setconfirming_loader(false);
              })
            }}
            >
          {
            confirming_loader ?
            'Confirming...'
            :
            'Confirm'
          }
        </div>
      </div>

      {cancel_pickup_modal ? (
        <CancelPickupModal
        no={() => setcancel_pickup_modal(false)}
        cancelnow={() => {
          // console.log(1);
          setshow_requestpickup("RequestPickupType");
          resetpickupdata();
        }}
        />
        ) : (
          <></>
          )}
      {
        current_image_index[0] == true &&
        <AddPhotoAndNoteModal
        current_image_index={current_image_index[1]}
        images={image_base64}
        // setimage_modal={setimage_base64}
        setimages={setimage_base64}
        setcurrent_image_index={setcurrent_image_index}
        add_img={add_img}
        />
      }
    </div>
      </>
  );
};
