import React from "react";

export const CancelPickupModal = ({ no, cancelnow ,txt}) => {
  return (
    <div
      className="cancelpickupmodal"
      id="cancelpickupmodal"
      onClick={(e) => {
        e.target.id === "cancelpickupmodal" && no();
      }}
    >
      <div className="cancelpickupmodal_main f26" id="cancelpickupmodal_main">
        <div className="title">
          {
            txt ?
            <>
            {
              txt.map(el=><>{el}<br/></>)
            }
            </>
            :
          <>
            Do you really want
          <br />
          to cancel your pickup?
          </>
          }
        </div>
        <div className="btn_group">
          <div className="btn f14" onClick={cancelnow}>
            Cancel
          </div>
          <div className="btn f14" onClick={no}>
            No
          </div>
        </div>
      </div>
    </div>
  );
};
