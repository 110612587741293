import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import password_not_shown from "../../../img/password_not_shown.svg";
import password_shown from "../../../img/password_shown.svg";
import { encrypt_data } from "../../../RSA_Encryption/RSA";
import { edit_password, reset_password } from "../../API/API";

export const SetNewPassword = ({ cancel ,email,temptoken, settemptoken}) => {
  const [password1, setpassword1] = useState("");
  const [password1_err, setpassword1_err] = useState("");
  const [password1_type, setpassword1_type] = useState("password");
  const [password2, setpassword2] = useState("");
  const [password2_err, setpassword2_err] = useState("");
  const [password2_type, setpassword2_type] = useState("password");



  const navigate = useNavigate();
  console.log('temptoken',temptoken);
  
  const OnChangePassword = (e, state_password, state_passsword_err) => {
    let last_typed_letter = e.slice(-1).charCodeAt(0);
    if (last_typed_letter < 33 || last_typed_letter > 126) {
      return;
    }
    let temp_password = e.split("");
    let pass_count = {
      char: 0,
      num: 0,
      special: 0,
    };
    temp_password.map((el) => {
      let last_char_code = el.charCodeAt(0);
      if (last_char_code >= 48 && last_char_code <= 57) {
        pass_count.num += 1;
      } else if (
        (last_char_code >= 65 && last_char_code <= 90) ||
        (last_char_code >= 97 && last_char_code <= 122)
      ) {
        pass_count.char += 1;
      } else if (last_char_code >= 33 && last_char_code <= 126) {
        pass_count.special += 1;
      }
    });
    state_password(e);
    state_passsword_err(
      (
        (pass_count.char + pass_count.num + pass_count.special) < 6 ||
        pass_count.num < 1 ||
        pass_count.special < 1
      ).toString()
    );
    // console.log(pass_count, last_typed_letter);
  };
  console.log( password1 != password2, password1 ,password2);
  
  return (
    <>
      <div className="f14">SET NEW PASSWORD</div>
      <div className="forgotpassword_password">
        <input
          type={password1_type}
          className="f14"
          placeholder="New Password"
          inp_error={password1_err}
          value={password1}
          onChange={(e) => {
            OnChangePassword(e.target.value, setpassword1, setpassword1_err);
            setpassword2('')
          }}
          ></input>
        {password1_type === "text" ? (
          <img
          mouse="pointer"
          className="password_img"
          src={password_shown}
          onClick={() => {
              password1_type === "password"
                ? setpassword1_type("text")
                : setpassword1_type("password");
            }}
          />
        ) : (
          <img
            mouse="pointer"
            className="password_img"
            src={password_not_shown}
            onClick={() => {
              password1_type === "password"
                ? setpassword1_type("text")
                : setpassword1_type("password");
            }}
          />
        )}
      </div>
      <div className="forgotpassword_password " style={{margin:'0 0 0 0'}}>
        <input
          type={password2_type}
          className="f14"
          placeholder="Confirm New Password"
          value={password2}
          inp_error={  password2 == ''?'':(password1 != password2 ).toString()}
          onChange={(e) => {
            OnChangePassword(e.target.value, setpassword2, setpassword2_err);
          }}
          ></input>
        {password2_type === "text" ? (
          <img
            mouse="pointer"
            className="password_img"
            src={password_shown}
            onClick={() => {
              password2_type === "password"
                ? setpassword2_type("text")
                : setpassword2_type("password");
            }}
          />
        ) : (
          <img
            mouse="pointer"
            className="password_img"
            src={password_not_shown}
            onClick={() => {
              password2_type === "password"
                ? setpassword2_type("text")
                : setpassword2_type("password");
            }}
          />
        )}
      </div>
      {
        password1_err === 'true' &&
        <div style={{margin:'5px 0 auto 0'}} className="error" error={password1_err}>
          Your password needs to consist of
          <br />
          8 characters including 1 number, 1 symbol.
        </div>
      }
      {
        (password1 !== password2 && password2 != '') &&
        <div style={{margin:'5px 0 auto 0'}} className="error" error={'true'}>
           Passwords have to match.
        </div>
      }
      <div style={{margin:'auto 0 0 0'}} className="forgotpassword_cancel" mouse="pointer" onClick={cancel}>
        Cancel
      </div>
      {password1 == password2 && password1 != "" ? (
        <div
          className="forgotpassword_submit"
          mouse="pointer"
          onClick={() =>{
            reset_password(
              {
                email:email,
                token:temptoken,
                password:encrypt_data(password1)
              },
             temptoken,
              (data) => {
                console.log('verify password',data);
                // setshow_this_page(true)
                if (!data.status) {
                  return navigate('/404');
                }
                return navigate('/login');
                // setprofile({ ...profile, password: data.msg });
                // cancel();
                return
              }
            )
            // navigate("/login");
          }
            }
        >
          Continue
        </div>
      ) : (
        <div className="forgotpassword_submit disable" mouse="pointer">
          Continue
        </div>
      )}
    </>
  );
};
