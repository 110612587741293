import React, { useEffect, useState } from 'react'
import { edit_phone, resend_sms_otp, send_sms_otp, verify_sms_otp } from '../../API/API';
import { CreateAccountDots } from '../../CommonComponents/CreateAccountDots'

export const VerifyYourself = ({ phone, phone_err, OnChangePhone, setshow_create_card, phone_verified, setphone_verified, invalidsignup, setinvalidsignup, setshow_card }) => {
    const [code_sent, setcode_sent] = useState(false);
    const [resendcode, setresendcode] = useState(false);

    const [phone_validation_code, setphone_validation_code] = useState('');
    const [phone_validation_err, setphone_validation_err] = useState('');
    const [phone_verification_code_wrong, setphone_verification_code_wrong] = useState(false);
    useEffect(() => {

        setcode_sent(false)
        setphone_validation_err('')
        setphone_validation_code('')
    }, [phone])

    return (
        <div className='verifyyourself'>
            <div className="verifyyourself_heading f40">
                Verify
                <br />
                yourself
            </div>
            <div className="f14">MOBILE VERIFICATION</div>
            <div className="verifyyourself_body">
                <div className="verifyyourself_number">
                    <input
                        className="f14"
                        placeholder="Mobile"
                        value={phone}
                        inp_error={phone_err}
                        onChange={(e) => {
                            OnChangePhone(e);
                            if (invalidsignup == "User mobile already exists") {
                                setinvalidsignup('')
                            }
                        }}
                        readOnly={phone_verified}
                    ></input>
                </div>
                {
                    code_sent &&
                    <>
                        <div className="verifyyourself_number">
                            <input
                                className="f14"
                                placeholder="Verification Code"
                                value={phone_validation_code}
                                inp_error={phone_validation_err.toString()}
                                onChange={(e) => {
                                    setphone_validation_code(e.target.value)
                                    setphone_validation_err(e.target.value.length == 0)
                                    setphone_verification_code_wrong(false)
                                }}
                                ></input>
                        </div>
                        {
                            phone_verification_code_wrong &&
                            <div className='error' error={phone_verification_code_wrong.toString()}>
                                Verification Code wrong.
                            </div>
                        }
                        <div
                            className="resend_code f-500 "
                            cursor="pointer"
                            onClick={() => {
                                if(resendcode){
                                    return
                                }
                                // creataccount && setshow_create_card("verifyyourself")
                                setresendcode(true)
                                resend_sms_otp(phone, (data) => {
                                    // console.log(data);
                                    if (data.status) {
                                        setphone_validation_code('');
                                        setphone_validation_err(true)
                                        setphone_verification_code_wrong(false);
                                        setresendcode(false)
                                    }

                                })
                            }
                            }
                        >
                            {
                                resendcode ? 'Resending...' : 'Resend code'
                            }
                        </div>
                    </>
                }
                <div className="verifyyourself_login f13 f-400"  style={{width:'fit-content'}} mouse="pointer"  onClick={() => {
                        setshow_card("login")
                    }
                    }>
                    <div>Have an Account?</div>
                    <div>Log In</div>
                </div>
                <div className="verifyyourself_agreement_btn" mouse="pointer">
                    {
                        !code_sent ?
                            <>
                                <div
                                    className="previous f-500 "
                                    cursor="pointer"
                                    onClick={() => {
                                        // creataccount && setshow_create_card("verifyyourself")
                                        setshow_create_card("letsgetstarted")
                                    }
                                    }
                                >
                                    Previous
                                </div>
                                {
                                    phone_verified ?
                                        <div
                                            className="submit f-500 "
                                            cursor="pointer"
                                            onClick={() => {
                                                // creataccount && setshow_create_card("verifyyourself")
                                                setshow_create_card("wheredoyoulive")
                                            }
                                            }
                                        >
                                            Next
                                        </div>
                                        :
                                        <div
                                            className={"submit f-500 " + (phone_err == 'false' ? "" : "disabled")}
                                            cursor="pointer"
                                            onClick={() => {
                                                // creataccount && setshow_create_card("verifyyourself")
                                                send_sms_otp(phone, (data) => {
                                                    // console.log('after sending msg otp', data)
                                                    if (!data.status) {
                                                        return
                                                    }
                                                    setcode_sent(true)

                                                })
                                            }
                                            }
                                        >
                                            Send Code
                                        </div>
                                }
                            </>
                            :
                            <>
                                <div
                                    className="previous f-500 "
                                    cursor="pointer"
                                    onClick={() => {
                                        // creataccount && setshow_create_card("verifyyourself")
                                        setphone_validation_code('')
                                        setshow_create_card("letsgetstarted")
                                    }
                                    }
                                >
                                    Previous
                                </div>
                                <div
                                    className={"submit f-500 " + (phone_validation_code != '' ? "" : "disabled")}
                                    cursor="pointer"
                                    onClick={() => {
                                        // creataccount && setshow_create_card("verifyyourself")
                                        if (phone_validation_code == '') {
                                            return
                                        }
                                        verify_sms_otp(
                                            {
                                                phone_no: phone,
                                                otp: phone_validation_code
                                            },
                                            (data) => {
                                                // console.log(data);
                                                if (data.status) {
                                                    // edit_phone();
                                                    setshow_create_card("wheredoyoulive")
                                                    return
                                                }
                                                setphone_verification_code_wrong(true)
                                                setphone_validation_err(true)
                                            }
                                        )
                                        // setcode_sent(true);
                                    }
                                    }
                                >
                                    Next
                                </div>
                            </>
                    }
                </div>
            </div>
            <CreateAccountDots show_dots="2" />

        </div>
    )
}
