import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import trash from "../../img/trash.svg";
import add_img from "../../img/add.svg";
import {
  cancel_order,
  get_orderfiledata,
  get_order_by_id,
  get_order_images,
} from "../API/API";
import { useSelector } from "react-redux";
import { CancelPickupModal } from "../CancelPickupModal";

const test1_data = {
  sql_data: {
    order_id: 0,
    display_status: "",
    client_particulars: {
      others: "0",
      laundry: "0",
      drycleaning: "0",
    },
    order_date: "",
    amount: "",
    notes: "",
    images: [],
    discount_code: "",
    client_name: "",
    client_id: 0,
    address: "",
    mobile: "",
    last_4: "",
  },
  file_data: {
    order_item: [],
    globaldiscount: "",
    discount_switch: false,
    images_notes: [{ img: [], notes: "" }],
    submitted: false,
  },
};

const status = ["pickup requested", "clothes picked up", "clothes delivered"];
const temp_obj = {
  order_item: [
    {
      item_name: "Laundry - Wash and Fold",
      item_price: "75",
      item_weight: "15",
    },
    { item_name: "Hang Dry", item_price: "24", item_weight: -1 },
  ],
};

export const NewViewOrder = ({
  view_order,
  order_item,
  setloader,
  order,
  setorder,
  setshowing_order_item,
  order_item_index,
  setorder_item_index,
  setview_order,
}) => {
  const navigate = useNavigate();

  const current_store = useSelector((state) => state);
  const [loadermodal, setloadermodal] = useState(false);
  useEffect(() => {
    setloader(loadermodal);
  }, [loadermodal]);
  const [test_data, settest_data] = useState({ ...test1_data });
  // const [test_data, settest_data] = useState({});
  const [calculated, setcalculated] = useState({
    subtotal: 0,
    discount: 0,
  });
  useEffect(() => {
    document.body.style = view_order ? "overflow:hidden" : "";
    if (!view_order) {
      return;
    }
    if (!order_item.id) {
      return;
    }
    setloadermodal(true);
    get_order_by_id(order_item.id, current_store.user.token, (data) => {
      setloadermodal(false);
      console.log("customer data", data.msg);
      settest_data({ ...data.msg });
      let temp_test_data = { ...data.msg };
      let temp_sub_total = 0;
      let temp_discount = 0;
      // if(Object.keys(temp_test_data.file_data).length == 0){
      //   return
      // }
      // if()
      temp_test_data.file_data?.order_item.map(
        (el) => (temp_sub_total += Number(el.total))
      );
      // setsub_total(temp_sub_total);
      if (temp_test_data.file_data.discount_switch) {
        temp_discount = temp_test_data.file_data.globaldiscount;
      } else {
        temp_discount =
          (temp_sub_total / 100) *
          (temp_test_data.file_data.globaldiscount || 0);
      }
      console.log({ subtotal: temp_sub_total, discount: temp_discount });
      setcalculated({
        ...calculated,
        subtotal: temp_sub_total,
        discount: temp_discount,
      });
    });
  }, [view_order]);
  const [cancelordermodalloader, setcancelordermodalloader] = useState(false);
  const [cancelpickupmodal, setcancelpickupmodal] = useState(false);
  const [cancelpickupmodal_txt, setcancelpickupmodal_txt] = useState("");
  const [current_item, setcurrent_item] = useState({
    ...order_item,
    file_data: {
      order_item: [],
    },
  });
  console.log();
  useEffect(() => {
    if (Object.keys(order_item).length != 0) {
      get_orderfiledata("", "", (data) => {
        setcurrent_item({
          ...order_item,
          file_data: {
            order_item: [...data],
          },
        });
      });
    }
    // get_order_images(
    //   order_item.id,
    //   current_store.user.token,
    //   (data)=>{
    //     console.log(data);
    //     if(!data.status){
    //       // return navigate('/404')
    //     }
    //     return setcurrent_item({
    //       ...current_item,
    //       photos:[
    //         ...data.msg.map(el=>el.url)
    //       ]
    //     })
    //   }
    // )
  }, [order_item]);

  //   console.log(order_item);
  // console.log("current_item", current_item);

  // const add_img_to_currentedit = (current) => {
  //   let temp_arr = [];
  //   if (current.target.files.length) {
  //     console.log(Object.values(current.target.files));
  //     Object.values(current.target.files).map((el) => {
  //       var reader = new FileReader();
  //       reader.readAsDataURL(el);
  //       reader.onload = function () {
  //         //   console.log("reader", reader.result);
  //         temp_arr.push(reader.result);
  //         //   console.log("reader_temp_Arr", temp_arr);
  //         if (current.target.files.length == temp_arr.length) {
  //           setcurrent_item({
  //             ...current_item,
  //             photos: [...current_item.photos, ...temp_arr],
  //           });
  //         }
  //       };
  //     });
  //   }
  // };
  // const removeimg = (i) => {
  //   let copy_current_photos = [...current_item.photos];
  //   copy_current_photos.splice(i, 1);
  //   setcurrent_item({
  //     ...current_item,
  //     photos: copy_current_photos,
  //   });
  // };
  if (Object.keys(test_data).length == 0) {
    return "";
  } else {
    console.log("loadermodal", loadermodal);
    return (
      <>
        <div
          className={
            "add_edit_order " + (view_order && !loadermodal ? "active" : "")
          }
        >
          {/* <div className="header">
        <div className="close" onClick={() => setview_order(false)}></div>
      </div> */}
          <div className="body">
            {/* <div className="order_date f30">{current_item.date}</div> */}
            {Object.keys(test_data.file_data).length > 0 &&
              test_data.file_data.order_item.length != 0 &&
              test_data.file_data?.submitted !== false && (
                <div className="main_card">
                  {/* <div className="name">{test_data.sql_data.client_name}</div> */}
                  <div className="add">
                    {test_data.sql_data.address || "No Address Found"}
                  </div>
                  <div className="mobile">
                    {test_data.sql_data.mobile || "No Mobile Number Found"}
                  </div>
                  <div className="card_last4">
                    {test_data.sql_data.last_4 ? (
                      <>**** **** {test_data.sql_data.last_4}</>
                    ) : (
                      "No Card Found"
                    )}
                  </div>
                  <div className="item_collection">
                    {
                      // test_data.submitted &&
                      test_data.file_data.order_item.map((el, i) => (
                        <div
                          className="item"
                          style={
                            view_order
                              ? {
                                  animation: `to-top ease-in-out forwards 0.3s ${
                                    0.2 * (i + 1)
                                  }s`,
                                }
                              : {}
                          }
                        >
                          <div className="item_data">
                            <div className="item_name">
                              {el.item_name.split("%ITEMS_NAME_DIVIDER%")
                                .length > 1
                                ? el.item_name
                                    .split("%ITEMS_NAME_DIVIDER%")
                                    .map((el1) => <div>{el1.trim()}</div>)
                                : el.item_name}
                            </div>
                            {el.item_weight && el.item_weight != -1 && (
                              <div className="weight">{el.item_weight}lb</div>
                            )}
                          </div>
                          <div className="item_price">${el.item_price}</div>
                          {/* {el?.addon_items && el.addon_items.length > 0 && ( */}
                          <div className="item_addon_group">
                            {el.addon_items.map((el1, i) => (
                              <div className="item_addonitem">
                                <div className="addon_list">{i + 1}.</div>
                                <div className="addon_name">
                                  <div>{el1.addon_title}</div>
                                  <div>{el1.addon_sub}</div>
                                </div>
                                <div className="addon_price">
                                  ${el1.price.toFixed(2)}
                                </div>
                              </div>
                            ))}
                            {el.addon_items.length > 0 &&
                            Number(el.discount_value) > 0 ? (
                              <div className="divider_line">
                                {/* {el.discount_value} */}
                              </div>
                            ) : (
                              ""
                            )}
                            {/* start options */}
                            {Number(el.discount_value) > 0 &&
                              el.addon_items.length > 0 && (
                                <div className="card_total">
                                  {el.addon_items.length > 0 && (
                                    <>
                                      <div>SubTotal</div>
                                      <div>
                                        $
                                        {Number(el.total) === 0
                                          ? (
                                              Number(el.item_price) +
                                              Number(
                                                el.addon_items.reduce(
                                                  (prev, curr) =>
                                                    prev + curr.price,
                                                  0
                                                )
                                              )
                                            ).toFixed(2)
                                          : (
                                              Number(el.total) +
                                              (Number(el.discount_value) > 0
                                                ? Number(el.discount_value)
                                                : 0)
                                            ).toFixed(2)}
                                      </div>
                                    </>
                                  )}
                                </div>
                              )}
                            {/* {el.discount_value || "not exists"} */}
                            {Number(el.discount_value) > 0 && (
                              <>
                                <div
                                  className="card_discount_total"
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <div>Discount</div>
                                  <div>
                                    - $
                                    {Number(el.total) === 0
                                      ? (
                                          Number(el.item_price) +
                                          Number(
                                            el.addon_items.reduce(
                                              (prev, curr) => prev + curr.price,
                                              0
                                            )
                                          )
                                        ).toFixed(2)
                                      : Number(el.discount_value).toFixed(2)}
                                  </div>
                                </div>
                              </>
                            )}
                            <div className="divider_line"></div>
                            <div className="card_total">
                              <div>Total</div>
                              <div>${el.total}</div>
                            </div>
                          </div>
                          {/* )} */}
                        </div>
                      ))
                    }
                  </div>
                  <div className="sub_total">
                    <div>Subtotal</div>
                    <div>
                      $
                      {test_data.submitted
                        ? Number(calculated.subtotal).toFixed(2).toString()
                        : "0.00"}
                    </div>
                  </div>
                  <div className="discount">
                    <div>Discount</div>
                    <div>
                      -$
                      {test_data.submitted
                        ? Number(calculated.discount).toFixed(2).toString()
                        : "0.00"}
                    </div>
                  </div>
                  <div className="total">
                    <div>Total</div>
                    <div>
                      $
                      {test_data.submitted
                        ? Number(
                            Number(calculated.subtotal) -
                              Number(calculated.discount)
                          )
                            .toFixed(2)
                            .toString()
                        : "0.00"}
                    </div>
                  </div>
                </div>
              )}
            <div
              className="details_card"
              single={(test_data.file_data.order_item.length === 0).toString()}
            >
              <div className="card_title">Order Details</div>
              <div>
                <div className="title">Order Date</div>
                <div className="option_group">
                  {test_data.sql_data.order_date}
                </div>
              </div>
              {Number(
                Object.values(test_data.sql_data.client_particulars).join("")
              ) > 0 && (
                <div>
                  <div className="title">Requested Pickup</div>
                  <div className="option_group">
                    <ol>
                      {[
                        ["laundry", "Laundry"],
                        ["drycleaning", "Dry Cleaning"],
                        ["others", "Others"],
                      ].map((el) => {
                        if (
                          test_data.sql_data.client_particulars[el[0]] == "1"
                        ) {
                          return <li className="option">{el[1]}</li>;
                        }
                      })}
                    </ol>
                  </div>
                </div>
              )}
              <div>
                <div className="title">Status</div>
                <div>{test_data.sql_data.display_status}</div>
              </div>
              {test_data.sql_data.discount_code && (
                <div>
                  <div className="title">Discount Code</div>
                  <div>{test_data.sql_data.discount_code || "-"}</div>
                </div>
              )}
              <div>
                <div className="title">Notes</div>
                <div className="notes">{test_data.sql_data.notes || "-"}</div>
              </div>
              <div className="btn_group">
                <div
                  className="btn"
                  onClick={() => {
                    setview_order(false);
                    setTimeout(() => {
                      settest_data({ ...test1_data });
                    }, 300);
                  }}
                >
                  Back
                </div>
                {/* {true ? ( */}
                {test_data.sql_data.stripe_reciept ? (
                  <div className="btn a">
                    <a href={test_data.sql_data.stripe_reciept} target="_blank">
                      Receipt
                    </a>
                  </div>
                ) : (
                  ""
                )}
                {test_data.sql_data.display_status === "Pickup Requested" ||
                  (test_data.sql_data.display_status === "Picked Up" && (
                    // {true && (
                    <div
                      className="btn"
                      onClick={() => {
                        if (cancelordermodalloader) {
                          return;
                        }
                        setcancelordermodalloader(true);
                        setloadermodal(true);
                        get_order_by_id(
                          current_item.id,
                          current_store.user.token,
                          (data) => {
                            setloadermodal(false);
                            console.log("cancel", data.msg);
                            if (!data.status) {
                              return navigate("/404");
                            }
                            setcancelordermodalloader(false);
                            console.log("order_by_id", data.msg.display_status);
                            if (data.msg.cancel_display) {
                              setcancelpickupmodal_txt(data.msg.cancel_display);
                            }
                            if (
                              data.msg.sql_data.display_status ===
                                "Pickup Requested" ||
                              data.msg.sql_data.display_status === "Picked Up"
                            ) {
                              setcancelpickupmodal(true);
                            } else {
                              // setcurrent_item({
                              //   ...current_item,
                              //   status: data.msg.display_status,
                              // });
                              settest_data({ ...data.msg });
                              let temp_test_data = { ...data.msg };
                              let temp_sub_total = 0;
                              let temp_discount = 0;
                              temp_test_data.file_data.order_item.map(
                                (el) =>
                                  (temp_sub_total += Number(el.item_price))
                              );
                              // setsub_total(temp_sub_total);
                              if (temp_test_data.file_data.discount_switch) {
                                temp_discount =
                                  temp_test_data.file_data.globaldiscount;
                              } else {
                                temp_discount =
                                  (temp_sub_total / 100) *
                                  temp_test_data.file_data.globaldiscount;
                              }
                              console.log({
                                subtotal: temp_sub_total,
                                discount: temp_discount,
                              });
                              setcalculated({
                                ...calculated,
                                subtotal: temp_sub_total,
                                discount: temp_discount,
                              });
                            }
                          }
                        );
                      }}
                    >
                      {/* <a href="/test" target="_blank"> */}
                      {cancelordermodalloader
                        ? "Fetching Data"
                        : "Cancel Order"}
                      {/* </a> */}
                    </div>
                  ))}
              </div>
            </div>
            {/* {false && ( */}
            {test_data.sql_data.images.length > 0 && (
              <div className="photos_notes_card">
                <div className="card_title">Photos</div>
                <div className="image_group">
                  {test_data.sql_data.images.map((el) => (
                    <div>
                      <img src={el.url} />
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
          {cancelpickupmodal && (
            <CancelPickupModal
              txt={cancelpickupmodal_txt}
              cancelnow={() => {
                cancel_order(
                  current_item.id,
                  current_store.user.token,
                  (data) => {
                    if (data.error) {
                      // do something
                      return;
                    }
                    // setcancel_pickup_modal(false)
                    // setshow_requestpickup("RequestPickupType");
                    window.location.reload();
                    // console.log(data);
                  }
                );
              }}
              no={() => {
                setcancelpickupmodal(false);
              }}
            />
          )}
          {/* {loadermodal && ( */}
        </div>
        {/* {loadermodal && (
        <div className="order_loader">
        <div>
        Fetching Order Details
        <div className="dot_group">
        <div className="dots"></div>
        <div className="dots"></div>
        <div className="dots"></div>
        <div className="dots"></div>
        </div>
        </div>
        </div>
      )} */}
      </>
    );
  }
};
