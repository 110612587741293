import React, { useEffect, useState } from "react";
import hanger from "../../img/requestpickup/hanger.svg";
import arrow_down_icon from '../../img/arrow_down_icon.svg'

export const PickupType = ({
  setlaundry_selected,
  setdrycleaning_selected,
  others_selected,
  setothers_selected,
  setshow_requestpickup,
  confirm_active,
  add_arr,
  address_for_delivery,
  setaddress_for_delivery
}) => {
  const [divheight,setdivheight] = useState(0)
  useEffect(()=>{
    const heightaddressdiv = document.getElementById('getheightaddressdropdown').clientHeight;
    // console.log(heightaddressdiv);
    setdivheight(heightaddressdiv);
  },[add_arr])

  return (
    <div className="requestpickup_body">
      <div>
        <img className="requestpickup_hanger" src={hanger} />
      </div>
      <div className="requestpickup_nav_tabs" style={{ maxWidth: '425px' }}>
        <div className="requestpickup_tabs f16">
          <input
            type="checkbox"
            className="requested_pickup_checkbox"
            id="laundry"
          />
          <label
            mouse="pointer"
            htmlFor="laundry"
            onClick={() =>
              setlaundry_selected(!document.getElementById("laundry").checked)
            }
          >
            Laundry
          </label>
        </div>
        <div className="requestpickup_tabs f16">
          <input
            type="checkbox"
            className="requested_pickup_checkbox"
            id="drycleaning"
          />
          <label
            mouse="pointer"
            htmlFor="drycleaning"
            onClick={() =>
              setdrycleaning_selected(
                !document.getElementById("drycleaning").checked
              )
            }
          >
            Dry Cleaning
          </label>
        </div>
        <div className="requestpickup_tabs f16 other_main">
          <input
            type="checkbox"
            className="requested_pickup_checkbox"
            id="others"
          />
          <label
            mouse="pointer"
            htmlFor="others"
            onClick={() =>
              setothers_selected(!document.getElementById("others").checked)
            }
          >
            Others (Shoes, Alterations, ...)
          </label>
        </div>
        <div className="requestpickup_tabs f16 others_extra" show={others_selected.toString()}>
          Other includes but is not limited to Shoes, Alterations, Leather and
          Suede.
        </div>
      </div>
      <div className="requestpickup_default_address">
        <div className="cust_dropdown" id='cust_dropdown' onClick={() => {
          document.getElementById('cust_dropdown').classList.toggle('cust_dropdown_active')
          let cust_dropdown_body = document.getElementById('cust_dropdown_body');
          cust_dropdown_body.style.height = (cust_dropdown_body.style.height != '') ? '' : `${divheight}px`;
        }}>
          <div className="default" style={{textOverflow:'ellipsis',overflow:'hidden'}}>
            {
              address_for_delivery != -1 && `${add_arr[address_for_delivery].address}, ${add_arr[address_for_delivery].apartment}, ${add_arr[address_for_delivery].zipcode}`
            }
          </div>
          <img src={arrow_down_icon} />
          <div className="cust_dropdown_body" id='cust_dropdown_body'>
            <div id='getheightaddressdropdown'>

              {
                add_arr.map(
                  (el, i) => <div
                    className={(i == address_for_delivery) ? 'current_selected' : ''}
                    onClick={() => {
                      setaddress_for_delivery(i)
                    }}
                  >
                    {`${el.address}, ${el.apartment}, ${el.zipcode}`}
                  </div>
                )
              }
            </div>
          </div>
        </div>
      </div>
      <div
        mouse="pointer"
        className="requestpickup_confirm"
        active={confirm_active.toString()}
        onClick={() =>
          confirm_active && setshow_requestpickup("addphotoandnote")
        }
      // style={{ marginTop: "16px" }}
      >
        Confirm
      </div>
      <div className="requestpickup_reminder f14">
        Reminder: we pick up everyday 5PM-9PM.
      </div>
    </div >
  );
};
