// import { add } from "date-fns";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import { add_address, edit_address } from "../../API/API";

export const AddEditAddress = ({
  type,
  cancel,
  data_index,
  profile,
  setprofile,
  setshow_this_page,
}) => {
  const current_store = useSelector((state) => state);
  const navigate = useNavigate();
  const [add_details, setadd_details] = useState({
    add: "",
    apt: "",
    zip: "",
    doorman: false,
    default: false,
  });
  const [edit_save, setedit_save] = useState(false);
  useEffect(() => {
    if (type == "edit") {
      setadd_details({
        id: profile.add_arr[data_index].id,
        add: profile.add_arr[data_index].add,
        apt: profile.add_arr[data_index].apt,
        zip: profile.add_arr[data_index].zip,
        doorman: profile.add_arr[data_index].doorman,
        default: profile.add_arr[data_index].default,
      });
    }
  }, []);
  useEffect(() => {
    if (type == "edit") {
      if (
        add_details.add == profile.add_arr[data_index].add &&
        add_details.apt == profile.add_arr[data_index].apt &&
        add_details.zip == profile.add_arr[data_index].zip &&
        add_details.doorman == profile.add_arr[data_index].doorman &&
        add_details.default == profile.add_arr[data_index].default
      ) {
        setedit_save(false);
      } else {
        setedit_save(true);
      }
    }
  }, [add_details]);
  useEffect(() => {
    if (type == "edit") {
      if (
        add_details.add != profile.add_arr[data_index].add ||
        add_details.apt != profile.add_arr[data_index].add ||
        add_details.zip != profile.add_arr[data_index].add ||
        add_details.doorman != profile.add_arr[data_index].add ||
        add_details.default != profile.add_arr[data_index].add
      ) {
      }
    }
  }, []);
  return (
    <div className="profile_modal_common_card">
      <div className="card_heading">
        {type == "edit" ? "Edit" : "Add"}
        <br />
        Address
      </div>
      <div className="card_title">ADDRESS DETAILS</div>
      <div className="card_input">
        <input
          type="text"
          placeholder="Address"
          value={add_details.add}
          onChange={(e) =>
            setadd_details({ ...add_details, add: e.target.value })
          }
        />
      </div>
      <div className="card_input">
        <input
          type="text"
          placeholder="Apt"
          value={add_details.apt}
          onChange={(e) =>
            setadd_details({ ...add_details, apt: e.target.value })
          }
        />
      </div>
      <div className="card_input">
        <input
          type="number"
          placeholder="Zip Code"
          value={add_details.zip}
          onChange={(e) =>
            setadd_details({ ...add_details, zip: e.target.value })
          }
        />
      </div>
      <div className="card_input doorman_checkbox">
        <div className="tick_checkbox">
          <input
            mouse="pointer"
            type="checkbox"
            checked={add_details.doorman}
          />
          <label
            onClick={() => {
              setadd_details({ ...add_details, doorman: !add_details.doorman });
            }}
          >
            <div className="show_cust_checkbox"></div>
          </label>
        </div>
        <label
          mouse="pointer"
          onClick={() => {
            setadd_details({ ...add_details, doorman: !add_details.doorman });
          }}
        >
          YES, I have a doorman
        </label>
      </div>
      {/* {type == "add" && ( */}
      <div
        className="card_input doorman_checkbox"
        style={{ marginTop: "15px" }}
      >
        <div className="tick_checkbox">
          <input
            mouse="pointer"
            type="checkbox"
            checked={add_details.default}
          />
          <label
            onClick={() => {
              setadd_details({ ...add_details, default: !add_details.default });
            }}
          >
            <div className="show_cust_checkbox"></div>
          </label>
        </div>
        <label
          mouse="pointer"
          onClick={() => {
            setadd_details({ ...add_details, default: !add_details.default });
          }}
        >
          Set as default address
        </label>
      </div>
      {/* )} */}
      <div className="margin_bottom_auto"></div>
      <div className="btn_group btn_group_horizontal">
        <div btn_type="type1" onClick={cancel}>
          Cancel
        </div>
        {type == "edit" ? (
          <div
            btn_type={edit_save ? "type3" : "type2"}
            onClick={() => {
              if (!edit_save) {
                return;
              }
              edit_address(
                {
                  address_id: add_details.id,
                  address1: add_details.add,
                  apt: add_details.apt,
                  zipcode: add_details.zip,
                  doorman: add_details.doorman ? "1" : "0",
                  is_default: add_details.default ? "1" : "0",
                },
                current_store.user.token,
                (data) => {
                  if (!data.status) {
                    return navigate("/404");
                  }
                  setprofile({
                    ...profile,
                    add_arr: [
                      ...data.msg.map((el) => {
                        return {
                          id: el.id,
                          add: el.address,
                          apt: el.apartment,
                          zip: el.zipcode,
                          doorman: el.is_doorman == "0" ? false : true,
                          default: el.is_default == "0" ? false : true,
                        };
                      }),
                    ],
                  });
                  cancel();
                }
              );
              // let temp_add_arr = profile.add_arr;
              // temp_add_arr[data_index] = add_details;
            }}
          >
            Save
          </div>
        ) : (
          <div
            btn_type={
              add_details.add == "" ||
              add_details.apt == "" ||
              add_details.zip == ""
                ? "type2"
                : "type3"
            }
            onClick={() => {
              if (
                add_details.add == "" ||
                add_details.apt == "" ||
                add_details.zip == ""
              ) {
                return;
              }
              setshow_this_page(false);
              add_address(
                {
                  address1: add_details.add,
                  apt: add_details.apt,
                  zipcode: add_details.zip,
                  doorman: add_details.doorman ? "1" : "0",
                  is_default:
                    profile.add_arr.length === 0
                      ? "1"
                      : add_details.default
                      ? "1"
                      : "0",
                },
                current_store.user.token,
                (data) => {
                  if (!data.status) {
                    return navigate("/404");
                  }
                  setprofile({
                    ...profile,
                    add_arr: [
                      ...data.msg.map((el) => {
                        return {
                          id: el.id,
                          add: el.address,
                          apt: el.apartment,
                          zip: el.zipcode,
                          doorman: el.is_doorman == "0" ? false : true,
                          default: el.is_default == "0" ? false : true,
                        };
                      }),
                    ],
                  });
                  cancel();
                  setshow_this_page(true);
                }
              );
            }}
          >
            Add
          </div>
        )}
      </div>
    </div>
  );
};
