import { cardActionAreaClasses } from "@mui/material";
import React from "react";

export const PaymentAddressDelete = ({
  type,
  // change_arr,
  data_index,
  profile,
  setprofile,
  setcurrent_showing_add_in_slider,
  setcurrent_showing_card_in_slider,
  cancel,
  handelclick,
  donot
}) => {
  return (
    <div className="profile_delete_modal_common_card">
      <div className="card_question">
        {
          donot ?
            <>
              Please change your default {type} and try again
            </>
            : <>
              Do you really want to delete this {type}?
            </>
        }
      </div>

      <div className="btn_group">
        {
          !donot &&
          <div
            btn_type="type2"
            onClick={() => {
              handelclick()
            }}
          >
            Delete now
          </div>
        }
        <div btn_type="type3" onClick={cancel}>
          {
            donot ?
              'Go Back' :
              'No'
          }
        </div>
      </div>
    </div>
  );
};
