import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import password_not_shown from "../../../img/password_not_shown.svg";
import password_shown from "../../../img/password_shown.svg";

import { OR_LoginThrough } from "../../CommonComponents/OR_LoginThrough";
import { CreateAccountDots } from "../../CommonComponents/CreateAccountDots";
import { WhereDoYouLive } from "./WhereDoYouLive";
import { OneMoreThing } from "./OneMoreThing";
import axios from "axios";
import { VerifyYourself } from "./VerifyYourself";
import { sign_up } from "../../API/API";
import { encrypt_data } from "../../../RSA_Encryption/RSA";
// import { OneMoreThingStripe } from "./OneMoreThingStript";

const months = [
  "January",
  "February",
  "March",
  "April",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export const LetsGetStarted = ({ setshow_card, setshow_dots }) => {
  const [show_create_card, setshow_create_card] = useState("letsgetstarted");
  // const [show_create_card, setshow_create_card] = useState("onemorething");

  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    setshow_dots(show_create_card);
  }, [show_create_card]);

  // createaccount start

  const [creataccount, setcreataccount] = useState(false);

  const [password_type, setpassword_type] = useState("password");

  const [name, setname] = useState("");
  const [name_err, setname_err] = useState("");

  const [phone, setphone] = useState("");
  const [phone_err, setphone_err] = useState("");
  const [phone_verified, setphone_verified] = useState(false);
  // const [phone_verification, setphone_verification] = useState("");
  // const [phone_verification_from_api, setphone_verification_from_api] = useState("12345");
  // const [phone_verification_err, setphone_verification_err] = useState("");
  // const [phone_verification_state, setphone_verification_state] = useState("");
  // const [phone_verification_counter, setphone_verification_counter] = useState(0);
  // console.log('phone_verification', phone_verification);
  // a@b.com
  const [email, setemail] = useState("");
  const [email_disabled, setemail_disabled] = useState(false);
  const [email_err, setemail_err] = useState("");

  // 1234@abcdef
  const [password, setpassword] = useState("");
  const [password_err, setpassword_err] = useState("");

  useEffect(() => {
    if (
      name != "" &&
      email != "" &&
      email_err == "false" &&
      password != "" &&
      name_err == "false" &&
      password_err == "false"
    ) {
      setcreataccount(true);
    } else {
      setcreataccount(false);
    }
  }, [name, name_err, phone, phone_err, email, password, email_err]);
  // useEffect(() => {
  //   if (phone_verification == '') {
  //     return
  //   }
  //   if (phone_verification === phone_verification_from_api) {
  //     return setphone_verification_err(false)
  //   }
  //   setphone_verification_err(true)
  // }, [phone_verification])

  const OnChangeName = (e) => {
    setname(e);
    setname_err((e.length == 0).toString());
  };
  const OnChangeEmail = (e) => {
    setemail(e);
    setemail_err(
      (
        e.match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        ) == null
      ).toString()
    );
  };

  const OnChangePhone = (e) => {
    // console.log([9, 13].some((el) => el == e.target.value.length));
    if (
      Number(e.target.value.slice(-1)).toString() == "NaN" ||
      e.target.value.length > 17 ||
      (e.target.value.slice(-1) == " " &&
        ![5, 9, 13].some((el) => el == e.target.value.length))
    ) {
      return;
    }
    setphone_err("true");
    if (e.target.value.length == 17) {
      setphone_err("false");
      // setphone_verification_state('')
    }
    switch (e.target.value.length) {
      case 1:
        return setphone(`(+1) ${e.target.value[0]}`);
      case 5:
        return setphone("");
      case 9:
        if (e.target.value.slice(-1) == " ") {
          return setphone(e.target.value.slice(0, 8));
        }
        return setphone(
          e.target.value.slice(0, 8) + " " + e.target.value.slice(-1)
        );
      case 13:
        if (e.target.value.slice(-1) == " ") {
          return setphone(e.target.value.slice(0, 12));
        }
        return setphone(
          e.target.value.slice(0, 12) + " " + e.target.value.slice(-1)
        );

      default:
        return setphone(e.target.value);
    }
  };
  const OnChangePassword = (e) => {
    let last_typed_letter = e.target.value.slice(-1).charCodeAt(0);
    if (last_typed_letter < 33 || last_typed_letter > 126) {
      return;
    }
    let temp_password = e.target.value.split("");
    let pass_count = {
      char: 0,
      num: 0,
      special: 0,
    };
    temp_password.map((el) => {
      let last_char_code = el.charCodeAt(0);
      if (last_char_code >= 48 && last_char_code <= 57) {
        pass_count.num += 1;
      } else if (
        (last_char_code >= 65 && last_char_code <= 90) ||
        (last_char_code >= 97 && last_char_code <= 122)
      ) {
        pass_count.char += 1;
      } else if (last_char_code >= 33 && last_char_code <= 126) {
        pass_count.special += 1;
      }
    });
    setpassword(e.target.value);
    setpassword_err(
      (
        pass_count.char + pass_count.num + pass_count.special < 8 ||
        pass_count.num < 1 ||
        pass_count.special < 1
      ).toString()
    );
    // console.log(pass_count, last_typed_letter);
  };
  // createaccount end

  // almostthere start
  const [next, setnext] = useState(false);

  const [address, setaddress] = useState("");
  const [address_err, setaddress_err] = useState("");
  const [apartmentno, setapartmentno] = useState("");
  const [apartmentno_err, setapartmentno_err] = useState("");
  const [zipcode, setzipcode] = useState("");
  const [zipcode_err, setzipcode_err] = useState("");
  const [i_have_a_doorman, seti_have_a_doorman] = useState(false);
  let almosttheredata = {
    address: address,
    setaddress,
    address_err,
    setaddress_err,
    apartmentno,
    setapartmentno,
    apartmentno_err,
    setapartmentno_err,
    zipcode,
    setzipcode,
    zipcode_err,
    setzipcode_err,
    i_have_a_doorman,
    seti_have_a_doorman,
    next,
    setnext,
  };
  // almosttherer end

  // daretobedirty start
  const [nameoncard, setnameoncard] = useState("");
  const [nameoncard_err, setnameoncard_err] = useState("");
  const [cardnumber, setcardnumber] = useState("");
  const [cardnumber_err, setcardnumber_err] = useState("");
  const [expirydate, setexpirydate] = useState("");
  const [expirydate_err, setexpirydate_err] = useState("");
  const [cvv, setcvv] = useState("");
  const [cvv_err, setcvv_err] = useState("");

  const [invalidcard, setinvalidcard] = useState("false");
  const [invalidsignup, setinvalidsignup] = useState("");

  let daretobedirtydata = {
    nameoncard,
    setnameoncard,
    nameoncard_err,
    setnameoncard_err,
    cardnumber,
    setcardnumber,
    cardnumber_err,
    setcardnumber_err,
    expirydate,
    setexpirydate,
    expirydate_err,
    setexpirydate_err,
    cvv,
    setcvv,
    cvv_err,
    setcvv_err,
    invalidcard,
    setinvalidcard,
    invalidsignup,
    setinvalidsignup,
  };
  // daretobedirty end
  // const currentUser = useAuth();
  // function setcard_details() {
  //   const url_token = "https://api.stripe.com/v1/tokens";
  //   let options_token = {
  //     method: "post",
  //     headers: {
  //       Authorization:
  //         "Basic c2tfbGl2ZV81MTAyeGpoMnpPTlpPZXQwam1PQ0hYUUpiQ1dFdmp6VTJxNjBtMUJQdDluNnFVOHVvbGthR1FWeXpKMmpsR0dMclRCdjRIUUc4NXN6a1BnbnRUSzJOZThpTDAwb2tVY2pFeFA6",
  //       // btoa(
  //       //   "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:"
  //       // ),
  //     },
  //     // auth: {
  //     //   username:
  //     //     "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",
  //     //   password: "",
  //     // },
  //     url: url_token,
  //     data: {
  //       "card[number]": `${cardnumber}`,
  //       "card[exp_month]": `${expirydate.split("/")[0].trim()}`,
  //       "card[exp_year]": `${expirydate.split("/")[1].trim()}`,
  //       "card[cvc]": `${cvv}`,
  //     },
  //     muteHttpExceptions: true,
  //   };
  //   axios.post(
  //     url_token,
  //     {
  //       ...options_token.data,
  //     },
  //     {
  //       auth: {
  //         username:
  //           "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",
  //         password: "",
  //       },
  //     }
  //   );
  //   return;
  //   axios(options_token)
  //     .then((response) => response.data)
  //     .then((data) => {
  //       if (data.error) {
  //         console.log("Error saving your card.\nDetailed Report:", data);
  //         return;
  //       }
  //       const card_data = {
  //         tokenId: data.id,
  //         cardLast4: data.card.last4,
  //         cardId: data.card.id,
  //         cardBrand: data.card.brand,
  //         cardType: data.card.funding,
  //       };
  //       console.log(card_data);
  //       return card_data.id;
  //     })
  //     .then((tokenId) => {
  //       const url_customer = "https://api.stripe.com/v1/customers";
  //       let options_customer = {
  //         ...options_token,
  //         url: url_customer,
  //         data: {
  //           name: `${name}`,
  //           email: `${email}`,
  //           source: `${tokenId}`,
  //         },
  //         muteHttpExceptions: true,
  //       };
  //       axios(options_customer)
  //         .then((response) => response.data)
  //         .then((data) => {
  //           console.log(data);
  //         })
  //         .catch((err) => {
  //           console.log("some error in 2st api", err);
  //         });
  //     })
  //     .catch((err) => {
  //       console.log("some error in 1st api", err);
  //     });
  // }
  const signup = (callback) => {
    // console.log(1);
    // console.log([
    //   name,
    //   phone,
    //   email,
    //   password,
    //   address,
    //   apartmentno,
    //   zipcode,
    //   i_have_a_doorman,
    //   nameoncard,
    //   cardnumber,
    //   expirydate.split("/")[0].trim(),
    //   expirydate.split("/")[1].trim(),
    //   cvv,
    // ]);
    sign_up(
      {
        full_name: name,
        phone: phone,
        email: email,
        password: encrypt_data(password),
        address1: address,
        apt: apartmentno,
        zipcode: zipcode,
        doorman: i_have_a_doorman ? 1 : 0,
        card_name: encrypt_data(nameoncard),
        card_number: encrypt_data(cardnumber.split(" ").join("")),
        card_expiry_month: encrypt_data(expirydate.split("/")[0].trim()),
        card_expiry_year: encrypt_data(expirydate.split("/")[1].trim()),
        card_cvc: encrypt_data(cvv),
      },
      (data) => {
        console.log(data);
        callback();
        if (data.status) {
          dispatch({ type: "login", payload: { token: data.msg.token } });
          localStorage.setItem("juliette_user_token", data.msg.token);
          navigate("/request-pickup");
        } else {
          if (data.error_card) {
            setnameoncard_err("true");
            setcardnumber_err("true");
            setexpirydate_err("true");
            setcvv_err("true");
            setnameoncard("");
            setcardnumber("");
            setexpirydate("");
            setcvv("");
            setinvalidcard("true");
          } else {
            // setinvalidcard('true')
            setinvalidsignup(data.msg);
          }
        }
      }
    );
    // localStorage.setItem("juliette_userdata", [
    //   // token,
    //   name,
    //   phone,
    //   email,
    //   // password,
    //   [
    //     {
    //       add: address,
    //       apt: apartmentno,
    //       zip: zipcode,
    //       doorman: true,
    //       default_add: true,
    //     },
    //   ],
    //   [
    //     {
    //       nameoncard: nameoncard,
    //       cardno: cardnumber,
    //       expirydate: expirydate,
    //       cvv: cvv,
    //       default_card: true,
    //     },
    //   ],
    // ]);
    // setcard_details();

    // try {
    //   const payload_login = {
    //     // name:name,
    //     // phone:phone,
    //     email: email,
    //     password: password,
    //     // address:address,
    //     // apartmentno:apartmentno,
    //     // zipcode:zipcode,
    //     // nameoncard:nameoncard,
    //     // cardnumber:cardnumber,
    //     // expirydate:expirydate,
    //     // cvv:cvv
    //   };
    //   dispatch({ type: "login", payload: payload_login });
    //   // navigate("/request-pickup");
    //   // console.log('currentUer',currentUser?.email);
    // } catch {
    //   alert("Error!");
    // }
  };
  // var verification_counter_fun = (i) => {

  //   setTimeout(() => {
  //     console.log('loop', i);
  //     setphone_verification_counter((i + 1 > 10) ? 0 : 10 - i)
  //   }, (1000 * i))
  // }
  return (
    <>
      {show_create_card === "letsgetstarted" ? (
        // 'create account' converted to 'lets get started'
        <div className="createacc">
          <div className="createacc_heading f40">
            Let's get
            <br />
            started
          </div>
          <div className="f14">PERSONAL DETAILS</div>
          <div>
            <div className="createacc_name">
              <input
                className="f14 "
                placeholder="Full Name"
                value={name}
                inp_error={name_err}
                onChange={(e) => OnChangeName(e.target.value)}
              ></input>
            </div>
            {/* <div className="createacc_number">

              <input
                className="f14"
                placeholder="Mobile"
                value={phone}
                inp_error={phone_err}
                onChange={OnChangePhone}
              ></input>
            </div>
            <div className={"createacc_numberverification " + (phone_err == 'false' ?
              ' createacc_numberverification_active' : '')}>
              {
                phone_verification_state == '' ?
                  ''
                  :
                  <input
                    className="f14"
                    placeholder="Enter verification"
                    value={phone_verification}
                    // inp_error={phone_err}
                    onChange={(e) => setphone_verification(e.target.value)}
                  ></input>
              }
              <div className={"createacc_numberverification_btn" + (phone_verification_state == '' ? '' : ' createacc_numberverification_btn_to_right')} onClick={() => {
                console.log('click');
                if (phone_verification_state == '') {
                  setphone_verification_state('true')

                }
                else {
                  setphone_verification_counter(0)
                  for (var i = 0; i <= 11; i++) {
                    verification_counter_fun(i)
                  }
                }

              }}>
                {
                  phone_verification_state == '' ?
                    'Send verification code'
                    :
                    // {
                    //   // phone_verification_err == false
                    // }
                    `Resend${(phone_verification_counter == 0) ? '' : ` (${phone_verification_counter})`}`
                }
              </div>
            </div> */}
            <div className="createacc_email">
              <input
                className="f14"
                placeholder="Email"
                value={email}
                inp_error={email_err}
                onChange={(e) => {
                  OnChangeEmail(e.target.value);
                  if (invalidsignup == "User email already exists") {
                    setinvalidsignup("");
                  }
                }}
                disabled={email_disabled}
              ></input>
            </div>
            <div className="createacc_password">
              <input
                type={password_type}
                className="f14"
                placeholder="Password"
                value={password}
                inp_error={password_err}
                onChange={OnChangePassword}
              ></input>

              {password_type === "text" ? (
                <img
                  mouse="pointer"
                  className="password_img"
                  src={password_shown}
                  onClick={() => {
                    password_type === "password"
                      ? setpassword_type("text")
                      : setpassword_type("password");
                  }}
                />
              ) : (
                <img
                  mouse="pointer"
                  className="password_img"
                  src={password_not_shown}
                  onClick={() => {
                    password_type === "password"
                      ? setpassword_type("text")
                      : setpassword_type("password");
                  }}
                />
              )}
            </div>
          </div>
          <div className="error f12" error={password_err}>
            Password should consist of
            <br /> 8 characters including 1 number, 1 symbol.
          </div>
          <div
            className="createacc_login f13 f-400"
            style={{ width: "fit-content" }}
            mouse="pointer"
            onClick={() => setshow_card("login")}
          >
            <div>Have an Account?</div>
            <div>Log In</div>
          </div>
          <div className="createacc_agreement_btn" mouse="pointer">
            <div className="agreement">
              By signing in you agree
              <br />
              on the Terms of Service
              <br />
              and our Privacy Policy.
            </div>
            <div
              className={"submit f-500 " + (creataccount ? "" : "disabled")}
              cursor="pointer"
              onClick={() =>
                creataccount && setshow_create_card("verifyyourself")
              }
            >
              Next
            </div>
          </div>
          <OR_LoginThrough
            setemail={(data_name) => {
              OnChangeEmail(data_name);
            }}
            setemail_disabled={setemail_disabled}
            setname={(data_name) => {
              OnChangeName(data_name);
            }}
            mini={phone_err == "false"}
          />
          <CreateAccountDots show_dots="1" />
        </div>
      ) : show_create_card === "verifyyourself" ? (
        <VerifyYourself
          phone={phone}
          phone_err={phone_err}
          OnChangePhone={OnChangePhone}
          setshow_create_card={setshow_create_card}
          phone_verified={phone_verified}
          setphone_verified={setphone_verified}
          invalidsignup={invalidsignup}
          setinvalidsignup={setinvalidsignup}
          setshow_card={setshow_card}
        />
      ) : show_create_card === "wheredoyoulive" ? (
        // converted 'almostthere' to 'where do you live'
        <WhereDoYouLive
          data={almosttheredata}
          setshow_card={setshow_card}
          setshow_create_card={setshow_create_card}
        />
      ) : show_create_card === "onemorething" ? (
        // ''
        <OneMoreThing
          data={daretobedirtydata}
          setshow_card={setshow_card}
          setshow_create_card={setshow_create_card}
          signup={signup}
        />
      ) : (
        ""
      )}
    </>
  );
};
