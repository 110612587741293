import { Cancel } from "@mui/icons-material";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { change_profile_image, delete_profile_image } from "../../API/API";
import { useNavigate } from "react-router-dom";

export const ChangeProfilePicture = ({ cancel, profile, setprofile, setshow_this_page }) => {
  const current_store = useSelector((state) => state);
  // const [name_token, setname_token] = useState('')
  const navigate = useNavigate();
  const changephoto = (current) => {
    setshow_this_page(false)
    // let temp_arr = [];
    // console.log(current.files);
    if (current.files.length > 0) {
      Object.values(current.files).map((el) => {
        var reader = new FileReader();
        reader.readAsDataURL(el);
        reader.onload = function () {
          // temp_arr.push(reader.result);
          // if (current.target.files.length == temp_arr.length) {
          // setimage_base64([...image_base64, ...temp_arr]);
          change_profile_image(
            reader.result,
            current_store.user.token,
            (data) => {
              // console.log(data);
              if (!data.status) {
                if (data.img_error) {
                  setshow_this_page(true);
                  cancel()
                  return
                }
                return navigate("/");
              }
              setprofile({ ...profile, img: data.msg })
              setshow_this_page(true)
              cancel();
            }
          )
          // setprofile({ ...profile, img: reader.result });
          // }
        };
      });
    }
  };
  return (
    <div className="changeprofilepicture"
    //  onClick={cancel}
    >
      <label htmlFor="changephoto">
        <input
          type="file"
          id="changephoto"
          accept=".jpg,.png"
          onChange={(e) => {
            changephoto(e.target)
          }}
        ></input>
        <div btn_type="type3">Change photo</div>
      </label>
      <div
        btn_type="type1"
        onClick={() => {
          // setprofile({ ...profile, img: "" });
          setshow_this_page(false);
          delete_profile_image(
            '',
            current_store.user.token,
            (data) => {
              // console.log(data);
              if (!data.status) {
                if (data.img_error) {
                  setshow_this_page(true);
                  cancel()
                  return
                }
                return navigate("/");
              }
              setprofile({ ...profile, img: data.msg })
              setshow_this_page(true)
              cancel();
            }
          )
          cancel();
        }}
      >
        Delete
      </div>
    </div>
  );
};
