import React, { useEffect, useState } from "react";
import Slider from "react-slick";

export const AddressSlider = ({ add_arr, setshowadd }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    // fade: true,
    afterChange: (i) => setshowadd(i),
  };
  // const [show_address_list, setshow_address_list] = useState([]);
  // useEffect(() => {
  //   console.log("slider", profile);
  //   setshow_address_list([]);
  //   setshow_address_list([...profile.add_arr]);
  // }, [profile]);

  return (
    <div className="address_slider">
      <Slider {...settings}>
        {add_arr.map((el, i) => (
          <div key={i}>
            <input type="text" placeholder="Address" value={el.add} readOnly />
            <div className="address_slider_inp_group">
              <input type="text" placeholder="Apt" value={el.apt} readOnly />
              <input
                type="text"
                placeholder="Zip Code"
                value={el.zip}
                readOnly
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};
