import React, { useState } from "react";
import { just_verify_email, resend_email_otp, verify_email } from "../../API/API";

export const EnterVerificationCode = ({
  setshow_forgetpassword_card,
  cancel,
  email,
  setemail,
  temptoken,
  settemptoken
}) => {

  const [resendcode, setresendcode] = useState(false);
  const [verificationcode, setverificationcode] = useState("");
  const [verificationcode_err, setverificationcode_err] = useState("");
  return (
    <>
      <div className="f14">ENTER EMAIL TO GET CODE</div>
      <div className="forgotpassword_email">
        <input
          className="f14"
          placeholder="Email Adress"
          value={email}
          // onChange={(e) => setemail(e.target.value)}
          readOnly
        ></input>
      </div>
      <div className="f14">ENTER VERIFICATIONS CODE</div>
      <div className="forgotpassword_email mb_auto">
        <input
          className="f14"
          placeholder="Verification Code"
          value={verificationcode}
          onChange={(e) => {setverificationcode(e.target.value);setverificationcode_err('')}}
        ></input>
        {
          verificationcode_err &&
        <div className="error" error='true' style={{marginTop:'5px'}}>{verificationcode_err}</div>
        }
      </div>
      <div
        className={"forgotpassword_resendcode"}
        mouse="pointer"
        onClick={()=>{
          if(resendcode){
            return
          }
          setresendcode(true)
          setverificationcode('');
          resend_email_otp({
            email:email,
          },'',(data)=>{
            // console.log(data);
            setresendcode(false)
                });
        }}
      >
        {
          resendcode ? 'Resending...' : 'Resend code'
        }
      </div>
      <div
        className={"forgotpassword_cancel"}
        mouse="pointer"
        onClick={() =>{ setshow_forgetpassword_card("enteremailtogetcode");setverificationcode('')}}
      >
        Set new email
      </div>
      {/* {verificationcode != "" ? ( */}
      <div
        className={
          "forgotpassword_submit" + (verificationcode == "" ? " disable" : " ")
        }
        mouse="pointer"
        onClick={() =>{

          if(verificationcode.trim() == ""){
            return
          }
        
          just_verify_email(
            {
              email: email,
              // name: profile.name,
              otp: verificationcode,
              // id: profile.id
            },
           '',
            (data) => {
              console.log(data);
              if (!data.status) {
                // navigate('/404')
                return
              }
              if (data.otp_error) {
                // console.log('otp false');
                setverificationcode_err('verification code is wrong')
                return
              }
              settemptoken(data.msg.token);
              setshow_forgetpassword_card("setnewpassword")
              // dispatch({ type: "login", payload: { token: data.token } });
              // localStorage.setItem('juliette_user_token', data.token);
              // cancel();

              // setverificationcode(data.msg)
              // setemail_modal_showing("setanewemail2");
            }
          )
          // verificationcode != "" &&
        }
        }
      >
        Confirm Verification
      </div>
      {/* ) : (
        <div
          className={"forgotpassword_submit disable"}
          mouse="pointer"
          onClick={() => setshow_forgetpassword_card("setnewpassword")}
        >
          enter
        </div>
      )} */}
    </>
  );
};
