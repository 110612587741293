import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CreateAccountDots } from "../../CommonComponents/CreateAccountDots";
import DateAndTime from "../../CommonComponents/MUI/DateAndTime";
import cvv_img from "../../../img/cvv.svg";
import { set } from "date-fns";
// import { StripeAddCard } from "../../CommonComponents/StripeAddCard";
// import { signupfb } from '../../firebase'

const month_count = [
  [0, 31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
  [0, 31, 29, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
];

export const OneMoreThing = ({
  setshow_card,
  setshow_create_card,
  data,
  signup,
}) => {
  // API ERROR SENARIOS
  // CARD INVALID
  // SERVER ISSUE :- STRIPE ADD CUSTOMER FAILED (retry )
  // adddress error limit exceded
  // apt 100char
  // zipcode 10char
  // add 1000char
  //
  // email 150 char

  const {
    nameoncard,
    setnameoncard,
    nameoncard_err,
    setnameoncard_err,
    cardnumber,
    setcardnumber,
    cardnumber_err,
    setcardnumber_err,
    expirydate,
    setexpirydate,
    expirydate_err,
    setexpirydate_err,
    cvv,
    setcvv,
    cvv_err,
    setcvv_err,
    invalidcard,
    setinvalidcard,
    invalidsignup,
    setinvalidsignup,
  } = data;
  const [next, setnext] = useState(false);
  const [sign_up_login_loaded, setsign_up_login_loaded] = useState(false);
  useEffect(() => {
    // console.log([
    //   nameoncard,
    //   cardnumber,
    //   expirydate,
    //   cvv,
    //   nameoncard_err,
    //   cardnumber_err,
    //   expirydate_err,
    //   cvv_err,
    // ]);
    if (
      nameoncard != "" &&
      cardnumber != "" &&
      expirydate != "" &&
      cvv != "" &&
      nameoncard_err == "false" &&
      cardnumber_err == "false" &&
      expirydate_err == "false" &&
      cvv_err == "false"
    ) {
      setnext(true);
    } else {
      setnext(false);
    }
  }, [
    nameoncard,
    cardnumber,
    expirydate,
    cvv,
    nameoncard_err,
    cardnumber_err,
    expirydate_err,
    cvv_err,
  ]);
  // console.log("next", next);
  const set_value_carddate = (current) => {
    console.log("test");
    // checks for a number as a input and also if cheks for while backspacing for '' and '/' if dsabled you wouldnt be able to backspace in card month
    if (
      Number(current.value.substr(-1)) == current.value.substr(-1) ||
      current.value.substr(-1) == " " ||
      current.value.substr(-1) == "/"
    ) {
      if (current.value.replaceAll("/", "").replaceAll(" ", "").length < 5) {
        if (current.value.replaceAll("/", "").replaceAll(" ", "").length != 4) {
          setexpirydate_err("true");
        } else {
          let entered_date = new Date(
            `20${current.value.split("/")[1].trim()}-${current.value
              .split("/")[0]
              .trim()}-${
              month_count[
                (2000 + current.value.split("/")[1].trim()) % 4 == 0 ? 1 : 0
              ][Number(current.value.split("/")[0].trim())]
            }T00:00:00`
          );
          console.log(entered_date);
          let current_date = new Date(
            `${new Date().getFullYear()}-${
              new Date().getMonth() < 9 ? "0" : ""
            }${new Date().getMonth() + 1}-${
              new Date().getDate() < 10 ? "0" : ""
            }${new Date().getDate()}T00:00:00`
          );
          // console.log(current_date, entered_date);
          setexpirydate_err((entered_date - current_date <= 0).toString());
        }
        let data = "";
        // adds a ' / ' etween two numbers and doesnot lets user to enter the first number greater than 12 if it is then changes eg. 13 =  01 / 3
        if (
          current.value.length == 3 &&
          expirydate.length + 1 <= current.value.length
        ) {
          setexpirydate(expirydate + " / " + current.value.slice(-1));
          data = current.value.slice(0, 2) + "/" + current.value.slice(-1);
        } else {
          if (current.value > 12) {
            let date_arr = current.value.split("");
            setexpirydate("0" + date_arr[0] + " / " + date_arr[1]);
            data = "0" + date_arr[0] + " / " + date_arr[1];
          } else {
            // console.log(current.value.length);
            if (current.value.length == 5 && current.value.substr(-1) == " ") {
              return setexpirydate(current.value.slice(0, 2));
            }
            setexpirydate(current.value);
            data = current.value;
          }
        }
      }
    }
  };
  const set_value_cardno = (current) => {
    // checks for a number as a input
    if (Number(current.value.substr(-1)) == current.value.substr(-1)) {
      // checks for the length of value to be less than 20
      if (current.value.replaceAll(" ", "").length >= 20) {
        return;
      }
      if (current.value.replaceAll(" ", "").length < 20) {
        setcardnumber_err(
          (
            [15, 16, 19].indexOf(current.value.replaceAll(" ", "").length) == -1
          ).toString()
        );
        // puts spaces between 4 digits eg. 1234 1234 1234 1234
        if (
          [5, 10, 15, 20].indexOf(current.value.length) > -1 &&
          cardnumber.length <= current.value.length
        ) {
          setcardnumber(cardnumber + " " + current.value.slice(-1));
        } else {
          if (
            [5, 10, 15, 20].indexOf(current.value.length) > -1 &&
            current.value.slice(-1) == " "
          ) {
            return setcardnumber(current.value.trim());
          }
          setcardnumber(current.value);
        }
      }
    }
  };
  const set_value_cardcvv = (current) => {
    if (current.value > 99) {
      setcvv_err("false");
    } else {
      setcvv_err("true");
    }
    if (current.value <= 9999) {
      setcvv(current.value);
    }
  };
  useEffect(() => {
    if (nameoncard == "" && cardnumber == "" && expirydate == "" && cvv == "") {
      return;
    }
    setinvalidcard(false);
  }, [nameoncard, cardnumber, expirydate, cvv]);
  // console.log(invalidcard);
  return (
    <div className="daretobedirty">
      <div className="daretobedirty_heading f40">
        One more
        <br />
        thing...
      </div>
      <div className="f14">PAYMENT DETAILS</div>
      <div className="daretobedirty_nameoncard">
        <input
          type="text"
          value={nameoncard}
          className="f14"
          placeholder="Name on Card"
          inp_error={nameoncard_err}
          onChange={(e) => {
            setnameoncard(e.target.value);
            setnameoncard_err((e.target.value == "").toString());
          }}
        ></input>
      </div>
      {/* <StripeAddCard /> */}
      <div className="daretobedirty_cardnumber">
        <input
          type="text"
          value={cardnumber}
          className="f14"
          placeholder="Card Number"
          inp_error={cardnumber_err}
          onChange={(e) => set_value_cardno(e.target)}
        ></input>
      </div>
      <div className="daretobedirty_expirydate">
        <input
          type="text"
          // value={() => {
          //   let date = expirydate.split(" ");
          //   return date[1] + " " + date[3];
          // }}
          value={expirydate}
          className="f14"
          placeholder="Exp"
          inp_error={expirydate_err}
          onChange={(e) => set_value_carddate(e.target)}
        />
        {/* <DateAndTime value={expirydate} setvalue={setexpirydate} /> */}
      </div>
      <div className="daretobedirty_cvv">
        <input
          type="text"
          value={cvv}
          className="f14"
          placeholder="CVV"
          inp_error={cvv_err}
          onChange={(e) => set_value_cardcvv(e.target)}
        ></input>
        <img className="cvv_img" src={cvv_img} />
      </div>
      {invalidcard && (
        <div className="error" error={invalidcard.toString()}>
          Invalid card
        </div>
      )}
      {/* { */}
      {/* // invalidsignup.length != 0 && */}
      <div className="error" error={invalidsignup.length != 0}>
        {invalidsignup}
      </div>
      {/* } */}
      <div
        className="daretobedirty_haveanaccount f13 f-400"
        style={{ width: "fit-content" }}
        onClick={() => setshow_card("login")}
      >
        <div>Have an Account?</div>
        <div>Log In</div>
      </div>
      <div className="daretobedirty_prevnext">
        <div
          className="daretobedirty_prev"
          mouse="pointer"
          onClick={() => setshow_create_card("wheredoyoulive")}
        >
          <div>Previous</div>
        </div>
        <div className="daretobedirty_next" mouse="pointer">
          <div
            className={next ? "" : "disabled"}
            onClick={() => {
              if (!next || sign_up_login_loaded) {
                return;
              }
              // if (nameoncard == "testerror") {
              //   setinvalidcard("true");
              //   // nameoncard("");
              //   // cardnumber("");
              //   // expirydate("");
              //   // cvv_err("");
              //   return;
              // }
              setsign_up_login_loaded(true);
              signup(() => {
                setsign_up_login_loaded(false);
              });

              // if(result_signup)
            }}
          >
            {sign_up_login_loaded ? "Signing Up..." : "Sign Up"}
          </div>
        </div>
      </div>
      <CreateAccountDots show_dots="4" />
    </div>
  );
};
