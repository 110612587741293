import React, { useEffect, useRef, useState } from 'react'
import Slider from 'react-slick'
import trash_icon from '../../img/addimagesmodal/trash_icon.svg'
import add_icon from '../../img/addimagesmodal/add_icon.svg'
import close_icon from '../../img/addimagesmodal/close_icon.svg'

export const AddPhotoAndNoteModal = ({ current_image_index, images, setimage_modal, setimages, setcurrent_image_index, add_img }) => {
    const [currentshowing_img, setcurrentshowing_img] = useState(current_image_index)
    const [confirm_delete_modal, setconfirm_delete_modal] = useState(false)
    // const [nav1, setNav1] = useState();
    // console.log(currentshowing_img);
    const slider1 = useRef();
    const set_height_width = (i) => {
        setTimeout(() => {

            let current_img = document.getElementsByClassName('show_images_set_height_width' + i);
            // console.log(current_img);
            [...current_img].map(
                el => {
                    let slick_list = document.getElementsByClassName('slick-list')[0].getBoundingClientRect()
                    if ((el.getBoundingClientRect().width / el.getBoundingClientRect().height) > slick_list.width / slick_list.height) {
                        el.style.width = slick_list.width + 'px'
                        el.style.height = 'auto'
                        // current_img.style.borderInline = '7px solid #fff'
                    }
                    else {
                        el.style.width = 'auto'
                        el.style.maxHeight = slick_list.height + 'px'
                        // current_img.style.borderBlock = '7px solid #fff'
                    }
                }
            )

        }, 0);
    }
    useEffect(() => {
        [...images].map((el, i) => set_height_width(i));
        // slider1.current.slickGoTo(current_image_index)
        // slider1.slick('slickGoTo', 2);
    }, [images])
    // console.log(slider1.current.slickGoTo(2));
    const add_img_to_current_open_item = (current, i) => {
        if (current.target.files.length) {
            // console.log(Object.values(current.target.files));
            let temp_arr = []
            Object.values(current.target.files).map(el => {
                var reader = new FileReader();
                reader.readAsDataURL(el);
                reader.onload = function () {
                    temp_arr.push(reader.result)
                    if (current.target.files.length == temp_arr.length) {
                        let temp_images = [...images];
                        temp_images[i].img = [...images[i].img, ...temp_arr];
                        setimages([...temp_images])
                    }
                    // console.log(reader.result);
                };
            })
        }
    };
    const settings = {
        dots: false,
        infinite: true,
        arrows: !confirm_delete_modal,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: current_image_index,
        afterChange: (i) => setcurrentshowing_img(i),
    };
    return (
        <div className='addphotoandnotemodal' id='addphotoandnotemodal_close' onClick={(e) => { (e.target.id == 'addphotoandnotemodal_close') && setcurrent_image_index([false, -1]) }}>
            <div>
                <div className='action_btns'>
                    <div className='delete_btn' style={{ visibility: confirm_delete_modal ? 'hidden' : 'visible' }} onClick={() => { }}>
                        <img src={trash_icon} onClick={() => { setconfirm_delete_modal(true) }} /></div>
                    <div className='add_btn' style={{ visibility: confirm_delete_modal ? 'hidden' : 'visible' }}>
                        <input
                            type="file"
                            accept=".jpg,.png"
                            placeholder=""
                            id="addphoto"
                            onChange={(e) => add_img(e)}
                            multiple
                        />
                        {/* not completed check mtlp EDITMODAL.JS line 725  */}
                        <label
                            className=" t-white"
                            mouse="pointer"
                            htmlFor="addphoto"
                        >
                            <img src={add_icon} onClick={() => add_img} />
                        </label>
                    </div>
                    <div className='close_btn'>
                        <img src={close_icon} onClick={() => setcurrent_image_index([false, -1])} />
                    </div>
                </div>
                <div className='show_img'>

                    {/* <img src={images[currentshowing_img]} id='current_img' /> */}
                    <Slider {...settings} ref={slider1}>
                        {
                            images.map(

                                (el, i) => <div>
                                    <img src={el} className={'show_images_set_height_width' + i} />
                                </div>
                            )
                        }
                    </Slider>
                    {/* <div className='prev_btn'></div>
                    <div className='next_btn'></div> */}
                    <div className='dots_to_show'>
                        {
                            images.map(
                                (el, i) => <div className={`dot ${(i == currentshowing_img) ? 'dot_active' : ''}`}></div>

                            )
                        }
                    </div>
                    {
                        confirm_delete_modal &&
                        <div className='delete_confirm_modal'>
                            <div className='txt'>Delete image?</div>
                            <div className='btn' type='type1' onClick={() => {
                                let temp_images = [...images];
                                temp_images.splice(currentshowing_img, 1,);
                                setimages([...temp_images])
                                setconfirm_delete_modal(false)
                            }}>Delete</div>
                            <div className='btn' type='type1' onClick={() => setconfirm_delete_modal(false)}>Cancel</div>
                        </div>
                    }
                </div>
            </div>
        </div >
    )
}
