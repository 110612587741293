const default_user = {
  token: "",
};
export const user = {
  ...default_user,
};

const User = (state = user, { type, payload }) => {
  // console.log('login_reducer', type, payload);
  switch (type) {
    case "login":

      return {
        ...state,
        token: payload.token
      };
    case "logout":
      return { ...state, ...default_user };
    // case "others":
    //   return { ...state, token: payload.token, others: payload.others };
    // case 'current_page':
    //     return { ...state, current_page: payload };
    // case 'set_admin_select_remember':
    //     // console.log('set_admin_select_remember',payload);
    //     return { ...state, admin_select_remember: payload }
    default:
      return state;
  }
};
export default User;
