import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { get_add, request_pickup, verify_local_token } from "./API/API";
import { Loading } from "./CommonComponents/Loading";
import { AddPhotoAndNote } from "./RequestPickup/AddPhotoAndNote";
import { GotIt } from "./RequestPickup/GotIt";
import { PickupType } from "./RequestPickup/PickupType";
import { useNavigate } from "react-router-dom";
// import { YouRequestedAPickup } from "./RequestPickup/YouRequestedAPickup";
import { YouWillRecieveAText } from "./RequestPickup/YouWillRecieveAText";
// import '../style/components copy/requestpickup.scss'
// const add_arr = [
//   {
//     add: "add1.1",
//     apt: "add1.2",
//     zip: "11",
//     doorman: false,
//     default_add: false,
//   },
//   {
//     add: "add2.1",
//     apt: "add2.2",
//     zip: "22",
//     doorman: false,
//     default_add: false,
//   },
//   {
//     add: "add3.1",
//     apt: "add3.2",
//     zip: "33",
//     doorman: true,
//     default_add: true,
//   },
// ]

export const RequestPickup = ({ setmenu, setjuliette_bg }) => {


  const [show_this_page, setshow_this_page] = useState(false)

  const [show_requestpickup, setshow_requestpickup] =
    useState("RequestPickupType");
  const [address_for_delivery, setaddress_for_delivery] = useState(-1)
  const [add_arr, setadd_arr] = useState([])
  const [laundry_selected, setlaundry_selected] = useState(false);
  const [drycleaning_selected, setdrycleaning_selected] = useState(false);
  const [others_selected, setothers_selected] = useState(false);
  const [pickupconfirm_active, setpickupconfirm_active] = useState(false);
  const [photonoteconfirm_active, setphotonoteconfirm_active] = useState(false);
  const [pickup_note, setpickup_note] = useState("");
  const [discount_code, setdiscount_code] = useState("");

  const [order_id, setorder_id] = useState('')

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const current_store = useSelector((state) => state);

  // console.log(current_store);
  useEffect(() => {
    if (current_store.user.token == '') {

      let token_in_localstorage = localStorage.getItem('juliette_user_token')

      if (token_in_localstorage) {
        verify_local_token(token_in_localstorage,
          (data) => {
            if (!data.status) {
              setshow_this_page(true)
              return navigate("/");
            }
            setshow_this_page(true)
            dispatch({ type: "login", payload: { token: data.token } });
          })
        // return navigate('/request-pickup')
      }
      else {
        setshow_this_page(true)
        return navigate("/");

      }

    }
    else {

      get_add(current_store.user.token, (data) => {
        if (!data.status) {
          return navigate('/')
        }
        if (data.msg.addresses.length == 0) {
          return navigate('/profile')
        }
        if(!data.msg.card_exists){
          return navigate('/profile')
        }
        setadd_arr([...data.msg.addresses])
        setaddress_for_delivery(data.msg.addresses.map(el => el.is_default == '1').indexOf(true))

        setshow_this_page(true)
        setmenu(true);
        setjuliette_bg(true);

      })
    }
    // }
  }, [current_store.user.token])

  // console.log('address_for_delivery', address_for_delivery);
  useEffect(() => {
    setpickupconfirm_active(
      laundry_selected || drycleaning_selected || others_selected
    );

    let payload_data = {
      laundry: laundry_selected,
      drycleaning: drycleaning_selected,
      others: others_selected,
    };
    dispatch({ type: "addrequestpickup", payload: payload_data });
  }, [laundry_selected, drycleaning_selected, others_selected]);

  useEffect(() => {
    // add photo and note
    if (show_requestpickup == "gotit") {
      setjuliette_bg(false);
    } else {
      setjuliette_bg(true);
    }
  }, [show_requestpickup]);
  // console.log([laundry_selected, drycleaning_selected, others_selected]);

  const resetpickupdata = () => {
    setlaundry_selected(false);
    setdrycleaning_selected(false);
    setothers_selected(false);
    setpickupconfirm_active(false);
    setdiscount_code('')
    setpickup_note("");
  };
  const requestpickup = (photos, note, callback) => {
    request_pickup(
      {
        "address": `${add_arr[address_for_delivery].address}, ${add_arr[address_for_delivery].apartment}, ${add_arr[address_for_delivery].zipcode}`,
        apt: add_arr[address_for_delivery].apartment,
        "particulars": {
          "laundry": laundry_selected ? '1' : '0',
          "drycleaning": drycleaning_selected ? '1' : '0',
          "others": others_selected ? '1' : '0'
        },
        "photos": [...photos.map(el => {
          return {
            base64: el
          }
        })],
        note: note,
        "discount_code": discount_code
      },
      current_store.user.token
      , (data) => {
        // setshow_requestpickup("gotit");
        if (!data.status) {
          return navigate('/404')
        }
        // console.log(data);
        setorder_id(data.msg)
        setshow_requestpickup('gotit')
        callback()
        return
      }
    );
  }

  return (
    <>
      {
        !show_this_page && <Loading />
      }
      <div
        className={
          "requestpickup" + (show_requestpickup === "gotit" ? " has_bg" : "")
        }
      >
        {/* {show_requestpickup === "yourequestedapickup" ||
      show_requestpickup === "youwillrecieveatextmsg" ? (
        <></>
      ) : ( */}
        <div
          className={
            "page_title" +
            (show_requestpickup === "addphotoandnote" ||
              show_requestpickup === "gotit"
              ? " hidden_in_phone"
              : "")
          }
        >
          Request Pickup
        </div>
        {/* )} */}
        {show_requestpickup === "RequestPickupType" ? (
          <PickupType
            setlaundry_selected={setlaundry_selected}
            setdrycleaning_selected={setdrycleaning_selected}
            others_selected={others_selected}
            setothers_selected={setothers_selected}
            setshow_requestpickup={setshow_requestpickup}
            confirm_active={pickupconfirm_active}
            add_arr={add_arr}
            address_for_delivery={address_for_delivery}
            setaddress_for_delivery={setaddress_for_delivery}
          />
        ) : show_requestpickup === "addphotoandnote" ? (
          <AddPhotoAndNote
            pickup_note={pickup_note}
            setpickup_note={setpickup_note}
            setshow_requestpickup={setshow_requestpickup}
            confirm_active={photonoteconfirm_active}
            discount_code={discount_code}
            setdiscount_code={setdiscount_code}
            requestpickup={requestpickup}
            resetpickupdata={resetpickupdata}
          />
        ) : show_requestpickup === "gotit" ? (
          <GotIt
            setshow_requestpickup={setshow_requestpickup}
            resetpickupdata={resetpickupdata}
            order_id={order_id}
            token={current_store.user.token}
          />
        ) : (
          // ) : show_requestpickup === "youwillrecieveatextmsg" ? (
          //   <YouWillRecieveAText
          //     setshow_requestpickup={setshow_requestpickup}
          //     resetpickupdata={resetpickupdata}
          //   />
          ""
        )}
      </div>
    </>
  );
};
