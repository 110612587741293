import React, { useEffect, useReducer } from "react";
import { useSelector } from "react-redux";
// import logo from "../../img/logo.svg";
import logo from "../../img/logo.png";
import { Navbar } from "./Navbar";
import { useNavigate } from "react-router-dom";

export const Juliette = ({ juliette_bg, menu }) => {
  const store_data = useSelector((store) => store);
  // console.log('jc_store_data', menu);
  const navigate = useNavigate();
  // useEffect(() => {
  //   // console.log(store_data);
  // }, [store_data]);

  return (
    <div className={"juliette " + (juliette_bg ? " bg-black " : " ")}>
      {menu && <div className="menu32_dummy"></div>}
      <img src={logo} onClick={() => {
        if (!menu) return;
        navigate("/request-pickup")
      }} />
      {menu && <Navbar />}
    </div>
  );
};
