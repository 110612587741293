import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useSelector } from "react-redux";
import password_not_shown from "../../../img/password_not_shown.svg";
import password_shown from "../../../img/password_shown.svg";
import { encrypt_data } from "../../../RSA_Encryption/RSA";
import { edit_password, verify_password } from "../../API/API";

export const EditPassword = ({ profile, setprofile, cancel, setshow_this_page }) => {
  const [current_pass, setcurrent_pass] = useState("");
  const [password1, setpassword1] = useState("");
  const [password1_err, setpassword1_err] = useState("");
  const [password1_type, setpassword1_type] = useState("password");
  const [password2, setpassword2] = useState("");
  const [password2_err, setpassword2_err] = useState('');
  const [password2_type, setpassword2_type] = useState("password");

  const [password_modal_showing, setpassword_modal_showing] =
    useState("enterpassword");

  const current_store = useSelector((state) => state);
  const [invalid_pass, setinvalid_pass] = useState("");
  const [temp_token, settemp_token] = useState("");

  const navigate = useNavigate();
  // useEffect(() => {
  //   if (password1 == '') {
  //     return setpassword1_err('')
  //   }

  // }, [password1])
  // useEffect(() => {
  //   if (password2 == '') {
  //     return setpassword1_err('')
  //   }
  // }, [password2])

  const OnChangePassword = (e, state_password, state_passsword_err) => {
    let last_typed_letter = e.slice(-1).charCodeAt(0);
    if (last_typed_letter < 33 || last_typed_letter > 126) {
      return;
    }
    let temp_password = e.split("");
    let pass_count = {
      char: 0,
      num: 0,
      special: 0,
    };
    temp_password.map((el) => {
      let last_char_code = el.charCodeAt(0);
      if (last_char_code >= 48 && last_char_code <= 57) {
        pass_count.num += 1;
      } else if (
        (last_char_code >= 65 && last_char_code <= 90) ||
        (last_char_code >= 97 && last_char_code <= 122)
      ) {
        pass_count.char += 1;
      } else if (last_char_code >= 33 && last_char_code <= 126) {
        pass_count.special += 1;
      }
    });
    state_password(e);
    state_passsword_err(
      (
        (pass_count.char + pass_count.num + pass_count.special) < 6 ||
        pass_count.num < 1 ||
        pass_count.special < 1
      ).toString()
    );
    // console.log(pass_count, last_typed_letter);
  };

  return (
    <div className="profile_modal_common_card">
      <div className="card_heading">
        Reset
        <br />
        Password
      </div>

      {
        password_modal_showing == 'resetpassword' ?
          <>
            <div className="card_title">RESET PASSWORD</div>
            <div className="card_input">
              <input
                type={password1_type}
                placeholder="New password"
                value={password1}
                onChange={(e) => {
                  OnChangePassword(e.target.value, setpassword1, setpassword1_err);
                  if (password2 != '') {
                    setpassword2_err((e.target.value != password2).toString())
                  }
                }}
                inp_error={password1_err.toString()}
              />
              <img
                mouse="pointer"
                className="password_img"
                src={password1_type === "text" ? password_shown : password_not_shown}
                onClick={() => {
                  password1_type === "password"
                    ? setpassword1_type("text")
                    : setpassword1_type("password");
                }}
              />
            </div>
            <div className="card_input">
              <input
                type={password2_type}
                placeholder="Confirm new password"
                value={password2}
                // onChange={(e) => setpassword2(e.target.value)}
                onChange={(e) => {
                  // console.log(password1, password1_err, password1 == '', password1_err === true);
                  if (password1 == '' || password1_err === 'true') {
                    return
                  }
                  setpassword2(e.target.value)
                  setpassword2_err((e.target.value != password1).toString())
                }}
                inp_error={password2_err.toString()}
              />
              <img
                mouse="pointer"
                className="password_img"
                src={password2_type === "text" ? password_shown : password_not_shown}
                onClick={() => {
                  password2_type === "password"
                    ? setpassword2_type("text")
                    : setpassword2_type("password");
                }}
              />
            </div>
            {
              password1_err === 'true' ?
                <div className="error" error={password1_err}>
                  Your password needs to consist of
                  <br />
                  8 characters including 1 number, 1 symbol.
                </div>
                :
                <div className="error" error={password2_err}>
                  Passwords have to match.
                </div>
            }
          </>
          : <>
            <div className="card_title">ENTER PASSWORD</div>
            <div className="card_input">
              <input
                type="password"
                placeholder="Password"
                value={current_pass}
                onChange={(e) => setcurrent_pass(e.target.value)}
                inp_error={invalid_pass.toString()}
              />
            </div>
          </>
      }
      <div className="margin_bottom_auto"></div>
      <div className="btn_group btn_group_vertical">
        {
          password_modal_showing == 'resetpassword' ?

            <>
              <div btn_type="type1" onClick={cancel}>
                Cancel
              </div>
              <div
                btn_type={
                  password1 == "" ||
                    password1 != password2
                    ? 'type2' :
                    "type3"}
                onClick={() => {
                  if (
                    password1 == "" ||
                    password1 != password2
                  ) {
                    return;
                  }
                  setshow_this_page(false)
                  edit_password(
                    {
                      token: temp_token,
                      password: password1
                    },
                    current_store.user.token,
                    (data) => {
                      // console.log(data);
                      setshow_this_page(true)
                      if (!data.status) {
                        return navigate('/404')
                      }
                      setprofile({ ...profile, password: data.msg });
                      cancel();
                      return
                    }
                  )
                }}
              >
                Set new password
              </div>
            </>
            :
            <>
              <div btn_type="type1" onClick={cancel}>
                Cancel
              </div>
              <div
                btn_type={current_pass == '' ? 'type2' : 'type3'}
                onClick={() => {
                  if (current_pass == '') {
                    return
                  }
                  verify_password(encrypt_data(current_pass), current_store.user.token, (data) => {
                    if (data.msg.verified) {
                      if (!data.status) {
                        return navigate('/404')
                      }
                      settemp_token(data.msg.token)
                      setpassword_modal_showing('resetpassword')
                      return
                    }
                    setinvalid_pass(true)
                  })
                  // if (
                  //   current_pass != profile.password ||
                  //   password1 == "" ||
                  //   password1 != password2
                  // ) {
                  //   return;
                  // }
                  // setprofile({ ...profile, password: password1 });
                  // cancel();
                }}
              >
                Continue
              </div>
            </>
        }
      </div>
    </div>
  );
};
